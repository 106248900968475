// @flow

import React, {Component} from 'react';
import {Button, Modal, Container} from 'react-bootstrap';
import './Styles/Demo.css';
import Tag from './Tag.js';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Image from 'react-bootstrap/Image';
// import wanderW from '../static/w.png';
// import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FollowButton from './FollowButton';
import API from '../Services/API';
// import UserActions from '../Redux/UserRedux';
import defaultWander from '../static/DefaultWander.png';
import Colors from '../Config/Colors';
import FeedActions from '../Redux/FeedRedux';

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIntro: true,
      showPeople: false,
      followings: [],
      selectedTags: [],
      loading: false,
      showExtension: false,
      showExtInstruction: false,
      continueAfterExtAdd: false,
      playVideo: false,
    };
    this.api = API();
  }

  handleDismiss = () => {
    this.setState({
      showIntro: true,
      showPeople: false,
      followings: [],
      selectedTags: [],
      loading: false,
      showExtension: false,
      showExtInstruction: false,
      continueAfterExtAdd: false,
      playVideo: false,
    });
    this.props.close();
  };

  handleOnFinish = () => {
    this.setState({
      showIntro: true,
      showPeople: false,
      followings: [],
      selectedTags: [],
      loading: false,
      showExtension: false,
      showExtInstruction: false,
      continueAfterExtAdd: false,
      playVideo: false,
    });
    this.props.finish();
  };

  renderFinish = () => {
    if (!this.props.focusActive) {
      return (
        <Link to="/" href="/" onClick={this.handleOnFinish}>
          <Button variant="primary">Finish</Button>
        </Link>
      );
    }
    return (
      <Button variant="primary" onClick={this.handleOnFinish}>
        Finish
      </Button>
    );
  };

  renderNext() {
    return (
      <Button
        variant="primary"
        onClick={() => this.setState({playVideo: true})}>
        Next
      </Button>
    );
  }

  renderVideo = () => {
    const demoLink =
      'https://wander-profile-photos.s3.us-east-2.amazonaws.com/WanderDemo.mp4';

    return (
      <Modal
        size="lg"
        contentClassName="demoModal"
        show={this.props.show}
        onHide={this.handleDismiss}
        backdrop={this.props.first ? 'static' : true}
        centered>
        <Modal.Header className="justify-content-center">
          <h3 style={{fontWeight: '300'}} className="pt-3">
            View the brief demo video below!{' '}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="shadow wrapper">
            <video className="demoVideo" controls>
              <source src={demoLink} type="video/mp4" />
            </video>
          </div>
        </Modal.Body>
        <Modal.Footer>{this.renderFinish()}</Modal.Footer>
      </Modal>
    );
  };

  renderContinue(choose_state) {
    return (
      <Button
        variant="primary"
        style={
          window.innerWidth <= 768
            ? {
                borderRadius: '15px',
                paddingLeft: '15px',
                paddingRight: '15px',
                fontWeight: 'bold',
                marginRight: '3px',
                fontSize: '13px',
                fontFamily: 'Roboto',
              }
            : {
                borderRadius: '15px',
                paddingLeft: '30px',
                paddingRight: '30px',
                fontSize: '16px',
                fontWeight: 'bold',
                marginRight: '5px',
                fontFamily: 'Roboto',
              }
        }
        onClick={() => {
          switch (choose_state) {
            case 1:
              this.setState({
                showIntro: false,
                showExtension: true,
              });
              break;

            case 2:
              this.setState({
                showExtension: false,
                showExtInstruction: true,
              });
              break;

            case 3:
              this.setState({
                showExtInstruction: false,
              });
              break;

            default:
              break;
          }
        }}
        disabled={choose_state === 2 && !this.state.continueAfterExtAdd}>
        Continue
      </Button>
    );
  }

  renderProgress = (step) => (
    <div
      className="demoProgress justify-content-center"
      style={{margin: '0 auto'}}>
      <div className="demoCircle done">
        <span className="label">
          <DoneIcon fontSize={window.innerWidth <= 768 ? 'small' : 'default'} />
        </span>
        <span className="description">Introduction</span>
      </div>
      <span className="demoBar done" />

      <div className={step < 2 ? 'demoCircle' : 'demoCircle done'}>
        <span className="label">
          {step < 2 ? (
            '2'
          ) : (
            <DoneIcon
              fontSize={window.innerWidth <= 768 ? 'small' : 'default'}
            />
          )}
        </span>
        <span className="description">Chrome Extension</span>
      </div>
      <span className={step < 2 ? 'demoBar' : 'demoBar done'} />

      <div className={step < 3 ? 'demoCircle' : 'demoCircle done'}>
        <span className="label">
          {step < 3 ? (
            '3'
          ) : (
            <DoneIcon
              fontSize={window.innerWidth <= 768 ? 'small' : 'default'}
            />
          )}
        </span>
        <span className="description">Select Topics</span>
      </div>
      <span className={step < 3 ? 'demoBar' : 'demoBar done'} />

      <div className={step < 4 ? 'demoCircle' : 'demoCircle done'}>
        <span className="label">
          {step < 4 ? (
            '4'
          ) : (
            <DoneIcon
              fontSize={window.innerWidth <= 768 ? 'small' : 'default'}
            />
          )}
        </span>
        <span className="description">Wanderers</span>
      </div>

      {
        // <span className={step < 3 ? "demoBar" : "demoBar done"}></span>
        // <div className={step < 4 ? "demoCircle" : "demoCircle done"}>
        //   <span className="label">{step < 4 ? "4" : <DoneIcon fontSize={window.innerWidth <= 768 ? 'small': 'medium'} />}</span>
        //   <span className="description">Add to Wander</span>
        // </div>
      }
    </div>
  );

  renderIntro() {
    return (
      <Modal
        size="lg"
        contentClassName="demoModal"
        show={this.props.show}
        onHide={this.handleDismiss}
        backdrop={this.props.first ? 'static' : true}
        centered>
        <Modal.Body className="justify-content-center">
          <h2
            style={{fontWeight: 'bold', padding: '10px'}}
            className="pt-3 demoIntroTitle">
            Welcome to Wander!{' '}
            <span role="img" aria-label="waving hand">
              👋
            </span>
          </h2>
          <div
            style={{
              height: '70%',
              padding: '10px',
              fontFamily: 'Roboto',
              fontHeight: '2vh',
              fontSize: '2.25vh',
            }}>
            <div className="demoIntroContent">
              <p>
                Thanks for taking the first step with us in building a better
                social media platform. Wander makes it easy to recommend and
                discuss intellectual content.
              </p>
              <p>To get set up, we'll take the time to:</p>
            </div>

            <div className="demoIntro">
              <div className="demoIntroItem">
                <GroupAddIcon style={{color: '#E87135', fontSize: '300%'}} />
                <p>
                  <b>Add people you care about</b>
                  <br></br>By selecting shared topics of interest
                </p>
              </div>
              <div className="demoIntroItem">
                <AccountCircleIcon
                  style={{color: '#E87135', fontSize: '300%'}}
                />
                <p>
                  <b>Set up your profile</b>
                  <br></br>Build out your intellectual homepage
                </p>
              </div>
              {
                // <div className='demoIntroItem'>
                //   <Image src={wanderW} style={{marginLeft: '-1vh', width: '9vh', height: '7vh'}} className='wander-logo' />
                //   <p className='demoIntroItem3'><b>Make Wander easy to come back to</b><br></br>Install the Chrome Extension and Mobile App</p>
                // </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.renderProgress(1)}
          {this.renderContinue(1)}
        </Modal.Footer>
      </Modal>
    );
  }

  renderAddToChrome() {
    return (
      <Button
        variant="primary"
        style={{
          paddingLeft: '50px',
          paddingRight: '50px',
          borderRadius: '15px',
          fontFamily: 'Roboto',
          fontWeight: '700',
        }}
        onClick={() => {
          this.setState({continueAfterExtAdd: true});
          window.open(
            'https://chrome.google.com/webstore/detail/wander-connecting-the-wor/jlnlinahcngkalagopmboffcpppaefkp',
            '_blank',
          );
        }}>
        Add to Chrome
      </Button>
    );
  }

  renderChromeExtension() {
    return (
      <Modal
        size="lg"
        contentClassName="demoModal"
        show={this.props.show}
        onHide={this.handleDismiss}
        backdrop={this.props.first ? 'static' : true}
        centered>
        <Modal.Body>
          <h1 style={{fontWeight: 'bold', padding: '10px'}} className="pt-3">
            wander
          </h1>
          <h3 style={{fontWeight: 'bold', padding: '10px', marginTop: '-10px'}}>
            recommend and discuss intellectual content
          </h3>
          <div style={{height: '70%', padding: '10px'}}>
            <p
              style={{
                fontSize: '3vh',
                fontFamily: 'Roboto',
                lineHeight: '4vh',
              }}>
              Our Chrome Extension makes it easy to post links to Wander from
              anywhere on the web, whether you’re saving it for later in your
              Unread or sharing it publicly with your network.
            </p>
            <div className="demoChromeExtButton">
              {this.renderAddToChrome()}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.renderProgress(2)}
          {this.renderContinue(2)}
        </Modal.Footer>
      </Modal>
    );
  }

  // renderConnectNext() {
  //   return (
  //     <Button
  //       variant="secondary"
  //       style={
  //         window.innerWidth <= 768
  //           ? {
  //               borderRadius: '15px',
  //               paddingLeft: '15px',
  //               paddingRight: '15px',
  //               fontWeight: 'bold',
  //               marginRight: '3px',
  //               fontSize: '13px',
  //               fontFamily: 'Roboto',
  //             }
  //           : {
  //               borderRadius: '15px',
  //               paddingLeft: '30px',
  //               paddingRight: '30px',
  //               fontSize: '16px',
  //               fontWeight: 'bold',
  //               marginRight: '5px',
  //               fontFamily: 'Roboto',
  //             }
  //       }
  //       onClick={() =>
  //         this.setState({showExtInstruction: false, showConnect: true})
  //       }>
  //       Next
  //     </Button>
  //   );
  // }

  renderExtensionInstructions() {
    return (
      <Modal
        size="lg"
        contentClassName="demoModal"
        show={this.props.show}
        onHide={this.handleDismiss}
        backdrop={this.props.first ? 'static' : true}
        centered>
        <Modal.Body>
          <div style={{height: '100%', padding: '10px'}}>
            <h3 style={{fontFamily: 'Roboto', fontWeight: 'bold'}}>
              Please sign in to the Wander Chome extension.
            </h3>
            <br></br>
            <Image
              src="https://i.imgur.com/zMNKuBv.gif"
              alt="Signing into Wander Chrome extension instructions"
              fluid
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.renderProgress(2)}
          {this.renderContinue(3)}
        </Modal.Footer>
      </Modal>
    );
  }

  // renderConnect = () => {
  //   return (
  //     <Button
  //       variant='primary'
  //       style={{
  //         fontFamily: 'Roboto',
  //         fontSize: '18px',
  //         paddingLeft: '40px',
  //         paddingRight: '40px',
  //         borderRadius: '15px',
  //         fontWeight: 'bold',
  //         marginLeft: '15px'
  //       }}
  //       onClick={() => {
  //         if (window.confirm("You’ll need to be logged-in to LinkedIn on this browser to continue.\r\n\nWander will open and scroll through LinkedIn to start importing. Please keep this window visible until this process is complete.")) {
  //           window.open('https://www.linkedin.com/search/results/people/?network=%5B"F"%5D&origin=MEMBER_PROFILE_CANNED_SEARCH');
  //         }
  //       }}
  //     >
  //       Connect
  //     </Button>
  //   );
  // }

  // renderConnectToLinkedin = () => {
  //   return (
  //     <Modal size='lg' contentClassName="demoModal" show={this.props.show} onHide={this.handleDismiss} backdrop={this.props.first ? 'static': true} centered>
  //       <Modal.Body>
  //       <h3 style={{fontWeight: 'bold', padding: '10px'}} className='pt-3'>Connect your LinkedIn</h3>
  //       <div style={{height:"100%", padding: '10px', fontSize: '2vh', lineHeight: '3vh'}}>
  //         <p>
  //           Wander works best when you can add contacts using existing contact sources.
  //         </p>
  //         <br></br>
  //         <p>After connecting your LinkedIn, Wander will consolidate information and show you who you know on Wander.</p>
  //         <div className='demoLinkedinBox'>
  //           <LinkedInIcon style={{color: '#0077B5', fontSize: '300%', marginRight: '15px'}} />
  //           {this.renderConnect()}
  //         </div>
  //       </div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         {this.renderProgress(3)}
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // }

  renderContinueToWander() {
    return (
      <Button
        variant="primary"
        size={window.innerWidth <= 768 ? 'sm' : 'lg'}
        onClick={() => this.setState({showPeople: false, playVideo: true})}>
        Continue
      </Button>
    );
  }

  renderLinkedinConnections = () => {
    if (!this.state.followings) return <div>Loading...</div>;
    return this.state.followings.map((user) => (
      <Row key={user.user_id}>
        <Col xs={12}>
          <Row
            style={
              window.innerWidth <= 768
                ? {margin: '0px', marginTop: '13px'}
                : {margin: '0px'}
            }>
            <Col
              xs={2}
              md={1}
              align="left"
              style={
                window.innerWidth <= 768
                  ? {paddingLeft: 0, paddingRight: 0}
                  : {paddingLeft: 20, paddingRight: 0}
              }>
              <Image
                src={user.profile_pic ? user.profile_pic : defaultWander}
                style={{boxShadow: 'none'}}
                className="profileIMG"
                roundedCircle
                height="50"
                width="50"
              />
            </Col>

            <Col
              xs={6}
              md={8}
              align="left"
              style={
                window.innerWidth <= 768 ? {paddingLeft: 10} : {paddingLeft: 20}
              }>
              <Row style={{margin: '0px'}}>
                <div
                  style={
                    window.innerWidth <= 768
                      ? {display: 'block'}
                      : {display: 'none'}
                  }>
                  <div style={{fontWeight: 'bold', fontFamily: 'Roboto'}}>
                    {user.full_name}
                  </div>
                  <div style={{color: 'grey', fontFamily: 'Roboto'}}>
                    <h6>{`@${user.username}`}</h6>
                  </div>
                </div>
                <pre
                  style={
                    window.innerWidth <= 768
                      ? {display: 'none'}
                      : {display: 'block'}
                  }>
                  <div style={{fontWeight: 'bold', fontFamily: 'Roboto'}}>
                    <h5>{user.full_name}</h5>
                  </div>
                  <div style={{color: 'grey', fontFamily: 'Roboto'}}>
                    <h6>{`@${user.username}`}</h6>
                  </div>
                </pre>
              </Row>
            </Col>
            <Col
              xs={3}
              md={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 0,
                paddingRight: 0,
              }}>
              <Row
                style={
                  window.innerWidth <= 768
                    ? {
                        margin: '0px',
                        verticalAlign: 'middle',
                        marginTop: '-5px',
                      }
                    : {margin: '0px', verticalAlign: 'top', marginTop: '-20px'}
                }>
                <FollowButton
                  userID={user.user_id}
                  name={user.full_name}
                  following={true}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    ));
  };

  renderNetwork = () => {
    this.api
      .getSocialPosts(10)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setSocialPosts(response.data));
        }
      })
      .catch((error) => console.warn(error));
    return (
      <Modal
        size="lg"
        contentClassName="demoModal"
        show={this.props.show}
        onHide={this.handleDismiss}
        backdrop={this.props.first ? 'static' : true}
        centered>
        <Modal.Header className="justify-content-center">
          <h3 style={{fontWeight: '300'}} className="pt-3">
            {' '}
            Meet your new Wanderers!
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div style={{height: '100%'}}>
            <div className="demoLinkedinFollowing">
              {this.renderLinkedinConnections()}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.renderProgress(4)}
          {this.renderFinish()}
        </Modal.Footer>
      </Modal>
    );
  };

  onTagPress = (tagID: number) => {
    if (this.state.selectedTags.includes(tagID)) {
      // Segment Event
      window.analytics.track(
        'Tag Unselected',
        this.props.tags.filter((tag) => tag.tag_id === tagID),
      );
      this.setState({
        selectedTags: this.state.selectedTags.filter(
          (selectedTag) => selectedTag !== tagID,
        ),
      });
    } else {
      // Segment Event
      window.analytics.track(
        'Tag Selected',
        this.props.tags.filter((tag) => tag.tag_id === tagID),
      );
      this.setState({
        selectedTags: this.state.selectedTags.concat(tagID),
      });
    }
  };

  // set current to false and show pwoplw to true
  renderAfterTagPreferences = () => {
    if (this.state.selectedTags.length < 1) {
      return null;
    }

    const buttonColor = this.state.loading ? Colors.secondary : Colors.primary;
    return (
      <Button
        id="tagButton"
        variant="primary"
        style={
          window.innerWidth <= 768
            ? {
                borderRadius: '15px',
                paddingLeft: '15px',
                paddingRight: '15px',
                fontWeight: 'bold',
                marginRight: '3px',
                fontSize: '13px',
                fontFamily: 'Roboto',
                backgroundColor: buttonColor,
                borderColor: buttonColor,
              }
            : {
                borderRadius: '15px',
                paddingLeft: '30px',
                paddingRight: '30px',
                fontSize: '16px',
                fontWeight: 'bold',
                marginRight: '5px',
                fontFamily: 'Roboto',
                backgroundColor: buttonColor,
                borderColor: buttonColor,
              }
        }
        onClick={() => {
          this.setState({loading: true});
          this.api
            .followFromTags(this.state.selectedTags, this.props.user.id)
            .then((response) => {
              if (response.ok) {
                this.setState({
                  showPeople: true,
                  followings: response.data,
                  loading: false,
                });
              }
            })
            .catch((error) => console.warn(error));
        }}>
        {this.state.loading ? 'Loading...' : 'Continue'}
      </Button>
    );
  };

  renderTagPreferences = () => {
    const tags = [];
    for (const tag of this.props.tags) {
      tags.push(
        <Col sm={4} xs={6} key={tag.tag_id}>
          <Tag
            clickable
            id={tag.tag_id}
            className="createPostTag"
            // color={tag.hex_bg_color}
            // textColor={tag.hex_text_color}
            color="#ffc5a5"
            textColor="#0"
            text={tag.text}
            isSelected={this.state.selectedTags.includes(tag.tag_id)}
            handlePress={this.onTagPress}
          />
        </Col>,
      );
    }
    return (
      <Modal
        size="lg"
        contentClassName="demoModal"
        show={this.props.show}
        onHide={this.handleDismiss}
        backdrop={this.props.first ? 'static' : true}
        centered>
        <Modal.Body className="justify-content-center">
          <Container fluid className="my-2 border rounded">
            <Row className="justify-content-center my-2">
              <Col md="auto">
                <h6 className="my-auto text-center">Select one or more tags</h6>
              </Col>
            </Row>
            <Row>{tags}</Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {this.renderProgress(3)}
          {this.renderAfterTagPreferences()}
        </Modal.Footer>
      </Modal>
    );
  };

  render = () => {
    const isChrome = window.navigator.userAgent.indexOf('Chrome') > -1;
    let isMobile = false;
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      isMobile = true;
    }

    if (this.state.showIntro) {
      return this.renderIntro();
    } else if (isChrome && this.state.showExtInstruction && !isMobile) {
      return this.renderExtensionInstructions();
    } else if (isChrome && this.state.showExtension && !isMobile) {
      return this.renderChromeExtension();
    } else if (this.state.showPeople) {
      return this.renderNetwork();
    } else if (this.state.playVideo) {
      return this.renderVideo();
    } else {
      return this.renderTagPreferences();
    }
  };
}

const mapStateToProps = (state) => ({
  focusActive: state.unread.focusActive,
  user: state.user,
  tags: state.feed.tags,
});
export default connect(mapStateToProps)(Demo);
