// @flow

import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import './Styles/Landing.css';
import bubble1 from './Styles/images/bubble_1.png';
import bubble2 from './Styles/images/bubble_2.png';
import bubble3 from './Styles/images/bubble_3.png';
import bubble4 from './Styles/images/bubble_4.png';
import bubble5 from './Styles/images/bubble_5.png';
import bubble6 from './Styles/images/bubble_6.png';
import bubble7 from './Styles/images/bubble_7.png';
import smallBubble1 from './Styles/images/small_bubble_1.png';
import smallBubble2 from './Styles/images/small_bubble_2.png';
import smallBubble3 from './Styles/images/small_bubble_3.png';
import smallBubble4 from './Styles/images/small_bubble_4.png';
import wanderLandingPageLogo from './Styles/images/wanderlogo.png';
import wanderLandingPageLogo2 from './Styles/images/wanderlogo2.png';
import socialMediaButtons from './Styles/images/socialMediaButtons.png';
import landingPagePreview1 from './Styles/images/preview1.png';
import landingPagePreview2 from './Styles/images/preview2.png';
import landingPagePreview3 from './Styles/images/preview3.png';
import landingPagePreview4 from './Styles/images/preview4.png';
import chatLogo from './Styles/images/chat_logo.png';
import peopleLogo from './Styles/images/people_logo.png';
import listLogo from './Styles/images/list_logo.png';
import upArrow from './Styles/images/up_arrow.png';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'landing',
    };
  }

  handleOnClick = (selectedItem, mode) => {
    window.analytics.track(selectedItem, this.state);
    this.setState({
      page: 'login',
      mode: mode,
    });
  };

  handleToTop = () => {
    // works for all browsers
    const topOfPage = document.getElementById('topOfPage');
    document.querySelector('.section-fullscreen').scrollTo(0, topOfPage.top);
  };

  // for webviews that have more of a horizontal aspect ratio
  landingPage = () => {
    let isMobile;
    if (window.innerWidth > 600) {
      isMobile = true;
    } else {
      isMobile = false;
    }

    return (
      <div id="topOfPage" className="section-fullscreen">
        <div className="producthunt-badge">
          <a href="https://www.producthunt.com/posts/wander-c84dfefb-0ac4-4ec7-a850-741d74e15bdd?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-wander-c84dfefb-0ac4-4ec7-a850-741d74e15bdd" target="_blank" rel="noopener noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=319786&theme=light" alt="Wander - Share and discuss intellectual content | Product Hunt" style={{width: '250px', height: '54px'}} /></a>
        </div>
        <span id="createLogin" className="createlogin">
          <Button
            id="createLoginText"
            className="text-center"
            variant="primary"
            onClick={() => this.handleOnClick('login clicked', 'login')}>
            <b>log in</b>
          </Button>
        </span>
        <span id="spanWanderLandingPageLogo" className="wanderLandingPageLogo">
          <img
            id="wanderLandingPageLogoID"
            src={wanderLandingPageLogo}
            alt="Wander logo"
          />
        </span>
        <div id="divBubble_1" className="bubble">
          <span className="one item">
            <img id="bubble_1" src={bubble1} alt="bubble" />
            <div id="bubble_1_text_1" className="one text part1">
              <b>Wander</b>
            </div>
            <div id="bubble_1_text_2" className="one text part2">
              share and discuss intellectual content
            </div>
            <div id="beta_tag">(Beta)</div>
            <span className="createlink">
              <Button
                id="createLinkText"
                variant="secondary"
                onClick={() =>
                  this.handleOnClick('top-create-account clicked', 'signup')
                }>
                <b>create an account</b>
              </Button>
            </span>
          </span>
          <span id="two" className="two item">
            <img id="bubble_2" src={bubble2} alt="bubble" />
            <div id="bubble_2_text_1" className="two text part1">
              <b>What do we stand for?</b>
            </div>
            <div id="bubble_2_text_2" className="two text part2">
              <b>curiosity.</b> not clickbait. <br />
              <br />
              <b>collaboration.</b> not controversy. <br />
              <br />
              <b>community.</b> not cliques.
            </div>
          </span>
        </div>
        <div id="divBubble_3" className="bubble">
          <span id="three" className="three item">
            <img id="bubble_3" src={bubble3} alt="bubble" />
            <img
              id="landingPagePreview_1"
              className="landingPagePreview1"
              src={landingPagePreview1}
              alt="landing page preview"
            />
          </span>
          <span id="smallOne" className="smallOne">
            <img id="smallBubble_1" src={smallBubble1} alt="small bubble" />
            <img className="chatLogo" src={chatLogo} alt="Chat icon" />
            <div id="smallBubble_1_text_1" className="three text part1">
              <b>recommend content</b>
            </div>
            <div id="smallBubble_1_text_2" className="three text part2">
              every post on Wander is a personal recommendation from a fellow
              Wanderer
            </div>
          </span>
        </div>
        <div id="divBubble_4" className="bubble">
          <span id="four" className="four item">
            <img id="bubble_4" src={bubble4} alt="bubble" />
            <img
              id="landingPagePreview_2"
              className="landingPagePreview2"
              src={landingPagePreview2}
              alt="landing page preview"
            />
          </span>
          <span id="smallTwo" className="smallTwo">
            <img id="smallBubble_2" src={smallBubble2} alt="small bubble" />
            <img className="peopleLogo" src={peopleLogo} alt="people icon" />
            <div id="smallBubble_2_text_1" className="four text part1">
              <b>shoutouts & comments</b>
            </div>
            <div id="smallBubble_2_text_2" className="four text part2">
              engage your network in content you love and hold meaningful
              discussions to foster collective insight
            </div>
          </span>
        </div>
        <div id="divBubble_5" className="bubble">
          <span id="five" className="five">
            <img id="bubble_5" src={bubble5} alt="bubble" />
            <img
              id="landingPagePreview_3"
              className="landingPagePreview3"
              src={landingPagePreview3}
              alt="landing page preview"
            />
          </span>
          <span id="smallThree" className="smallThree">
            <img id="smallBubble_3" src={smallBubble3} alt="small bubble" />
            <img
              id="listLogo"
              className="listLogo"
              src={listLogo}
              alt="list icon"
            />
            <div id="smallBubble_3_text_1" className="five text part1">
              <b>unread</b>
            </div>
            <div id="smallBubble_3_text_2" className="five text part2">
              store, prioritize, and schedule content you want to get back to
              later
            </div>
          </span>
        </div>
        <div id="divBubble_6" className="bubble">
          <span id="six" className="six item">
            <img id="bubble_6" src={bubble6} alt="bubble" />
            <img
              id="landingPagePreview_4"
              className="landingPagePreview4"
              src={landingPagePreview4}
              alt="landing page preview"
            />
          </span>
          <span id="smallFour" className="smallFour">
            <img id="smallBubble_4" src={smallBubble4} alt="small bubble" />
            <img
              id="listLogo_1"
              className="listLogo"
              src={listLogo}
              alt="list icon"
            />
            <div id="smallBubble_4_text_1" className="six text part1">
              <b>collections</b>
            </div>
            <div id="smallBubble_4_text_2" className="six text part2">
              categorize and save content you want to keep forever
            </div>
          </span>
        </div>
        <div id="divBubble_7" className="bubble item">
          <span id="seven" className="seven ">
            {isMobile ? (
              <span id="toTop" className="toTop">
                <img id="upArrow" src={upArrow} alt="arrow" />
                <Button
                  id="toTopHover"
                  className="toTopHover"
                  onClick={() => this.handleToTop()}>
                  {' '}
                  back to top{' '}
                </Button>
              </span>
            ) : null}

            <img id="bubble_7" src={bubble7} alt="bubble" />
            <img
              id="wanderLandingPageLogoID2"
              src={wanderLandingPageLogo2}
              className="chromeExtensionLogo"
              alt="Chrome Extension logo"
            />
            <img
              id="socialMediaButtons"
              src={socialMediaButtons}
              className="socialMediaButtons"
              alt="social media buttons"
            />
            <div id="bubble_4_text_1" className="seven text part1">
              <b>join the community!</b>
            </div>
            <div id="bubble_4_text_2" className="seven text part2">
              <a
                href="https://chrome.google.com/webstore/detail/wander-connecting-the-wor/jlnlinahcngkalagopmboffcpppaefkp?hl=en"
                style={{color: '#1E223B'}}>
                <b>download the chrome extension</b>
              </a>
            </div>
            <div id="bubble_4_text_3" className="seven text part3">
              <a
                href="https://apps.apple.com/us/app/wander/id1554598084"
                style={{color: '#1E223B'}}>
                <b>download the iOS app</b>
              </a>
            </div>
            <span id="createLink_1" className="createlink_1">
              <Button
                id="createlinktext_1"
                variant="primary"
                className="createlinktext_1"
                onClick={() =>
                  this.handleOnClick('bottom-create-account clicked', 'signup')
                }>
                <b> create an account </b>
              </Button>
            </span>
          </span>
        </div>
      </div>
    );
  };

  componentDidMount() {
    // Segment pageview call
    window.analytics.page('Landing');
    // Given url of     ____.com/src=<input>   an analytics message that says User from <input> will be tracked
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if ('src' in params) {
      const createAnalyticsMsg = `User came from ${params.src}`;
      window.analytics.track(createAnalyticsMsg);
    }
  }

  render() {
    window.onresize = () => {
      this.setState({rerender: true});
    };
    if (this.state.page === 'login') {
      return (
        <Redirect
          to={{
            pathname: '/login',
            search: window.location.search,
            mode: this.state.mode,
          }}
        />
      );
    } else {
      return this.landingPage();
    }
  }
}

export default Landing;
