// @flow

import React, {Component} from 'react';
import './Styles/Tag.css';
import {Badge} from 'react-bootstrap';

type Props = {
  id: number,
  text: string,
  color: string,
  textColor: string,
  clickable?: boolean,
  className?: string,
  handlePress?: (number) => void,
  isSelected?: boolean,
};

export default class Tag extends Component<Props> {
  static defaultProps = {
    clickable: false,
    isSelected: false,
  };

  handleClick = () => {
    if (this.props.clickable && this.props.handlePress) {
      this.props.handlePress(this.props.id);
    }
  };

  renderTag = () => {
    if (this.props.className === 'createPostTag') {
      return (
        <div
          onClick={this.handleClick}
          id={this.props.clickable ? 'tagButton' : ''}>
          <Badge
            className={
              this.props.isSelected
                ? 'selectedTagCreate text-truncate'
                : 'unselectedTagCreate text-truncate'
            }
            style={{
              backgroundColor: this.props.color,
              color: this.props.textColor,
            }}>
            {this.props.text}
          </Badge>
        </div>
      );
    } else {
      return (
        <div
          onClick={this.handleClick}
          id={this.props.clickable ? 'tagButton' : ''}>
          <Badge
            className={this.props.isSelected ? 'selectedTag' : 'unselectedTag'}
            style={{
              backgroundColor: this.props.color,
              color: this.props.textColor,
            }}>
            {this.props.text}
          </Badge>
        </div>
      );
    }
  };

  render() {
    if (this.props.clickable) {
      return <h4 className={this.props.className}>{this.renderTag()}</h4>;
    } else {
      return <h6 className={this.props.className}>{this.renderTag()}</h6>;
    }
  }
}
