// @flow

import React, {Component} from 'react';
import {Card, Form, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import API from '../Services/API';
import {toast} from 'react-toastify';

type Props = {
  user: {
    name: string,
    id: number,
  },
};

type State = {
  text: string,
  triedSubmitting: boolean,
};
class FeedbackBox extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      text: '',
      triedSubmitting: false,
    };
  }

  handleFeedbackSubmit = () => {
    if (this.state.text.length > 0) {
      // Segment Event
      window.analytics.track('Feedback Submitted', {
        user: this.props.user,
        feedback: this.state.text,
      });
      this.api.submitFeedback(this.state.text);
      toast('Got your feedback!', {containerId: 'N'});
      this.setState({
        text: '',
        triedSubmitting: false,
      });
    } else {
      this.setState({triedSubmitting: true});
    }
  };

  handleChange = (event: any) => {
    this.setState({text: event.currentTarget.value});
  };

  render() {
    return (
      <div className="feedback-form">
        <Card
          className="bg-light"
          style={{
            width: '100%',
            border: 'none',
            fontWeight: '300',
            fontSize: '17px',
          }}>
          <Card.Body>
            <Card.Text className="mb-2">
              We want to do better. Type any and all feedback here, suggestions,
              bugs and feature ideas here.
            </Card.Text>
            <Form>
              <Form.Group controlId="feedbackTextArea">
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Share your thoughts"
                  isInvalid={
                    this.state.triedSubmitting && this.state.text.length === 0
                  }
                  onChange={this.handleChange}
                  value={this.state.text}
                  style={{fontSize: '15px'}}
                />
                <div className="feedback-submit pt-3">
                  <Button
                    className="shadow-none"
                    onClick={this.handleFeedbackSubmit}
                    variant="outline-primary">
                    Submit
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: {
    name: state.user.name,
    id: state.user.id,
  },
});
export default connect(mapStateToProps)(FeedbackBox);
