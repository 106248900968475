/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-bind */
// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import API from '../Services/API';
import AdminActions from '../Redux/AdminRedux';
import './Styles/Admin.css';
import {Button, Modal, Row, Col} from 'react-bootstrap';
import Colors from '../Config/Colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

type Props = {
  dispatch: ({}) => void,
};

type State = {};

class Admin extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      showTestUsers: false,
      showManageAdmin: false,
      showDeleteUser: false,
      showCreateTestUser: false,
      showAddAdmin: false,
      username: '',
      showDeleteUserModal: false,
      userToDelete: '',
      showRemoveAdmin: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // initialize the test and admin users on start up
    this.api
      .getTestUsers()
      .then((response) => {
        if (response.data !== undefined) {
          this.props.dispatch(AdminActions.setTestUsers(response.data));
        }
      })
      .catch((error) => console.warn(error));

    this.api
      .getAdminUsers()
      .then((response) => {
        if (response.data !== undefined) {
          this.props.dispatch(AdminActions.setAdminUsers(response.data));
        }
      })
      .catch((error) => console.warn(error));
  }

  renderHelpfulLinks = () => (
    <Col
      lg={{span: 4, order: 2}}
      xs={{span: 12, order: 2}}
      style={{fontWeight: '300'}}
      className="text-center pl-lg-4">
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12} className="pb-3">
              <h3
                style={{fontWeight: '300', color: Colors.primary}}
                className="adminHeader">
                Helpful Links
              </h3>
            </Col>
          </Row>
          <Row className="mb-1 text-left">
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <h5 style={{fontWeight: '300'}} className="mb-1">
                    <span
                      style={{color: 'black', cursor: 'pointer'}}
                      onClick={() => {
                        window.open(
                          'https://www.notion.so/Admin-Account-Guide-f3a79f78608c4605b92a7c5e5211d236',
                        );
                      }}>
                      Admin Account Guide
                    </span>
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-1 text-left">
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <h5 style={{fontWeight: '300'}} className="mb-1">
                    <span
                      style={{color: 'black', cursor: 'pointer'}}
                      onClick={() => {
                        window.open(
                          'https://www.notion.so/Test-Users-Guide-70758edd11dd4c62b66637032c7df2d2',
                        );
                      }}>
                      Test Users Guide
                    </span>
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );

  renderDashboardLinks() {
    return (
      <div>
        <Row
          className="shadow mx-0 py-2 mb-3"
          style={{fontWeight: '300', borderRadius: '5px', cursor: 'pointer'}}
          onClick={() => this.setState({showTestUsers: true})}>
          <Col xs="auto" />
          <Col xs={10} className="text-left px-0 my-auto">
            <p className="my-auto">Manage Test Users</p>
          </Col>
          <Col xs="auto" />
        </Row>
        <Row
          className="shadow mx-0 py-2 mb-3"
          style={{fontWeight: '300', borderRadius: '5px', cursor: 'pointer'}}
          onClick={() => this.setState({showManageAdmin: true})}>
          <Col xs="auto" />
          <Col xs={10} className="text-left px-0 my-auto">
            <p className="my-auto">Manage Admin Accounts</p>
          </Col>
          <Col xs="auto" />
        </Row>
        <Row
          className="shadow mx-0 py-2 mb-3"
          style={{fontWeight: '300', borderRadius: '5px', cursor: 'pointer'}}
          onClick={() => this.setState({showDeleteUser: true})}>
          <Col xs="auto" />
          <Col xs={10} className="text-left px-0 my-auto">
            <p className="my-auto">Delete User</p>
          </Col>
          <Col xs="auto" />
        </Row>
      </div>
    );
  }

  // shows the dashboard where admins can see a list of what they can do
  renderDashboard() {
    return (
      <div>
        <Row className="mt-5 mx-0 pt-5 pt-lg-0 pb-4 pb-lg-0 justify-content-center">
          <Col sm={11} xs={12}>
            <Row>
              <Col
                lg={{span: 8, order: 1}}
                xs={{span: 12, order: 1}}
                className="text-center">
                <Row>
                  <Col xs={12} className="pb-3">
                    <h3
                      style={{fontWeight: '300', color: Colors.primary}}
                      className="adminHeader">
                      Admin Dashboard
                    </h3>
                  </Col>
                </Row>
                {this.renderDashboardLinks()}
              </Col>
              {this.renderHelpfulLinks()}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  handleDeleteUser(userId, userType) {
    this.api
      .deleteUser(userId)
      .then((response) => {
        if (response.ok) {
          if (userType === 'test') {
            this.props.dispatch(AdminActions.removeTestUser(userId));
          }
          this.setState({showDeleteUserModal: false, userToDelete: ''});
        }
      })
      .catch((error) => console.warn(error));
  }

  renderTestUsers() {
    const testUsers = this.props.testUsers;

    return (
      <div>
        {testUsers.map((user) => (
          <Row
            key={user.user_id}
            className="shadow mx-0 py-2 mb-3"
            style={{fontWeight: '300', borderRadius: '5px'}}>
            <Col xs="auto" />
            <Col xs={9} className="text-left px-0 my-auto">
              <p className="my-auto">
                {'@' + user.username + ' (' + user.username + '@test.com)'}
              </p>
            </Col>
            <Col xs={1} className="text-right px-0 my-auto">
              <DeleteIcon
                className="my-auto"
                onClick={() =>
                  this.setState({showDeleteUserModal: true, userToDelete: user})
                }
                style={{cursor: 'pointer'}}
              />
            </Col>
            <Col xs="auto" />
          </Row>
        ))}
        <Modal
          show={this.state.showDeleteUserModal}
          onHide={() => this.setState({showDeleteUserModal: false})}
          centered>
          <Modal.Body>
            <p className="text-center pt-3">
              Are you sure you want to permanently delete @
              {this.state.userToDelete.username}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.handleDeleteUser(this.state.userToDelete.user_id, 'test')
              }>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.setState({showDeleteUserModal: false})}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleCreateTestUser() {
    this.api
      .createTestUser(this.state.username)
      .then((response) => {
        if (response.data !== undefined) {
          this.props.dispatch(AdminActions.addTestUser(response.data));
          this.setState({showCreateTestUser: false, username: ''});
        }
      })
      .catch((error) => console.warn(error));
  }

  renderManageTestUsers() {
    return (
      <div>
        <Row className="mt-5 mx-0 pt-5 pt-lg-0 pb-4 pb-lg-0 justify-content-center">
          <Col sm={11} xs={12}>
            <Row>
              <Col
                lg={{span: 8, order: 1}}
                xs={{span: 12, order: 1}}
                className="text-center">
                <Row>
                  <Col xs="auto" className="pb-3">
                    <ArrowBackIcon
                      fontSize="large"
                      className="backButton"
                      style={{cursor: 'pointer', color: Colors.primary}}
                      onClick={() =>
                        this.setState({showTestUsers: false, username: ''})
                      }
                    />
                  </Col>
                  <Col xs={10} className="pb-3">
                    <h3
                      style={{fontWeight: '300', color: Colors.primary}}
                      className="adminHeader">
                      Manage Test Users
                    </h3>
                  </Col>
                </Row>
                <Modal
                  show={this.state.showCreateTestUser}
                  onHide={() => this.setState({showCreateTestUser: false})}
                  centered>
                  <Modal.Body>
                    <Row className="px-3">
                      <Col xs={12} className="text-right">
                        <CloseRoundedIcon
                          style={{cursor: 'pointer', color: 'gray'}}
                          onClick={() =>
                            this.setState({showCreateTestUser: false})
                          }
                        />
                      </Col>
                      <Col xs={12} className="displayLink">
                        <div className="form__group field">
                          <input
                            type="input"
                            onChange={(username) => {
                              this.setState({
                                username: username.target.value,
                              });
                            }}
                            className="form__field"
                            value={this.state.username}
                            placeholder="Username"
                            name="username"
                            style={{zIndex: '1'}}
                          />
                          <label
                            htmlFor="username"
                            className="form__label"
                            style={{zIndex: '0'}}>
                            Test User's Username
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="text-center mt-3 px-3">
                      <Col className="text-center pr-1">
                        <Button
                          disabled={this.state.username ? false : true}
                          onClick={this.handleCreateTestUser.bind(this)}
                          className="shadow-none"
                          size="sm"
                          variant="primary">
                          Create Test User
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>
                <Row>
                  <Col xs={4}>
                    <Button
                      onClick={() => this.setState({showCreateTestUser: true})}
                      size="md"
                      className="mt-2 mb-4 text-right">
                      <AddIcon size="sm" /> Test User
                    </Button>
                  </Col>
                  <Col xs="auto" />
                </Row>

                {this.renderTestUsers()}
              </Col>
              {this.renderHelpfulLinks()}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  handleRemoveAdminUser(userId) {
    this.api
      .setUserRole(userId, 'regular')
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(AdminActions.removeAdminUser(userId));
          this.setState({showRemoveAdmin: false, userToDelete: ''});
        }
      })
      .catch((error) => console.warn(error));
  }

  renderAdminUsers() {
    const adminUsers = this.props.adminUsers;
    const currUser = this.props.user;

    return (
      <div>
        {adminUsers.map((user) => (
          <Row
            key={user.user_id}
            className="shadow mx-0 py-2 mb-3"
            style={{fontWeight: '300', borderRadius: '5px'}}>
            <Col xs="auto" />
            <Col xs={9} className="text-left px-0 my-auto">
              <p className="my-auto">
                {user.full_name + ' (@' + user.username + ')'}
              </p>
            </Col>
            <Col xs={1} className="text-right px-0 my-auto">
              {currUser.id !== user.user_id ? (
                <DeleteIcon
                  className="my-auto"
                  onClick={() =>
                    this.setState({showRemoveAdmin: true, userToDelete: user})
                  }
                  style={{cursor: 'pointer'}}
                />
              ) : null}
            </Col>
            <Col xs="auto" />
          </Row>
        ))}
        <Modal
          show={this.state.showRemoveAdmin}
          onHide={() => this.setState({showRemoveAdmin: false})}
          centered>
          <Modal.Body>
            <p className="text-center pt-3">
              Are you sure you want to remove @
              {this.state.userToDelete.username} as admin?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.handleRemoveAdminUser(this.state.userToDelete.user_id)
              }>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.setState({showRemoveAdmin: false})}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  handleCreateAdminUser() {
    this.api
      .setUserAdmin(this.state.username)
      .then((response) => {
        if (response.data !== undefined) {
          this.props.dispatch(AdminActions.addAdminUser(response.data));
          this.setState({showAddAdmin: false, username: ''});
        }
      })
      .catch((error) => console.warn(error));
  }

  renderManageAdmin() {
    return (
      <div>
        <Row className="mt-5 mx-0 pt-5 pt-lg-0 pb-4 pb-lg-0 justify-content-center">
          <Col sm={11} xs={12}>
            <Row>
              <Col
                lg={{span: 8, order: 1}}
                xs={{span: 12, order: 1}}
                className="text-center">
                <Row>
                  <Col xs={1} className="pb-3">
                    <ArrowBackIcon
                      fontSize="large"
                      className="backButton"
                      style={{cursor: 'pointer', color: Colors.primary}}
                      onClick={() =>
                        this.setState({showManageAdmin: false, username: ''})
                      }
                    />
                  </Col>
                  <Col xs={11} className="pb-3">
                    <h3
                      style={{fontWeight: '300', color: Colors.primary}}
                      className="adminHeader">
                      Manage Admin Accounts
                    </h3>
                  </Col>
                </Row>

                <Modal
                  show={this.state.showAddAdmin}
                  onHide={() => this.setState({showAddAdmin: false})}
                  centered>
                  <Modal.Body>
                    <Row className="px-3">
                      <Col xs={12} className="text-right">
                        <CloseRoundedIcon
                          style={{cursor: 'pointer', color: 'gray'}}
                          onClick={() => this.setState({showAddAdmin: false})}
                        />
                      </Col>
                      <Col xs={12} className="displayLink">
                        <div className="form__group field">
                          <input
                            type="input"
                            onChange={(username) => {
                              this.setState({
                                username: username.target.value,
                              });
                            }}
                            className="form__field"
                            value={this.state.username}
                            placeholder="Username"
                            name="username"
                            style={{zIndex: '1'}}
                          />
                          <label
                            htmlFor="username"
                            className="form__label"
                            style={{zIndex: '0'}}>
                            Username
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="text-center mt-3 px-3">
                      <Col className="text-center pr-1">
                        <Button
                          disabled={this.state.username ? false : true}
                          onClick={this.handleCreateAdminUser.bind(this)}
                          className="shadow-none"
                          size="sm"
                          variant="primary">
                          Add User as Admin
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>

                <Row>
                  <Col xs={4}>
                    <Button
                      onClick={() => this.setState({showAddAdmin: true})}
                      size="md"
                      className="mt-2 mb-4 text-right">
                      New Admin
                    </Button>
                  </Col>
                  <Col xs="auto" />
                </Row>
                {this.renderAdminUsers()}
              </Col>
              {this.renderHelpfulLinks()}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  handleSearchUser() {
    this.api
      .getUserByUsername(this.state.username)
      .then((response) => {
        if (response.ok) {
          this.setState({userToDelete: response.data, username: ''});
        }
      })
      .catch((error) => console.warn(error));
  }

  renderDisplayUser() {
    const user = this.state.userToDelete;
    if (user === '') {
      return null;
    }

    const currUser = this.props.user;
    return (
      <div>
        <Row
          key={user.user_id}
          className="shadow mx-0 py-2 mb-3"
          style={{fontWeight: '300', borderRadius: '5px'}}>
          <Col xs="auto" />
          <Col xs={9} className="text-left px-0 my-auto">
            <p className="my-auto">
              {user.full_name + ' (@' + user.username + ')'}
            </p>
          </Col>
          <Col xs={1} className="text-right px-0 my-auto">
            {currUser.id !== user.user_id ? (
              <DeleteIcon
                className="my-auto"
                onClick={() =>
                  this.setState({showDeleteUserModal: true, userToDelete: user})
                }
                style={{cursor: 'pointer'}}
              />
            ) : null}
          </Col>
          <Col xs="auto" />
        </Row>
        <Modal
          show={this.state.showDeleteUserModal}
          onHide={() => this.setState({showDeleteUserModal: false})}
          centered>
          <Modal.Body>
            <p className="text-center pt-3">
              Are you sure you want to permanently delete @{user.username}?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.handleDeleteUser(
                  this.state.userToDelete.user_id,
                  'regular',
                )
              }>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.setState({showDeleteUserModal: false})}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  renderDeleteUser() {
    return (
      <div>
        <Row className="mt-5 mx-0 pt-5 pt-lg-0 pb-4 pb-lg-0 justify-content-center">
          <Col sm={11} xs={12}>
            <Row>
              <Col
                lg={{span: 8, order: 1}}
                xs={{span: 12, order: 1}}
                className="text-center">
                <Row>
                  <Col xs={1} className="pb-3">
                    <ArrowBackIcon
                      fontSize="large"
                      className="backButton"
                      style={{cursor: 'pointer', color: Colors.primary}}
                      onClick={() =>
                        this.setState({showDeleteUser: false, userToDelete: ''})
                      }
                    />
                  </Col>
                  <Col xs={11} className="pb-3">
                    <h3
                      style={{fontWeight: '300', color: Colors.primary}}
                      className="adminHeader">
                      Delete User
                    </h3>
                  </Col>
                </Row>
                <Row className="px-3 mb-4">
                  <Col xs={9} className="displayLink">
                    <div className="form__group field">
                      <input
                        type="input"
                        onChange={(username) => {
                          this.setState({
                            username: username.target.value,
                          });
                        }}
                        className="form__field"
                        value={this.state.username}
                        placeholder="Username"
                        name="username"
                        style={{zIndex: '1'}}
                      />
                      <label
                        htmlFor="username"
                        className="form__label"
                        style={{zIndex: '0'}}>
                        Username
                      </label>
                    </div>
                  </Col>
                  <Col xs={3} className="my-auto">
                    <Button
                      disabled={this.state.username ? false : true}
                      onClick={this.handleSearchUser.bind(this)}
                      className="shadow-none"
                      size="md"
                      variant="primary">
                      Search
                    </Button>
                  </Col>
                </Row>
                {this.renderDisplayUser()}
              </Col>
              {this.renderHelpfulLinks()}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  render = () => {
    // must be an admin to see Admin panel's info
    if (this.props.user.role !== 'admin') {
      return (
        <div className="justify-content-center text-center mt-5">
          <h2>404</h2>
          <h3>oops! page not found.</h3>
        </div>
      );
    }

    if (this.state.showTestUsers) {
      return this.renderManageTestUsers();
    } else if (this.state.showManageAdmin) {
      return this.renderManageAdmin();
    } else if (this.state.showDeleteUser) {
      return this.renderDeleteUser();
    } else {
      return this.renderDashboard();
    }
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  testUsers: state.admin.testUsers,
  adminUsers: state.admin.adminUsers,
});
export default connect(mapStateToProps)(Admin);
