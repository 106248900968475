// @flow

import React, {Component} from 'react';
import {Provider} from 'react-redux';
import App from './App';
import createStore from '../Redux';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import Login from './Login';
import Landing from './Landing';
import SocialFeed from './SocialFeed';
import ExploreFeed from './ExploreFeed';
import Profile from './Profile';
import Playlist from './Playlist';
import Follow from './Follow';
import Unread from './Unread';
import Admin from './Admin';
import Settings from './Settings';
import NativeReader from './NativeReader';
import NotificationActions from '../Redux/NotificationRedux';

// create redux store
const store = createStore();

type Props = {};

class RootContainer extends Component<Props> {
  componentDidMount() {
    if (navigator.serviceWorker) {
      // $FlowFixMe
      navigator.serviceWorker.addEventListener('message', this.receiveMessage);
    }
  }

  componentWillUnmount() {
    if (navigator.serviceWorker) {
      // $FlowFixMe
      navigator.serviceWorker.removeEventListener(
        'message',
        this.receiveMessage,
      );
    }
  }

  receiveMessage = (event: any) => {
    if (event.data.command === 'notification') {
      const notification = event.data.notification;
      store.dispatch(NotificationActions.addNotification(notification));
      store.dispatch(
        NotificationActions.showToast(notification.title, notification.text),
      );
    }
  };

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Container fluid style={{paddingLeft: 0, paddingRight: 0}}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/landing" component={Landing} />
              <Route
                path="/privacy"
                render={() => {
                  window.location.href = 'privacy_policy.html';
                }}
              />
              <Route path="/" component={App} />
            </Switch>
          </Container>
        </Router>
      </Provider>
    );
  }
}

export default RootContainer;

export const routeConfig = [
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/',
        component: SocialFeed,
        exact: true,
      },
      {
        path: '/explore',
        component: ExploreFeed,
      },
      {
        path: '/profile/:id',
        component: Profile,
      },
      {
        path: '/follow',
        component: Follow,
      },
      {
        path: '/collection/:id',
        component: Playlist,
      },
      {
        path: '/unread',
        component: Unread,
      },
      {
        path: '/post/:id',
        component: NativeReader,
      },
      {
        path: '/admin',
        component: Admin,
      },
      {
        path: 'settings',
        component: Settings,
      },
    ],
  },
  {
    path: '/login',
    component: Login,
  },
];
