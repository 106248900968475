import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setSocialPosts: ['posts'],
  appendSocialPosts: ['posts'],
  setExplorePosts: ['posts'],
  deleteSinglePost: ['pid'],
  appendExplorePosts: ['posts'],
  setTags: ['tags'],
  addComment: ['postID', 'comment'],
  deleteComment: ['postID', 'comment_id'],
  updateRating: ['postID', 'rate'],
  setActivityPostData: ['activityID', 'post'],
  removeRating: ['postID'],
  reset: [],
});

export const FeedTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  socialPosts: [],
  explorePosts: [],
  tags: [],
});

/* ------------- Reducers ------------- */

export const setSocialPosts = (state, {posts}) =>
  state.merge({
    socialPosts: posts,
  });

export const appendSocialPosts = (state, {posts}) =>
  state.merge({
    socialPosts: state.socialPosts.concat(posts),
  });

export const setExplorePosts = (state, {posts}) =>
  state.merge({
    explorePosts: posts,
  });

export const appendExplorePosts = (state, {posts}) =>
  state.merge({
    explorePosts: state.explorePosts.concat(posts),
  });

export const setActivityPostData = (state, {activityID, post}) =>
  state.merge({
    socialPosts: state.socialPosts.map((item) => {
      if (item.feed_type === 'activity' && item.activity_id === activityID) {
        return {
          ...item,
          post,
        };
      }
      return item;
    }),
  });

export const addComment = (state, {postID, comment}) =>
  state.merge({
    socialPosts: state.socialPosts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          comments: post.comments.concat([comment]),
        };
      }
      return post;
    }),
    explorePosts: state.explorePosts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          comments: post.comments.concat([comment]),
        };
      }
      return post;
    }),
  });

export const updateRating = (state, {postID, rate}) =>
  state.merge({
    socialPosts: state.socialPosts.map((item) => {
      if (item.feed_type === 'post' && item.post_id === postID) {
        return {
          ...item,
          rating: rate,
          rating_count: item.rating_count + 1,
        };
      } else if (
        item.feed_type === 'activity' &&
        item.post &&
        item.post.post_id === postID
      ) {
        return {
          ...item,
          post: {
            ...item.post,
            rating: rate,
            rating_count: item.post.rating_count + 1,
          },
        };
      }
      return item;
    }),
    explorePosts: state.explorePosts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          rating: rate,
          rating_count: post.rating_count + 1,
        };
      }
      return post;
    }),
  });

export const removeRating = (state, {postID}) =>
  state.merge({
    socialPosts: state.socialPosts.map((item) => {
      if (item.feed_type === 'post' && item.post_id === postID) {
        return {
          ...item,
          rating: null,
          rating_count: item.rating_count - 1,
        };
      } else if (
        item.feed_type === 'activity' &&
        item.post &&
        item.post.post_id === postID
      ) {
        return {
          ...item,
          post: {
            ...item.post,
            rating: null,
            rating_count: item.post.rating_count - 1,
          },
        };
      }
      return item;
    }),
    explorePosts: state.explorePosts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          rating: null,
          rating_count: post.rating_count - 1,
        };
      }
      return post;
    }),
  });

export const setTags = (state, {tags}) => state.merge({tags});

export const deleteComment = (state, {postID, comment_id}) =>
  state.merge({
    socialPosts: state.socialPosts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          comments: post.comments.filter(
            (comment) => comment.comment_id !== comment_id,
          ),
        };
      }
      return post;
    }),
    explorePosts: state.explorePosts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          comments: post.comments.filter(
            (comment) => comment.comment_id !== comment_id,
          ),
        };
      }
      return post;
    }),
  });

export const deleteSinglePost = (state, {pid}) => {
  const newSocialPosts = state.socialPosts.filter(
    (post) => post.post_id !== pid,
  );
  const newExplorePosts = state.explorePosts.filter(
    (post) => post.post_id !== pid,
  );
  return state.merge({
    explorePosts: newExplorePosts,
    socialPosts: newSocialPosts,
  });
};

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SOCIAL_POSTS]: setSocialPosts,
  [Types.SET_EXPLORE_POSTS]: setExplorePosts,
  [Types.SET_TAGS]: setTags,
  [Types.DELETE_SINGLE_POST]: deleteSinglePost,
  [Types.APPEND_SOCIAL_POSTS]: appendSocialPosts,
  [Types.APPEND_EXPLORE_POSTS]: appendExplorePosts,
  [Types.ADD_COMMENT]: addComment,
  [Types.DELETE_COMMENT]: deleteComment,
  [Types.UPDATE_RATING]: updateRating,
  [Types.SET_ACTIVITY_POST_DATA]: setActivityPostData,
  [Types.REMOVE_RATING]: removeRating,
  [Types.RESET]: reset,
});
