// @flow

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import html from '../static/privacy_policy.js';
import ClearIcon from '@material-ui/icons/Clear';

const Privacy = (props) => (
  <>
    <Dialog
      open={props.show}
      onClose={props.close}
      maxWidth="lg"
      fullWidth={true}
      align="center">
      <DialogTitle>
        <ClearIcon
          style={{cursor: 'pointer', fontSize: 30}}
          onClick={props.close}
        />
      </DialogTitle>
      <DialogContent>
        <div>{html}</div>

        <Button
          variant="contained"
          onClick={props.close}
          style={{
            marginLeft: 10,
            marginTop: 10,
          }}>
          close
        </Button>
      </DialogContent>
    </Dialog>
  </>
);

export default Privacy;
