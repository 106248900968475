// @flow

import React, {Component} from 'react';
import PostComponent from '../Components/Post';
import {connect} from 'react-redux';
import ConfigActions from '../Redux/ConfigRedux';
import FeedActions from '../Redux/FeedRedux';
import _ from 'lodash';
import API from '../Services/API';
import type {Post, Activity} from '../Config/Types';
import CreateLink from '../Components/CreateLink';

type Props = {
  socialPosts: (Post & Activity)[],
  name: string,
  playlists: {}[],
  scrollPosition: number,
  unreadPosts: Post[],
  dispatch: ({}) => void,
  user_id: number,
  username: string,
};
type State = {
  loadingPosts: boolean,
};

class SocialFeed extends Component<Props, State> {
  api: any;
  fetchThreshold: number;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.fetchThreshold = -Infinity;
    this.state = {
      loadingPosts: false,
      modal: false,
    };
  }

  scrollRef: any = React.createRef();

  componentDidMount() {
    window.scrollTo(0, this.props.scrollPosition);
    window.addEventListener('scroll', this.onScroll);
    // Segment pageview call
    window.analytics.page('Home');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate() {
    // Segment track user
    window.analytics.identify(this.props.user_id, {
      name: this.props.name,
      username: this.props.username,
    });
    if (this.scrollRef.current && this.props.socialPosts.length > 0) {
      this.fetchThreshold =
        (this.scrollRef.current.scrollHeight / this.props.socialPosts.length) *
        10;
    }
  }

  onScroll = _.throttle(
    (info: any) => {
      this.props.dispatch(ConfigActions.setSocialScroll(window.pageYOffset));
      if (
        !this.state.loadingPosts &&
        this.scrollRef.current.scrollHeight - window.scrollY <=
          this.fetchThreshold
      ) {
        this.loadMorePosts();
      }
    },
    500,
    {
      trailing: true,
      leading: false,
    },
  );

  loadMorePosts = () => {
    this.setState({loadingPosts: true});
    const cursorPost =
      this.props.socialPosts[this.props.socialPosts.length - 1];
    this.api.getSocialPosts(20, cursorPost.post_time).then((response) => {
      if (response.ok) {
        this.props.dispatch(FeedActions.appendSocialPosts(response.data));
      }
      this.setState({loadingPosts: false});
    });
  };

  renderPosts() {
    return this.props.socialPosts.map<any>((post) => {
      let isUnread = false;
      if (this.props.unreadPosts) {
        this.props.unreadPosts.forEach((unreadPost) => {
          if (
            unreadPost.post_id === post.post_id &&
            unreadPost.clear_date == null
          ) {
            isUnread = true;
          }
        });
      }
      return (
        <PostComponent
          comments={post.comments}
          shoutouts={post.shoutouts}
          tags={post.tags}
          img={post.img}
          title={post.title}
          description={post.description}
          postUser={post.user}
          link={post.link}
          post_id={post.post_id}
          key={post.post_id}
          rating={post.rating}
          rating_average={post.rating_average}
          rating_count={post.rating_count}
          explore_page={false}
          playlists={this.props.playlists}
          public={post.public}
          markedUnread={isUnread}
          embed_link={post.embed_link}
          estimatedTime={post.estimated_time}
          post_time={post.post_time}
          domain={post.domain}
          native={post.native}
        />
      );
    });
  }

  render() {
    if (this.props.socialPosts && this.props.socialPosts.length > 0) {
      return (
        <div ref={this.scrollRef} className="pt-4">
          <CreateLink modal={this.state.modal} />
          {this.renderPosts()}
        </div>
      );
    } else {
      return (
        <div className="pt-4 text-center">
          <CreateLink modal={this.state.modal} />
          <h3 style={{fontWeight: '300'}}>Follow people for more content :)</h3>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user_id: state.user.id,
  username: state.user.username,
  name: state.user.name,
  socialPosts: state.feed.socialPosts,
  playlists: state.user.playlists,
  scrollPosition: state.config.socialScroll,
  unreadPosts: state.unread.posts,
});
export default connect(mapStateToProps)(SocialFeed);
