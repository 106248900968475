// @flow

import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import moment from 'moment';
import './Styles/Comment.css';
import type {User} from '../Config/Types';
import DeleteComment from './DeleteComment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Dropdown from 'react-bootstrap/Dropdown';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Image from 'react-bootstrap/Image';
import parse from 'html-react-parser';
import defaultWander from '../static/DefaultWander.png';

type Props = {
  id: number,
  time: Date,
  user: User,
  text: string,
  user_data: {
    name: string,
    id: number,
  },
  performDelete: (number) => void,
};
type State = {
  showDeleteConfirm: boolean,
};

export default class Comment extends Component<Props, State> {
  timeText: string;

  constructor(props: Props) {
    super(props);
    this.timeText = moment.utc(this.props.time).local().fromNow();
    this.state = {
      showDeleteConfirm: false,
    };
  }

  confirmDelete = () => this.setState({showDeleteConfirm: true});

  cancelDelete = () => this.setState({showDeleteConfirm: false});

  performDeleteWrapper() {
    return this.props.performDelete(this.props.id);
  }

  completeDelete = () => {
    this.props.performDelete(this.props.id);
    this.cancelDelete();
  };

  renderDeleteOption() {
    return this.props.user.user_id === this.props.user_data.id ? (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-primary shadow-none"
            className="toggler">
            <MoreHorizIcon fontSize="small" className="delete-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight>
            <Dropdown.Item onClick={this.confirmDelete}>
              <Row>
                <Col xs={12} className="comment-option px-0 py-0">
                  <DeleteOutlineIcon className="mb-1" />{' '}
                  <p className="my-auto">&nbsp;Delete</p>
                </Col>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <DeleteComment
          show={this.state.showDeleteConfirm}
          onHide={this.cancelDelete}
          performDelete={this.completeDelete}
        />
      </div>
    ) : null;
  }

  renderDeleteButtonTemp() {
    return this.props.user.user_id === this.props.user_data.id ? (
      <div className="commentDeleteButton">
        <button onClick={this.performDeleteWrapper} type="button">
          Delete
        </button>
      </div>
    ) : null;
  }

  render() {
    const tagRegex = /@\[(.*)]\((\d*)\)/g;
    const tags = this.props.text.matchAll(tagRegex);
    const comment = [];
    let unique = 0;
    for (const tag of tags) {
      if (tag.length === 3) {
        const tagText = tag[0];
        const userName = tag[1];
        const userID = tag[2];
        const split = this.props.text.split(tagText);
        comment.push(split[0]);
        comment.push(<span key={unique}>&nbsp;</span>);
        unique += 1;
        comment.push(
          <a key={unique} href={`/profile/${userID}`}>
            {userName}
          </a>,
        );
        unique += 1;
        comment.push(<span key={unique}>&nbsp;</span>);
        unique += 1;
      }
    }
    const fullSplit = this.props.text.split(tagRegex);
    const endText = fullSplit[fullSplit.length - 1];
    comment.push(endText);

    let profileImg = defaultWander;
    if (this.props.user.profile_pic !== null) {
      profileImg = this.props.user.profile_pic;
    }

    return (
      <Container
        className="mx-0 my-0"
        style={{maxWidth: '100%', position: 'relative', zIndex: 'auto'}}>
        <Row className="mx-0">
          <Col xs="auto" className="d-none d-sm-block">
            <Image
              src={profileImg}
              roundedCircle
              className="profileIMG"
              width="45"
              height="45"
            />
          </Col>
          <Col>
            <Row>
              <Col xs="auto">
                <div className="commentUserText mb-1">
                  <a href={`/profile/${this.props.user.user_id}`}>
                    {this.props.user.full_name}
                  </a>
                </div>
              </Col>
              <Col>
                <div className="commentTimeText text-truncate">
                  {this.timeText}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="commentText">
                  {parse(
                    comment[0].replace('<a href', '<a target="_blank" href'),
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">{this.renderDeleteOption()}</Col>
        </Row>
      </Container>
    );
  }
}
