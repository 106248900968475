// @flow

import React, {Component} from 'react';
import type {Node} from 'react';
import './Styles/SideBar.css';

type Props = {
  show: boolean,
  children: Node,
};

export default class SideBar extends Component<Props> {
  render() {
    return (
      <div className={this.props.show ? 'sidebar' : 'sidebar close'}>
        {this.props.children}
      </div>
    );
  }
}
