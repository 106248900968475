import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setCurrentTitle: ['title'],
  setCurrentPosts: ['posts'],
  updateRatingPlaylist: ['postID', 'rate'],
  addComment: ['postID', 'comment'],
  appendPlaylistPosts: ['posts'],
  deletePlaylistPost: ['pid'],
  deleteComment: ['postID', 'comment_id'],
  reset: [],
});

export const PlaylistTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  title: '',
  posts: [],
});

/* ------------- Reducers ------------- */

export const setCurrentTitle = (state, {title}) => state.merge({title});

export const setCurrentPosts = (state, {posts}) => state.merge({posts});

export const updateRatingPlaylist = (state, {postID, rate}) =>
  state.merge({
    posts: state.posts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          rating: rate,
        };
      }
      return post;
    }),
  });

export const addComment = (state, {postID, comment}) =>
  state.merge({
    posts: state.posts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          comments: post.comments.concat([comment]),
        };
      }
      return post;
    }),
  });

export const appendPlaylistPosts = (state, {posts}) =>
  state.merge({
    posts: state.posts.concat(posts),
  });

export const deletePlaylistPost = (state, {pid}) => {
  const newPosts = state.posts.filter((post) => post.post_id !== pid);
  return state.merge({
    posts: newPosts,
  });
};

export const deleteComment = (state, {postID, comment_id}) =>
  state.merge({
    posts: state.posts.map((post) => {
      if (post.post_id === postID) {
        return {
          ...post,
          comments: post.comments.filter(
            (comment) => comment.comment_id !== comment_id,
          ),
        };
      }
      return post;
    }),
  });

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CURRENT_TITLE]: setCurrentTitle,
  [Types.SET_CURRENT_POSTS]: setCurrentPosts,
  [Types.UPDATE_RATING_PLAYLIST]: updateRatingPlaylist,
  [Types.ADD_COMMENT]: addComment,
  [Types.APPEND_PLAYLIST_POSTS]: appendPlaylistPosts,
  [Types.DELETE_PLAYLIST_POST]: deletePlaylistPost,
  [Types.DELETE_COMMENT]: deleteComment,
  [Types.RESET]: reset,
});
