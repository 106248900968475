// @flow

import React, {Component} from 'react';
import {Card, Row, Col} from 'react-bootstrap';
import Comments from './Comments';
import type {Comment, Playlist} from '../Config/Types';
import FeedActions from '../Redux/FeedRedux';
import PlaylistActions from '../Redux/PlaylistRedux';
import UserActions from '../Redux/UserRedux';
import UnreadActions from '../Redux/UnreadRedux';
import PostActions from '../Redux/PostRedux';
import {connect} from 'react-redux';
import API from '../Services/API';
import './Styles/Post.css';
import {toast} from 'react-toastify';
import Colors from '../Config/Colors';
import UpvoteIcon from '@material-ui/icons/EmojiObjectsOutlined';
import UpvoteFilledIcon from '@material-ui/icons/EmojiObjects';
import {DropdownMultiple} from 'reactjs-dropdown-component';
import _ from 'lodash';

type Props = {
  user: {
    name: string,
    id: number,
  },
  post_id: number,
  rating_average: number,
  title: string,
  comments: Comment[],
  playlists: Playlist[],
  rating?: number,
  rating_count: number,
  dispatch: ({}) => void,
  history: any,
};
type State = {
  currentlyIn: Playlist[],
  isPlaylistSelectionReady: boolean,
};

class Post extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    const postPlaylists = this.props.playlists.filter((playlist) => {
      if (!playlist.posts) {
        return false;
      }
      for (const post of playlist.posts) {
        if (post.post_id === this.props.post_id) return true;
      }
      return false;
    });
    this.state = {
      currentlyIn: postPlaylists,
      isPlaylistSelectionReady: false,
    };
  }

  componentDidMount() {
    window.analytics.track('Action Panel opened');
    this.setState({
      isPlaylistSelectionReady: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      isPlaylistSelectionReady: false,
    });
  }

  handleSelect = (selectedItem) => {
    // Segment Event
    window.analytics.track('Post Added To Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.api
      .addToPlaylist(selectedItem.playlist_id, this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.api
            .getMainUserPlaylists()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(UserActions.setPlaylists(response.data));
              }
            })
            .catch((error) => console.warn(error));
        }
      })
      .catch((error) => console.warn(error));
  };

  handleRemove = (selectedItem) => {
    // Segment Event
    window.analytics.track('Post Removed From Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.api
      .removeFromPlaylist(selectedItem.playlist_id, this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.api
            .getMainUserPlaylists()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(UserActions.setPlaylists(response.data));
              }
            })
            .catch((error) => console.warn(error));
        }
      })
      .catch((error) => console.warn(error));
  };

  handleDelete = (e: any) => {
    e.preventDefault();
    // Segment Event
    window.analytics.track('Post Deleted', this.props);
    this.api
      .deleteUserPost(this.props.post_id)
      .then((response) => {
        if (response.ok) {
          // console.log('dispatching')
          this.props.dispatch(FeedActions.deleteSinglePost(this.props.post_id));
          this.props.dispatch(
            PlaylistActions.deletePlaylistPost(this.props.post_id),
          );
          this.props.dispatch(UnreadActions.deletePost(this.props.post_id));
          this.cancelDelete();
          toast('The post has been deleted.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        console.warn(error);
        this.cancelDelete();
        toast('The post could not be deleted.', {containerId: 'N'});
      });
  };

  onCommentSubmit = (comment: string) => {
    // Segment Event
    window.analytics.track('Comment Created', {
      post: this.props,
      comment: comment,
    });
    this.api
      .createComment(this.props.post_id, comment)
      .then((response) => {
        if (response.ok) {
          const newComment = {
            user: {
              full_name: this.props.user.name,
              user_id: this.props.user.id,
            },
            comment_text: comment,
            comment_time: response.data[0].comment_time,
            comment_id: response.data[0].comment_id,
          };
          this.props.dispatch(PostActions.newComment(newComment));
          this.props.dispatch(
            FeedActions.addComment(this.props.post_id, newComment),
          );
          toast('The comment has been created.', {containerId: 'N'});
        }
      })
      .catch((error) => console.warn(error));
  };

  onCommentDelete = (comment_id: number) => {
    // Segment Event
    window.analytics.track('Comment Deleted', {
      post: this.props,
      comment_id: comment_id,
    });
    this.api
      .deleteComment(comment_id)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(PostActions.removeComment(comment_id));
          this.props.dispatch(
            FeedActions.deleteComment(this.props.post_id, comment_id),
          );
          this.props.dispatch(
            PlaylistActions.deleteComment(this.props.post_id, comment_id),
          );
          toast('The comment has been deleted.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  renderUpvoteIcon = () => {
    const ratedPosts = this.props.ratedPosts;
    // console.log(ratedPosts)
    if (ratedPosts.includes(this.props.post.post_id)) {
      // console.log('returns filled')
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="pr-4 justify-content-end text-truncate upvoteIcon">
          <UpvoteFilledIcon onClick={() => this.handleRatingChange(null)} />
          &nbsp;
          <p style={{color: Colors.grey}} className="my-auto">
            {this.props.post.rating_count}
          </p>
        </div>
      );
    } else {
      // console.log('returns unfilled')
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="pr-4 justify-content-end text-truncate upvoteIcon">
          <UpvoteIcon onClick={() => this.handleRatingChange(5)} /> &nbsp;
          <p style={{color: Colors.grey}} className="my-auto">
            {this.props.post.rating_count}
          </p>
        </div>
      );
    }
  };

  handleRatingChange = (rating) => {
    // console.log(rating)
    window.analytics.track('Rating Set', {rating: rating, ...this.props});
    let rating_count = this.props.rating_count;
    // const ratedPosts = this.props.ratedPosts;
    if (rating != null) {
      // console.log('changing rating to not null')
      rating_count += 1;
      this.api
        .setRatingForPost(this.props.post_id, rating)
        .catch((error) => console.warn(error));
      this.props.dispatch(FeedActions.updateRating(this.props.post_id, rating));
      this.props.dispatch(
        PlaylistActions.updateRatingPlaylist(this.props.post_id, rating),
      );
      // console.log(this.props.post.post_id)
      this.props.dispatch(UserActions.addRating(this.props.post.post_id));
    } else {
      // console.log('changing rating to null')
      rating_count -= 1;
      this.api
        .removeRatingForPost(this.props.post_id)
        .catch((error) => console.warn(error));
      this.props.dispatch(FeedActions.removeRating(this.props.post_id));
      this.props.dispatch(UserActions.removeRating(this.props.post_id));
    }
    this.props.dispatch(
      PostActions.setPostRating(this.props.post, rating, rating_count),
    );
    // console.log(`Changed rating: ${this.props.ratedPosts}`)
  };

  renderPlaylists = () => {
    const playlists = [];
    for (let i = 0; i < this.props.playlists.length; i++) {
      const playlist = this.props.playlists[i];
      playlists.push({
        label: playlist.title,
        value: playlist.playlist_id,
      });
    }

    return playlists;
  };

  onSelect = (items, name) => {
    if (this.state.isPlaylistSelectionReady) {
      const currentlyInSnapshot = this.state.currentlyIn
        .slice()
        .map((p) => p.playlist_id);
      const dropdownSnapshot = items.slice().map((i) => i.value);
      let updatedCurrentlyIn = [...this.state.currentlyIn];

      if (currentlyInSnapshot.length > dropdownSnapshot.length) {
        const _diff = _.difference(currentlyInSnapshot, dropdownSnapshot);
        const playlistToRemove = this.props.playlists.find(
          (p) => p.playlist_id === _diff[0],
        );
        this.handleRemove(playlistToRemove);
        updatedCurrentlyIn = updatedCurrentlyIn.filter(
          (p) => p.playlist_id !== _diff[0],
        );
      } else if (currentlyInSnapshot.length < dropdownSnapshot.length) {
        const _diff = _.difference(dropdownSnapshot, currentlyInSnapshot);
        const playlistToAdd = this.props.playlists.find(
          (p) => p.playlist_id === _diff[0],
        );
        this.handleSelect(playlistToAdd);
        updatedCurrentlyIn.push(playlistToAdd);
      }
      this.setState({
        currentlyIn: updatedCurrentlyIn,
      });
    }
  };

  renderPlaylistSelectionBtn() {
    return (
      <DropdownMultiple
        ref={this.playlistSelectionRef}
        name="playlists"
        title="Add to collections"
        titleSingular="collection"
        titlePlural="collections"
        closeOnSelection={true}
        list={this.renderPlaylists()}
        onChange={this.onSelect}
        styles={{
          wrapper: {
            padding: '0px',
            fontSize: '1rem',
          },
          header: {
            borderColor: Colors.primary,
            borderRadius: '5px',
            padding: '0px',
          },
          listItem: {
            fontSize: '0.75rem',
          },
        }}
      />
    );
  }

  render() {
    return (
      <Card className="shadow-sm w-100">
        <Card.Header>
          <Row className="my-auto align-items-center justify-content-between">
            <Col xs="auto">{this.renderUpvoteIcon()}</Col>
            <Col xs="auto">{this.renderPlaylistSelectionBtn()}</Col>
          </Row>
        </Card.Header>
        <Row>
          <Col xs={12}>
            <Comments
              visible={true}
              comments={this.props.comments}
              handleCommentSubmit={this.onCommentSubmit}
              handleCommentDelete={this.onCommentDelete}
              user={this.props.user}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: {
    name: state.user.name,
    id: state.user.id,
  },
  playlists: state.user.playlists,
  post: state.post,
  ratedPosts: state.user.ratedPosts,
});
export default connect(mapStateToProps)(Post);
