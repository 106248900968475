// @flow

import React, {Component} from 'react';
import PostComponent from '../Components/Post';
import {connect} from 'react-redux';
import ConfigActions from '../Redux/ConfigRedux';
import _ from 'lodash';
import FeedActions from '../Redux/FeedRedux';
import API from '../Services/API';
import type {Post, Playlist} from '../Config/Types';
import TagFilter from '../Components/TagFilter';
import './Styles/ExploreFeed.css';

type Props = {
  explorePosts: Post[],
  playlists: Playlist[],
  scrollPosition: number,
  unreadPosts: Post[],
  dispatch: ({}) => void,
};
type State = {
  loadingPosts: boolean,
  refreshingTags: boolean,
  selectedTags: number[],
};

class ExploreFeed extends Component<Props, State> {
  api: any;
  fetchThreshold: number;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.fetchThreshold = -Infinity;
    this.state = {
      loadingPosts: false,
      refreshingTags: false,
      selectedTags: [],
    };
  }

  scrollRef: any = React.createRef();

  componentDidMount() {
    window.scrollTo(0, this.props.scrollPosition);
    window.addEventListener('scroll', this.onScroll);
    // Segment pageview call
    window.analytics.page('Explore');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate() {
    // Segment track user
    window.analytics.identify(this.props.user_id, {
      name: this.props.name,
      username: this.props.username,
    });
    if (this.scrollRef.current && this.props.explorePosts.length > 0) {
      this.fetchThreshold =
        (this.scrollRef.current.scrollHeight / this.props.explorePosts.length) *
        10;
    }
  }

  onScroll = _.throttle(
    (info: any) => {
      this.props.dispatch(ConfigActions.setSocialScroll(window.pageYOffset));
      if (
        !this.state.loadingPosts &&
        this.scrollRef.current &&
        this.scrollRef.current.scrollHeight - window.scrollY <=
          this.fetchThreshold
      ) {
        this.loadMorePosts();
      }
    },
    500,
    {
      trailing: true,
      leading: false,
    },
  );

  loadMorePosts = () => {
    this.setState({loadingPosts: true});
    const existingPostIds = [];
    for (let i = 0; i < this.props.explorePosts.length; i++) {
      existingPostIds.push(this.props.explorePosts[i].post_id);
    }
    this.api
      .getExplorePosts(20, existingPostIds, this.state.selectedTags)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.appendExplorePosts(response.data));
        }
        this.setState({loadingPosts: false});
      });
  };

  renderPosts() {
    return this.props.explorePosts.map<any>((post: any) => {
      let isUnread = false;
      this.props.unreadPosts.forEach((unreadPost) => {
        if (
          unreadPost.post_id === post.post_id &&
          unreadPost.clear_date == null
        ) {
          isUnread = true;
        }
      });
      return (
        <PostComponent
          comments={post.comments}
          rating={post.rating}
          tags={post.tags}
          img={post.img}
          title={post.title}
          description={post.description}
          postUser={post.user}
          link={post.link}
          post_id={post.post_id}
          key={post.post_id}
          rating_average={post.rating_average}
          explore_page={true}
          public={post.public}
          markedUnread={isUnread}
          embed_link={post.embed_link}
          estimatedTime={post.estimated_time}
          native={post.native}
        />
      );
    });
  }

  render() {
    if (this.props.explorePosts) {
      return (
        <div ref={this.scrollRef}>
          <TagFilter />
          {this.renderPosts()}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  user_id: state.user.id,
  username: state.user.username,
  name: state.user.name,
  explorePosts: state.feed.explorePosts,
  playlists: state.user.playlists,
  scrollPosition: state.config.exploreScroll,
  unreadPosts: state.unread.posts,
});
export default connect(mapStateToProps)(ExploreFeed);
