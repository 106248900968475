import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  addNotification: ['notification'],
  addNotifications: ['notifications'],
  setNotifications: ['notifications'],
  removeNotification: ['id'],
  markAsRead: ['id'],
  showToast: ['title', 'message'],
  hideToast: [],
  reset: [],
});

export const NotificationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  notifications: [],
  showToast: false,
  toastTitle: '',
  toastMessage: '',
});

/* ------------- Reducers ------------- */

export const addNotification = (state, {notification}) =>
  state.merge({
    notifications: [notification].concat(state.notifications),
  });

export const setNotifications = (state, {notifications}) =>
  state.merge({
    notifications,
  });

export const showToast = (state, {title, message}) =>
  state.merge({
    showToast: true,
    toastTitle: title,
    toastMessage: message,
  });

export const hideToast = (state) =>
  state.merge({
    showToast: false,
    toastTitle: '',
    toastMessage: '',
  });

export const markAsRead = (state, {id}) =>
  state.merge({
    notifications: state.notifications.map((n) => {
      if (n.notification_id === id) {
        return {
          ...n,
          read: true,
        };
      }
      return n;
    }),
  });

export const removeNotification = (state, {id}) =>
  state.merge({
    notifications: state.notifications.filter((n) => n.notification_id !== id),
  });

export const addNotifications = (state, {notifications}) => {
  const newNotifications = notifications.filter((n) => {
    const ids = state.notifications.map((n) => n.notification_id);
    return !ids.includes(n.notification_id);
  });
  newNotifications.reverse();
  return state.merge({
    notifications: newNotifications.concat(state.notifications),
  });
};

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hook Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_NOTIFICATION]: addNotification,
  [Types.ADD_NOTIFICATIONS]: addNotifications,
  [Types.SET_NOTIFICATIONS]: setNotifications,
  [Types.REMOVE_NOTIFICATION]: removeNotification,
  [Types.MARK_AS_READ]: markAsRead,
  [Types.SHOW_TOAST]: showToast,
  [Types.HIDE_TOAST]: hideToast,
  [Types.RESET]: reset,
});
