// @flow

import React, {Component} from 'react';
import {Image, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import FollowButton from './FollowButton';
import defaultWander from '../static/DefaultWander.png';

class ProfileUser extends Component {
  render() {
    const user = this.props.user;
    let imageLink = defaultWander;
    if (user.img) {
      imageLink = user.img;
    }
    let bio = user.bio;
    if (bio && bio.length > 50) {
      bio = bio.slice(0, 50) + '...';
    }
    return (
      <div className="card m-2">
        <Row>
          <Col className="my-auto" style={{textAlign: 'center'}}>
            <Link className="w-100" to={`/profile/${user.user_id}`}>
              <Image
                src={imageLink}
                style={{boxShadow: 'none', margin: '3px 0px'}}
                className="profileIMG"
                roundedCircle
                height="80"
                width="80"
              />
            </Link>
          </Col>

          <Col xs={7}>
            <Row style={{margin: '0px'}}>
              <Link className="w-100" to={`/profile/${user.user_id}`}>
                {user.full_name}
              </Link>
            </Row>

            <Row style={{margin: '0px'}}>
              <Link
                className="w-100 text-decoration-none text-dark"
                to={`/profile/${user.user_id}`}>
                {bio}
              </Link>
            </Row>

            <Row style={{margin: '0px'}} className="justify-content-end">
              <Link className="w-50" to={`/profile/${user.user_id}`} />
              <FollowButton
                userID={user.user_id}
                name={user.full_name}
                following={user.following}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProfileUser;
