import React from 'react';
import {Image, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {Card} from '@material-ui/core';
import './Styles/SearchHits.css';
import {connectHits} from 'react-instantsearch-dom';

const Hits = ({hits, resetSearch}) => (
  <Row xs={3}>
    {hits.map((hit, index) => (
      <Col
        width={190}
        style={{
          marginLeft: 0,
          marginRight: 0,
          paddingRight: 10,
          paddingLeft: 10,
        }}>
        {hit.firstname && (
          <Card
            style={{
              backgroundColor: '#FF8D4D',
              height: 55,
              width: 180,
              marginBottom: 5,
            }}>
            <Link
              to={`/profile/${hit.objectID}`}
              onClick={() => resetSearch()}
              style={{color: 'black'}}>
              <div style={{display: 'flex'}}>
                <Image
                  src={
                    hit.profile_pic ||
                    'https://i.ibb.co/b2sGQQ3/Pretty-Profile-Pic.jpg'
                  }
                  style={{boxShadow: 'none'}}
                  height="55"
                  width="55"
                />
                <div style={{marginLeft: 6}}>
                  <p style={{marginBottom: 0}}>
                    {hit.firstname} {hit.lastname}
                  </p>
                  {/* <p style={{fontSize: 12, marginTop: 2, marginRight: 5}}>{hit.bio}</p> */}
                </div>
              </div>
            </Link>
          </Card>
        )}
        {!hit.firstname && (
          <Card
            style={{
              backgroundColor: '#FF8D4D',
              height: 137,
              width: 180,
              marginBottom: 5,
            }}>
            {hit.native && (
              <Link
                to={`/post/${hit.post_id}/`}
                onClick={() => resetSearch()}
                style={{color: 'black'}}>
                <div>
                  <Image
                    src={
                      hit.img ||
                      'https://i.ibb.co/b2sGQQ3/Pretty-Profile-Pic.jpg'
                    }
                    style={{boxShadow: 'none', maxHeight: 90}}
                    width="180"
                  />
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: 11,
                      marginLeft: 2,
                      marginRight: 2,
                    }}>
                    {hit.title}
                  </p>
                </div>
              </Link>
            )}
            {!hit.native && (
              <a
                href={hit.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{color: 'black'}}>
                <div>
                  <Image
                    src={
                      hit.img ||
                      'https://i.ibb.co/b2sGQQ3/Pretty-Profile-Pic.jpg'
                    }
                    style={{boxShadow: 'none', maxHeight: 90}}
                    width="180"
                  />
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: 11,
                      marginLeft: 2,
                      marginRight: 2,
                    }}>
                    {hit.title}
                  </p>
                </div>
              </a>
            )}
          </Card>
        )}
      </Col>
    ))}
  </Row>
);

const SearchHits = connectHits(Hits);
export default SearchHits;
