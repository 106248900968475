// @flow

import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Multiselect} from 'multiselect-react-dropdown';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import Colors from '../Config/Colors';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux';
import API from '../Services/API';

type Props = {
  show: boolean,
  post_id: number,
  onHide: () => void,
  markRead: (number) => void,
  animate: (number, boolean) => void,
  finish: (number) => void,
  errorReset: (number) => void,
};

type State = {
  selectedTags: [],
  showTags: boolean,
  errorMessage: boolean,
};

class PrivatePost extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      selectedTags: [],
      showTags: false,
      errorMessage: false,
    };
  }

  onTagPress = (tags, tag) => {
    if (this.state.selectedTags.includes(tag.tag_id)) {
      window.analytics.track(
        'Tag Unselected',
        this.props.tags.filter((tag1) => tag1.tag_id === tag.tag_id),
      );
      this.setState({
        selectedTags: this.state.selectedTags.filter(
          (selectedTag) => selectedTag !== tag.tag_id,
        ),
      });
    } else {
      window.analytics.track(
        'Tag Selected',
        this.props.tags.filter((tag1) => tag1.tag_id === tag.tag_id),
      );
      this.setState({
        selectedTags: this.state.selectedTags.concat(tag.tag_id),
      });
    }
  };

  performClick = (makePublic) => {
    this.props.onHide();
    this.props.markRead(this.props.post_id, makePublic);
  };

  resetState = () => {
    this.setState({
      selectedTags: [],
      showTags: false,
      errorMessage: false,
    });
  };

  chooseTags = () => {
    this.props.onHide();
    this.setState({
      showTags: true,
    });
  };

  handleTags = () => {
    if (this.state.selectedTags.length > 0) {
      this.props.animate(this.props.post_id, true);
      setTimeout(() => {
        this.api
          .markReadPrivate(this.props.post_id, this.state.selectedTags)
          .then((response) => {
            if (response.ok) {
              this.props.finish(this.props.post_id);
            }
            this.resetState();
          })
          .catch((error) => {
            console.warn(error);
            this.resetState();
            this.props.errorReset(this.props.post_id);
          });
      }, 100);
    } else {
      this.setState({
        errorMessage: true,
      });
    }
  };

  render() {
    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.onHide} centered>
          <Modal.Body className="text-center">
            <p className="pt-3">Do you want to make the post public?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.chooseTags}>
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.performClick(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showTags} onHide={this.resetState} centered>
          <Modal.Body>
            <Row>
              <Col xs={12} className="text-center">
                <h5 style={{fontWeight: '400'}}>Select at least one tag.</h5>
                <Multiselect
                  options={this.props.tags}
                  onSelect={this.onTagPress}
                  onRemove={this.onTagPress}
                  displayValue="text"
                  placeholder="Search Tags"
                  avoidHighlightFirstOption
                  hidePlaceholder
                  style={{
                    optionContainer: {border: 'none'},
                    chips: {whiteSpace: 'normal', background: Colors.primary},
                    searchBox: {background: '#eeeeee', border: 'none'},
                  }}
                />
              </Col>
              <Col xs={12} className="text-right pt-3">
                <SendRoundedIcon
                  onClick={this.handleTags}
                  fontSize="large"
                  style={{cursor: 'pointer', color: Colors.primary}}
                />
              </Col>
            </Row>
            <Row
              className={
                this.state.errorMessage ? 'd-block mt-2 px-0' : 'd-none'
              }>
              <Col xs={12} className="text-center">
                <p className="mb-0" style={{fontWeight: '400', color: 'gray'}}>
                  Must select at least one tag.
                </p>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tags: state.feed.tags,
});
export default connect(mapStateToProps)(PrivatePost);
