// @flow

import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import './Styles/DeleteComment.css';

type Props = {
  show: boolean,
  onHide: () => void,
  performDelete: () => void,
};

class DeleteComment extends Component<Props> {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered>
        <Modal.Body>
          <p className="message">
            Are you sure you want to permanently delete this comment?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.performDelete}>
            Yes
          </Button>
          <Button variant="secondary" onClick={this.props.onHide}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteComment;
