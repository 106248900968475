
import ReactHtmlParser from "react-html-parser"

const privacy = `<html>

<head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type">
    <style type="text/css">
        .lst-kix_wkw0ppd4ukbl-0>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_wkw0ppd4ukbl-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_at55vjgmrjdg-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_at55vjgmrjdg-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_wkw0ppd4ukbl-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_at55vjgmrjdg-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_wkw0ppd4ukbl-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_at55vjgmrjdg-3>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_at55vjgmrjdg-4>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_wkw0ppd4ukbl-8 {
            list-style-type: none
        }

        .lst-kix_kqfq0cfofimy-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_wkw0ppd4ukbl-6 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-6 {
            list-style-type: none
        }

        ul.lst-kix_wkw0ppd4ukbl-7 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-5 {
            list-style-type: none
        }

        ul.lst-kix_wkw0ppd4ukbl-4 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-8 {
            list-style-type: none
        }

        ul.lst-kix_wkw0ppd4ukbl-5 {
            list-style-type: none
        }

        .lst-kix_kqfq0cfofimy-0>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_kqfq0cfofimy-7 {
            list-style-type: none
        }

        .lst-kix_at55vjgmrjdg-0>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_wkw0ppd4ukbl-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_at55vjgmrjdg-1>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_at55vjgmrjdg-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_wkw0ppd4ukbl-1>li:before {
            content: "\\0025cb   "
        }

        ul.lst-kix_at55vjgmrjdg-0 {
            list-style-type: none
        }

        .lst-kix_kqfq0cfofimy-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kqfq0cfofimy-8>li:before {
            content: "\\0025a0   "
        }

        ul.lst-kix_at55vjgmrjdg-7 {
            list-style-type: none
        }

        ul.lst-kix_at55vjgmrjdg-8 {
            list-style-type: none
        }

        ul.lst-kix_at55vjgmrjdg-5 {
            list-style-type: none
        }

        ul.lst-kix_at55vjgmrjdg-6 {
            list-style-type: none
        }

        .lst-kix_kqfq0cfofimy-3>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_at55vjgmrjdg-3 {
            list-style-type: none
        }

        ul.lst-kix_at55vjgmrjdg-4 {
            list-style-type: none
        }

        ul.lst-kix_at55vjgmrjdg-1 {
            list-style-type: none
        }

        ul.lst-kix_at55vjgmrjdg-2 {
            list-style-type: none
        }

        .lst-kix_kqfq0cfofimy-2>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_wkw0ppd4ukbl-6>li:before {
            content: "\\0025cf   "
        }

        .lst-kix_wkw0ppd4ukbl-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_wkw0ppd4ukbl-7>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_kqfq0cfofimy-4>li:before {
            content: "\\0025cb   "
        }

        .lst-kix_at55vjgmrjdg-8>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_kqfq0cfofimy-5>li:before {
            content: "\\0025a0   "
        }

        .lst-kix_kqfq0cfofimy-6>li:before {
            content: "\\0025cf   "
        }

        ul.lst-kix_wkw0ppd4ukbl-2 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-2 {
            list-style-type: none
        }

        ul.lst-kix_wkw0ppd4ukbl-3 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-1 {
            list-style-type: none
        }

        ul.lst-kix_wkw0ppd4ukbl-0 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-4 {
            list-style-type: none
        }

        ul.lst-kix_wkw0ppd4ukbl-1 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-3 {
            list-style-type: none
        }

        ul.lst-kix_kqfq0cfofimy-0 {
            list-style-type: none
        }

        ol {
            margin: 0;
            padding: 0
        }

        table td,
        table th {
            padding: 0
        }

        .c6 {
            margin-left: 36pt;
            padding-top: 12pt;
            padding-left: 0pt;
            padding-bottom: 12pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c2 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 14.5pt;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c8 {
            padding-top: 11pt;
            padding-bottom: 11pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c9 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.1;
            orphans: 2;
            widows: 2;
            text-align: left;
            height: 11pt
        }

        .c3 {
            color: #000000;
            font-weight: 400;
            text-decoration: none;
            vertical-align: baseline;
            font-size: 11.5pt;
            font-family: "Times New Roman";
            font-style: normal
        }

        .c20 {
            padding-top: 11pt;
            padding-bottom: 11pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c1 {
            padding-top: 11pt;
            padding-bottom: 11pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c0 {
            -webkit-text-decoration-skip: none;
            font-weight: 400;
            text-decoration: underline;
            text-decoration-skip-ink: none;
            font-size: 11.5pt;
            font-family: "Times New Roman"
        }

        .c14 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: center
        }

        .c7 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.15;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c19 {
            padding-top: 0pt;
            padding-bottom: 0pt;
            line-height: 1.5;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .c12 {
            color: #000000;
            text-decoration: none;
            vertical-align: baseline;
            font-style: italic
        }

        .c16 {
            font-weight: 400;
            text-decoration: none;
            font-size: 12pt;
            font-family: "Times New Roman"
        }

        .c15 {
            font-weight: 400;
            text-decoration: none;
            font-size: 11pt;
            font-family: "Times New Roman"
        }

        .c4 {
            color: #000000;
            vertical-align: baseline;
            font-style: normal
        }

        .c11 {
            font-size: 11.5pt;
            font-family: "Times New Roman";
            font-weight: 400
        }

        .c10 {
            background-color: #ffffff;
            max-width: 468pt;
            padding: 72pt 72pt 72pt 72pt
        }

        .c17 {
            padding: 0;
            margin: 0
        }

        .c5 {
            color: inherit;
            text-decoration: inherit
        }

        .c18 {
            height: 11pt
        }

        .c13 {
            font-style: italic
        }

        .title {
            padding-top: 0pt;
            color: #000000;
            font-size: 26pt;
            padding-bottom: 3pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        .subtitle {
            padding-top: 0pt;
            color: #666666;
            font-size: 15pt;
            padding-bottom: 16pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        li {
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        p {
            margin: 0;
            color: #000000;
            font-size: 11pt;
            font-family: "Arial"
        }

        h1 {
            padding-top: 20pt;
            color: #000000;
            font-size: 20pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h2 {
            padding-top: 18pt;
            color: #000000;
            font-size: 16pt;
            padding-bottom: 6pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h3 {
            padding-top: 16pt;
            color: #434343;
            font-size: 14pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h4 {
            padding-top: 14pt;
            color: #666666;
            font-size: 12pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h5 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            orphans: 2;
            widows: 2;
            text-align: left
        }

        h6 {
            padding-top: 12pt;
            color: #666666;
            font-size: 11pt;
            padding-bottom: 4pt;
            font-family: "Arial";
            line-height: 1.15;
            page-break-after: avoid;
            font-style: italic;
            orphans: 2;
            widows: 2;
            text-align: left
        }
    </style>
</head>

<body class="c10">
    <p class="c14"><span class="c15 c4">PRIVACY POLICY</span></p>
    <p class="c1"><span class="c3">Last updated June 21, 2020</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c3">Thank you for choosing to be part of our community at Wander (&ldquo;Company&rdquo;,
            &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;). We are committed to protecting your personal
            information and your right to privacy. If you have any questions or concerns about our policy, or our
            practices with regards to your personal information, please contact us at justin@thinkwander.com.</span></p>
    <p class="c1"><span class="c3">When you visit our website thinkwander.com, and use our services, you trust us with
            your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain
            to you in the clearest way possible what information we collect, how we use it and what rights you have in
            relation to it. We hope you take some time to read through it carefully, as it is important. If there are
            any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our
            services.</span></p>
    <p class="c1"><span class="c3">This privacy policy applies to all information collected through our website (such as
            thinkwander.com), and/or any related services, sales, marketing or events (we refer to them collectively in
            this privacy policy as the &quot;Services&quot;).</span></p>
    <p class="c1"><span class="c3">Please read this privacy policy carefully as it will help you make informed decisions
            about sharing your personal information with us.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">TABLE OF CONTENTS</span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23infocollect&amp;sa=D&amp;ust=1595992325532000&amp;usg=AOvVaw0E-Aqf0AvCJUFvPUfTbSHS">1.
                WHAT INFORMATION DO WE COLLECT?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23infouse&amp;sa=D&amp;ust=1595992325532000&amp;usg=AOvVaw3qXNXxIaji3-6-bFGiYOYj">2.
                HOW DO WE USE YOUR INFORMATION?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23infoshare&amp;sa=D&amp;ust=1595992325533000&amp;usg=AOvVaw3mTrsVeJxlcZtB88GyWsNd">3.
                WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23cookies&amp;sa=D&amp;ust=1595992325533000&amp;usg=AOvVaw3FIuiTW-cBMrmoOiUG3pat">4.
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23inforetain&amp;sa=D&amp;ust=1595992325533000&amp;usg=AOvVaw2iwUIxATku5UAz_T43-dBa">5.
                HOW LONG DO WE KEEP YOUR INFORMATION?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23infosafe&amp;sa=D&amp;ust=1595992325534000&amp;usg=AOvVaw1d8YEFFJjcVPq_KC2vI9Ff">6.
                HOW DO WE KEEP YOUR INFORMATION SAFE?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23infominors&amp;sa=D&amp;ust=1595992325534000&amp;usg=AOvVaw3LyI63VtPz7Q9mXejp8BV7">7.
                DO WE COLLECT INFORMATION FROM MINORS?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23privacyrights&amp;sa=D&amp;ust=1595992325534000&amp;usg=AOvVaw1avR3qw0oiaAObqTT9HDVw">8.
                WHAT ARE YOUR PRIVACY RIGHTS?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23DNT&amp;sa=D&amp;ust=1595992325534000&amp;usg=AOvVaw2z0arbwWYwXISvD7ZAuUzK">9.
                CONTROLS FOR DO-NOT-TRACK FEATURES</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23caresidents&amp;sa=D&amp;ust=1595992325535000&amp;usg=AOvVaw0i_ZM0UhIJSWDfxPt0WKNM">10.
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23policyupdates&amp;sa=D&amp;ust=1595992325535000&amp;usg=AOvVaw2ASxirMhjQOAThQUGlqKwO">11.
                DO WE MAKE UPDATES TO THIS POLICY?</a></span></p>
    <p class="c1"><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/480074/privacy-policy%23contact&amp;sa=D&amp;ust=1595992325535000&amp;usg=AOvVaw3XyjnAaqfQHL1XQIOYg8n7">12.
                HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a></span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">1. WHAT INFORMATION DO WE COLLECT?</span></p>
    <p class="c9"><span class="c4 c15"></span></p>
    <p class="c7"><span class="c15 c4">Personal information you disclose to us</span></p>
    <p class="c1"><span class="c11 c13">In Short:</span><span class="c11">&nbsp; </span><span class="c12 c11">We collect
            personal information that you provide to us.</span></p>
    <p class="c1"><span class="c3">We collect personal information that you voluntarily provide to us when registering
            at the Services expressing an interest in obtaining information about us or our products and services, when
            participating in activities on the Services (such as posting messages in our online forums or entering
            competitions, contests or giveaways) or otherwise contacting us.</span></p>
    <p class="c1"><span class="c3">The personal information that we collect depends on the context of your interactions
            with us and the Services, the choices you make and the products and features you use. The personal
            information we collect can include the following:</span></p>
    <p class="c1"><span class="c3">Publicly Available Personal Information. We collect first name, maiden name, last
            name, and nickname; email addresses; and other similar data. </span></p>
    <p class="c1"><span class="c3">All personal information that you provide to us must be true, complete and accurate,
            and you must notify us of any changes to such personal information.</span></p>
    <p class="c1 c18"><span class="c3"></span></p>
    <p class="c1"><span class="c16 c4">Information automatically collected</span></p>
    <p class="c1"><span class="c11 c13">In Short: &nbsp;</span><span class="c11">&nbsp;</span><span class="c12 c11">Some
            information &mdash; such as IP address and/or browser and device characteristics &mdash; is collected
            automatically when you visit our Services.</span></p>
    <p class="c1"><span class="c3">We automatically collect certain information when you visit, use or navigate the
            Services. This information does not reveal your specific identity (like your name or contact information)
            but may include device and usage information, such as your IP address, browser and device characteristics,
            operating system, language preferences, referring URLs, device name, country, location, information about
            how and when you use our Services and other technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our internal analytics and reporting
            purposes.</span></p>
    <p class="c1"><span class="c3">Like many businesses, we also collect information through cookies and similar
            technologies.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">2. HOW DO WE USE YOUR INFORMATION?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">We process
            your information for purposes based on legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.</span></p>
    <p class="c1"><span class="c3">We use personal information collected via our Services for a variety of business
            purposes described below. We process your personal information for these purposes in reliance on our
            legitimate business interests, in order to enter into or perform a contract with you, with your consent,
            and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.</span></p>
    <p class="c1"><span class="c3">We use the information we collect or receive:</span></p>
    <ul class="c17 lst-kix_at55vjgmrjdg-0 start">
        <li class="c6"><span class="c3">To facilitate account creation and logon process. If you choose to link your
                account with us to a third party account (such as your Google or Facebook account), we use the
                information you allowed us to collect from those third parties to facilitate account creation and logon
                process for the performance of the contract.</span></li>
    </ul>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">We only
            share information with your consent, to comply with laws, to provide you with services, to protect your
            rights, or to fulfill business obligations.</span></p>
    <p class="c7"><span class="c3">We may process or share data based on the following legal basis:</span></p>
    <ul class="c17 lst-kix_wkw0ppd4ukbl-0 start">
        <li class="c6"><span class="c3">Consent: We may process your data if you have given us specific consent to use
                your personal information in a specific purpose.<br></span></li>
        <li class="c6"><span class="c3">Legitimate Interests: We may process your data when it is reasonably necessary
                to achieve our legitimate business interests.<br></span></li>
        <li class="c6"><span class="c3">Performance of a Contract: Where we have entered into a contract with you, we
                may process your personal information to fulfill the terms of our contract.<br></span></li>
        <li class="c6"><span class="c3">Legal Obligations: We may disclose your information where we are legally
                required to do so in order to comply with applicable law, governmental requests, a judicial proceeding,
                court order, or legal process, such as in response to a court order or a subpoena (including in response
                to public authorities to meet national security or law enforcement requirements).<br></span></li>
        <li class="c6"><span class="c3">Vital Interests: We may disclose your information where we believe it is
                necessary to investigate, prevent, or take action regarding potential violations of our policies,
                suspected fraud, situations involving potential threats to the safety of any person and illegal
                activities, or as evidence in litigation in which we are involved.</span></li>
    </ul>
    <p class="c1"><span class="c3">More specifically, we may need to process your data or share your personal
            information in the following situations:</span></p>
    <ul class="c17 lst-kix_kqfq0cfofimy-0 start">
        <li class="c6"><span class="c3">Vendors, Consultants and Other Third-Party Service Providers. We may share your
                data with third party vendors, service providers, contractors or agents who perform services for us or
                on our behalf and require access to such information to do that work. Examples include: payment
                processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We
                may allow selected third parties to use tracking technology on the Services, which will enable them to
                collect data about how you interact with the Services over time. This information may be used to, among
                other things, analyze and track data, determine the popularity of certain content and better understand
                online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your
                information with third parties for their promotional purposes.<br></span></li>
        <li class="c6"><span class="c3">Business Transfers. We may share or transfer your information in connection
                with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                a portion of our business to another company.<br></span></li>
        <li class="c6"><span class="c3">Third-Party Advertisers. We may use third-party advertising companies to serve
                ads when you visit the Services. These companies may use information about your visits to our Website(s)
                and other websites that are contained in web cookies and other tracking technologies in order to provide
                advertisements about goods and services of interest to you.<br></span></li>
        <li class="c6"><span class="c3">Other Users. When you share personal information (for example, by posting
                comments, contributions or other content to the Services) or otherwise interact with public areas of the
                Services, such personal information may be viewed by all users and may be publicly distributed outside
                the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity,
                communicate with you within our Services, and view your profile.</span></li>
    </ul>
    <p class="c1 c18"><span class="c3"></span></p>
    <p class="c7"><span class="c2">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">We may use
            cookies and other tracking technologies to collect and store your information.</span></p>
    <p class="c1"><span class="c3">We may use cookies and similar tracking technologies (like web beacons and pixels) to
            access or store information. Specific information about how we use such technologies and how you can refuse
            certain cookies is set out in our Cookie Policy.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">We keep
            your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless
            otherwise required by law.</span></p>
    <p class="c1"><span class="c3">We will only keep your personal information for as long as it is necessary for the
            purposes set out in this privacy policy, unless a longer retention period is required or permitted by law
            (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping
            your personal information for longer than the period of time in which users have an account with us.</span>
    </p>
    <p class="c1"><span class="c3">When we have no ongoing legitimate business need to process your personal
            information, we will either delete or anonymize it, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we will securely store your personal
            information and isolate it from any further processing until deletion is possible.</span></p>
    <p class="c18 c19"><span class="c15 c4"></span></p>
    <p class="c1"><span class="c2">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">We aim to
            protect your personal information through a system of organizational and technical security measures.</span>
    </p>
    <p class="c1"><span class="c3">We have implemented appropriate technical and organizational security measures
            designed to protect the security of any personal information we process. However, please also remember that
            we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your
            personal information, transmission of personal information to and from our Services is at your own risk. You
            should only access the services within a secure environment.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">7. DO WE COLLECT INFORMATION FROM MINORS?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">We do not
            knowingly collect data from or market to children under 18 years of age.</span></p>
    <p class="c1"><span class="c3">We do not knowingly solicit data from or market to children under 18 years of age. By
            using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a
            minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information
            from users less than 18 years of age has been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you become aware of any data we have collected
            from children under age 18, please contact us at justin@thinkwander.com.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">8. WHAT ARE YOUR PRIVACY RIGHTS?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">You may
            review, change, or terminate your account at any time.</span></p>
    <p class="c1"><span class="c11">If you are resident in the European Economic Area and you believe we are unlawfully
            processing your personal information, you also have the right to complain to your local data protection
            supervisory authority. You can find their contact details here:</span><span class="c11">&nbsp;</span><span
            class="c0"><a class="c5"
                href="https://www.google.com/url?q=http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;ust=1595992325543000&amp;usg=AOvVaw2afwcZtLmsRbGUzpOGecoy">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a></span><span
            class="c3">.</span></p>
    <p class="c1"><span class="c3">If you have questions or comments about your privacy rights, you may email us at
            justin@thinkwander.com.</span></p>
    <p class="c9"><span class="c15 c4"></span></p>
    <p class="c7"><span class="c4 c16">Account Information</span></p>
    <p class="c1"><span class="c3">If you would at any time like to review or change the information in your account or
            terminate your account, you can:</span></p>
    <p class="c1"><span class="c11">&nbsp; &nbsp; &#9632; &nbsp;</span><span class="c3">Contact us using the contact
            information provided.</span></p>
    <p class="c1"><span class="c3">Upon your request to terminate your account, we will deactivate or delete your
            account and information from our active databases. However, some information may be retained in our files to
            prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply
            with legal requirements.</span></p>
    <p class="c1"><span class="c0">Cookies and similar technologies:</span><span class="c11">&nbsp;Most Web browsers are
            set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove
            cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain
            features or services of our Services. To opt-out of interest-based advertising by advertisers on our
            Services visit</span><span class="c11">&nbsp;</span><span class="c0"><a class="c5"
                href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;ust=1595992325544000&amp;usg=AOvVaw1gtjtQzvDywZdZoXIYGZOP">http://www.aboutads.info/choices/</a></span><span
            class="c3">.</span></p>
    <p class="c1"><span class="c0">Opting out of email marketing:</span><span class="c3">&nbsp;You can unsubscribe from
            our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be removed from the marketing email list
            &ndash; however, we will still need to send you service-related emails that are necessary for the
            administration and use of your account. To otherwise opt-out, you may:</span></p>
    <p class="c1"><span class="c11">&nbsp; &nbsp; &#9632; </span><span class="c3">&nbsp;Contact us using the contact
            information provided.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span></p>
    <p class="c1"><span class="c3">Most web browsers and some mobile operating systems and mobile applications include a
            Do-Not-Track (&ldquo;DNT&rdquo;) feature or setting you can activate to signal your privacy preference not
            to have data about your online browsing activities monitored and collected. No uniform technology standard
            for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT
            browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
            If a standard for online tracking is adopted that we must follow in the future, we will inform you about
            that practice in a revised version of this privacy policy.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c11 c12">Yes, if
            you are a resident of California, you are granted specific rights regarding access to your personal
            information.</span></p>
    <p class="c1"><span class="c3">California Civil Code Section 1798.83, also known as the &ldquo;Shine The
            Light&rdquo; law, permits our users who are California residents to request and obtain from us, once a year
            and free of charge, information about categories of personal information (if any) we disclosed to third
            parties for direct marketing purposes and the names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If you are a California resident and would
            like to make such a request, please submit your request in writing to us using the contact information
            provided below.</span></p>
    <p class="c1"><span class="c3">If you are under 18 years of age, reside in California, and have a registered account
            with the Services, you have the right to request removal of unwanted data that you publicly post on the
            Services. To request removal of such data, please contact us using the contact information provided below,
            and include the email address associated with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services, but please be aware that the data may not
            be completely or comprehensively removed from our systems.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c20"><span class="c2">11. DO WE MAKE UPDATES TO THIS POLICY?</span></p>
    <p class="c1"><span class="c11 c13">In Short: </span><span class="c11">&nbsp;</span><span class="c12 c11">Yes, we
            will update this policy as necessary to stay compliant with relevant laws.</span></p>
    <p class="c1"><span class="c3">We may update this privacy policy from time to time. The updated version will be
            indicated by an updated &ldquo;Revised&rdquo; date and the updated version will be effective as soon as it
            is accessible. If we make material changes to this privacy policy, we may notify you either by prominently
            posting a notice of such changes or by directly sending you a notification. We encourage you to review this
            privacy policy frequently to be informed of how we are protecting your information.</span></p>
    <p class="c8"><span class="c3"></span></p>
    <p class="c1"><span class="c2">12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</span></p>
    <p class="c1"><span class="c11">If you have questions or comments about this policy, you may email us at
            justin@thinkwander.com.</span></p>
</body>

</html>
`;

const html = ReactHtmlParser(privacy);

export default html;