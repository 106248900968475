// @flow

import {Container, Toast, Image} from 'react-bootstrap';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SideBar from './SideBar';
import logo from '../static/Wander_Logo.png';
import moment from 'moment';
import './Styles/NotificationCenter.css';
import {withStyles} from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import NotificationActions from '../Redux/NotificationRedux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import API from '../Services/API';

const StyledBadge = withStyles(() => ({
  badge: {
    backgroundColor: 'rgb(41, 119, 246)',
    marginTop: -10,
    left: -15,
    width: 10,
    height: 10,
    borderRadius: 5,
  },
}))(Badge);

type Props = {
  visible: boolean,
  notifications: {
    notification_id: string,
    title: string,
    text: string,
    url: string,
    time: Date,
    read: boolean,
  }[],
  history: any,
  dispatch: any,
  hideCenter: () => void,
};

class NotificationCenter extends Component<Props> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
  }

  handleNotificationPress = (notification) => {
    // Segment Event
    window.analytics.track('Notification Opened', notification);
    this.props.dispatch(
      NotificationActions.markAsRead(notification.notification_id),
    );
    this.api.markAsRead(notification.notification_id);
    this.props.history.push(notification.url);
  };

  handleNotificationClose = (event, notification) => {
    event.stopPropagation();
    // Segment Event
    window.analytics.track('Notification Deleted', notification);
    this.props.dispatch(
      NotificationActions.removeNotification(notification.notification_id),
    );
    this.api.deleteNotif(notification.notification_id);
  };

  renderBadge = (read) => {
    if (!read) {
      return <StyledBadge variant="dot" />;
    }
    return null;
  };

  renderNotifications = () => {
    if (this.props.notifications) {
      const reversed_list = [...this.props.notifications];
      reversed_list.reverse();
      const unread_notifications = [];
      const read_notifications = [];

      // eslint-disable-next-line array-callback-return
      reversed_list.map<any>((notification, index) => {
        const timeText = moment.utc(notification.time).fromNow();
        const element = (
          <Toast
            key={index}
            className="notification"
            onClick={() => this.handleNotificationPress(notification)}
            onClose={(e) => this.handleNotificationClose(e, notification)}>
            <Toast.Header>
              {this.renderBadge(notification.read)}
              <Image
                fluid
                rounded
                src={logo}
                className="mr-2 ml-1 notificationIcon"
              />
              <strong className="mr-auto">{notification.title}</strong>
              <small>{timeText}</small>
            </Toast.Header>
            <Toast.Body>{notification.text}</Toast.Body>
          </Toast>
        );
        if (!notification.read) {
          unread_notifications.push(element);
        } else {
          read_notifications.push(element);
        }
      });
      const all_notifications = unread_notifications.concat(read_notifications);
      return all_notifications;
    }
    return null;
  };

  render() {
    return (
      <SideBar show={this.props.visible}>
        <div>
          <Row className="m-0">
            <Col xs={12} className="text-right py-1 px-2">
              <ExitToAppIcon
                style={{cursor: 'pointer'}}
                onClick={this.props.hideCenter}
                fontSize="small"
              />
            </Col>
          </Row>
          <h3 className="mx-auto mt-0 mb-4 text-center">Notifications</h3>
          <Container fluid>{this.renderNotifications()}</Container>
        </div>
      </SideBar>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
});
export default connect(mapStateToProps)(withRouter(NotificationCenter));
