// @flow

import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-date-picker';

type Props = {
  show: boolean,
  post_id: number,
  onHide: () => void,
  performSchedule: (post_id: number, scheduleDate: Date) => void,
};

type State = {
  scheduleDate: Date,
};

class CalendarView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scheduleDate: new Date(),
    };
  }

  handleDateChange = (date: Date) => {
    this.setState({
      scheduleDate: date,
    });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered>
        <Modal.Body>
          <p className="pt-3" style={{textAlign: 'center'}}>
            Choose a day to read this post
          </p>
          <Row>
            <Col style={{textAlign: 'center'}}>
              <DatePicker
                onChange={this.handleDateChange}
                value={this.state.scheduleDate}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              this.props.performSchedule(
                this.props.post_id,
                this.state.scheduleDate,
              );
              this.props.onHide();
            }}>
            Schedule
          </Button>
          <Button variant="secondary" onClick={this.props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CalendarView;
