// @flow

import React, {Component} from 'react';
import NavigationBar from '../Components/NavigationBar';
import API from '../Services/API';
import {connect} from 'react-redux';
import ProtectedRoute from '../Components/ProtectedRoute';
import SocialFeed from './SocialFeed';
import ExploreFeed from './ExploreFeed';
import NativeReader from './NativeReader';
import Unread from './Unread';
import {Link, Switch} from 'react-router-dom';
import Cookies from 'js-cookie';
import Profile from './Profile';
import Playlist from './Playlist';
import Follow from './Follow';
import Admin from './Admin';
import Settings from './Settings';
import {ToastContainer, toast, Zoom} from 'react-toastify';
import UserActions from '../Redux/UserRedux';
import FeedActions from '../Redux/FeedRedux';
import PlaylistActions from '../Redux/PlaylistRedux';
import ConfigActions from '../Redux/ConfigRedux';
import ProfileActions from '../Redux/ProfileRedux';
import UnreadActions from '../Redux/UnreadRedux';
import {
  Dropdown,
  Navbar,
  Nav,
  Image,
  Button,
  Toast,
  Row,
  Col,
  Card,
  Container,
} from 'react-bootstrap';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SetPassword from '../Components/SetPassword';
import logo from '../static/Wander_Logo.png';
import reactRouterFetch from 'react-router-fetch';
import {routeConfig} from './RootContainer';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import NotificationCenter from '../Components/NotificationCenter';
import './Styles/App.css';
import {Badge} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import * as Notifications from '../Services/Notifications';
import NotificationPrompt from '../Components/NotificationPrompt';
import NotificationActions from '../Redux/NotificationRedux';
import MiniNavBar from '../Components/MiniNavBar';
import MenuIcon from '@material-ui/icons/Menu';
import Demo from '../Components/Demo';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import Timer from 'react-compound-timer';
import StopIcon from '@material-ui/icons/Stop';
import Colors from '../Config/Colors';
import Privacy from '../Components/Privacy';
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, Index, Configure} from 'react-instantsearch-dom';
import SearchHits from '../Components/SearchHits';
import CustomSearchBox from '../Components/SearchBar';
import UnreadSideCard from '../Components/UnreadSideCard';
import ToFollowSideCard from '../Components/ToFollowSideCard';

const searchClient = algoliasearch(
  '98X487OSDF',
  '7532b884e16e9770a890481f8617d87e',
);

const StyledBadge = withStyles(() => ({
  badge: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica',
    color: 'white',
    backgroundColor: Colors.primary,
    border: '2px solid white',
    margin: '3px',
  },
}))(Badge);

toast.configure({
  autoClose: 2000,
  draggable: false,
});

NProgress.configure({
  minimum: 0.3,
  showSpinner: false,
});

type Props = {
  dispatch: ({}) => void,
  location: any,
  history: any,
  storeInitialized: boolean,
  userValid: boolean,
  notifications: {
    read: boolean,
  }[],
  showToast: boolean,
  toastTitle: string,
  toastMessage: string,
  promptedForNotifications: boolean,
};
type State = {
  showPasswordModal: boolean,
  isFetching: boolean,
  showSidebar: boolean,
  showNotificationPrompt: boolean,
  showNavbar: boolean,
  grayName: string,
  showDemo: boolean,
  showPrivacy: Boolean,
};

class App extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      showPasswordModal: false,
      isFetching: false,
      showSidebar: false,
      showNotificationPrompt: false,
      showNavbar: false,
      grayName: 'noGray',
      showDemo: false,
      showPrivacy: false,
      showStats: false,
      searchState: '',
      resultsState: null,
      giveDemo: false,
    };

    this.api
      .getSocialPosts(10)
      .then((response) => {
        if (response.ok) {
          // console.log(response.data)
          this.props.dispatch(FeedActions.setSocialPosts(response.data));
        } else {
          console.warn(response.originalError);
        }
      })
      .catch((error) => console.warn(error));

    this.api
      .getExplorePosts(10)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setExplorePosts(response.data));
        }
      })
      .catch((error) => console.warn(error));

    this.api
      .getUserRatings()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(
            UserActions.setRatedPosts(response.data.rated_posts),
          );
        }
      })
      .catch((error) => console.warn(error));
  }

  componentDidMount() {
    if (this.props.storeInitialized) {
      if (
        Notifications.getPermissions() === 'default' &&
        !this.props.promptedForNotifications
      ) {
        this.setState({showNotificationPrompt: true});
      }
    }
    this.api.onboard().then((response) => {
      if (response.ok && response.data.onboard) {
        this.setState({showDemo: true});
      }
    });
    this.api.reset().then((response) => {
      if (window.innerWidth > 1000) {
        if (response.ok && response.data.reset_web) {
          this.api.endResetWeb().then((res) => {
            if (res.ok) {
              this.props.dispatch(FeedActions.reset());
              this.props.dispatch(ProfileActions.reset());
              window.location.reload();
            }
          });
        }
      } else {
        if (response.ok && response.data.reset_mobile) {
          this.api.endResetMob().then((res) => {
            if (res.ok) {
              this.props.dispatch(FeedActions.reset());
              this.props.dispatch(ProfileActions.reset());
              window.location.reload();
            }
          });
        }
      }
    });
    this.fetchRoutes();
  }

  componentDidUpdate(prevProps, prevState) {
    const current = `${this.props.location.pathname}${this.props.location.search}`;
    const prev = `${prevProps.location.pathname}${prevProps.location.search}`;
    if (current !== prev) {
      this.fetchRoutes();
    }

    if (this.props.storeInitialized && !prevProps.storeInitialized) {
      if (
        Notifications.getPermissions() === 'default' &&
        !this.props.promptedForNotifications
      ) {
        this.setState({showNotificationPrompt: true});
      }
    }
  }

  fetchRoutes = () => {
    const {dispatch, location} = this.props;
    this.props.dispatch(ConfigActions.setFetching(true));
    NProgress.start();
    reactRouterFetch(routeConfig, location, {dispatch})
      .then((results) => {
        this.props.dispatch(ConfigActions.setFetching(false));
        NProgress.done();
      })
      .catch((error) => {
        console.warn(error);
        this.props.dispatch(ConfigActions.setFetching(false));
        NProgress.done();
      });
  };

  handleLogout = () => {
    Cookies.remove('accessToken');
    this.props.dispatch(UserActions.reset());
    this.props.dispatch(FeedActions.reset());
    this.props.dispatch(PlaylistActions.reset());
    this.props.dispatch(ConfigActions.reset());
    this.props.dispatch(ProfileActions.reset());
    this.props.dispatch(UnreadActions.reset());
    // this.props.dispatch(TourActions.reset())
    this.props.history.push('/landing');
  };

  handleModalDismiss = () => this.setState({showPasswordModal: false});

  handleSetPassword = () => this.setState({showPasswordModal: true});

  handleNotificationPromptHide = () => {
    this.setState({
      showNotificationPrompt: false,
    });
  };

  handleSearch = (e) => {
    const current_state = this.state;
    current_state.searchState = e.target.value;
    this.setState(current_state);
    // console.log(this.state.searchState)
  };

  renderInbox = () => {
    if (!this.props.focusActive) {
      return (
        <Button
          variant="outline-primary"
          className="stopFocus ml-3"
          onClick={() => {
            if (!this.props.focusActive) {
              this.setState({showSidebar: !this.state.showSidebar});
            }
          }}
          style={{height: '39.81px'}}>
          <StyledBadge
            badgeContent={
              this.props.notifications.filter((n) => !n.read).length
            }
            max={10}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <NotificationsIcon />
          </StyledBadge>
        </Button>
      );
    }
    return (
      <Button
        variant="outline-primary"
        className="stopFocus ml-3"
        onClick={() => {
          this.setState({showStats: true});
        }}>
        <StopIcon />
      </Button>
    );
  };

  renderToast() {
    return (
      <Toast
        autohide
        show={this.props.showToast}
        delay={6000}
        onClose={() => this.props.dispatch(NotificationActions.hideToast())}
        style={{
          position: 'fixed',
          top: 90,
          right: 20,
          zIndex: 999,
        }}>
        <Toast.Header>
          <strong className="mr-auto">{this.props.toastTitle}</strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>{this.props.toastMessage}</Toast.Body>
      </Toast>
    );
  }

  demoToggle = () => {
    // this.props.dispatch(TourActions.startTour())
    // window.location.reload(true);
    this.setState({showDemo: !this.state.showDemo});
  };

  privacyToggle = () => {
    this.setState({showPrivacy: !this.state.showPrivacy});
  };

  finishDemo = () => {
    this.demoToggle();
    this.api.endOnboard();
  };

  handleMenuClick = (e) => {
    if (!this.state.showNavbar) {
      this.setState({
        showNavbar: !this.state.showNavbar,
        grayName: 'grayOut',
      });
      /* if (window.innerWidth < 1200 && this.props.homeRun && this.props.homeStep === 1) {
        this.props.dispatch(TourActions.setHomeStep(2))
        this.props.dispatch(TourActions.setHomeRun(false))
        setTimeout(() => this.props.dispatch(TourActions.setHomeRun(true)), 500)
      }
      if (window.innerWidth < 1200 && this.props.exploreRun && this.props.exploreStep === 5) {
        this.props.dispatch(TourActions.setExploreStep(6))
        this.props.dispatch(TourActions.setExploreRun(false))
        setTimeout(() => this.props.dispatch(TourActions.setExploreRun(true)), 500)
      }
      if (window.innerWidth < 1200 && this.props.profileRun && this.props.profileStep === 4) {
        this.props.dispatch(TourActions.setProfileStep(5))
        this.props.dispatch(TourActions.setProfileRun(false))
        setTimeout(() => this.props.dispatch(TourActions.setProfileRun(true)), 500)
      } */
    } else {
      this.setState({
        showNavbar: !this.state.showNavbar,
        grayName: 'noGray',
      });
    }
  };

  growsurfLoad = () => {
    // Check to see if GrowSurf is available
    const script = document.createElement('script');
    script.src = 'https://growsurf.com/growsurf.js?v=2.0.0';
    script.setAttribute('grsf-campaign', 'jaoh4t');
    script.async = true;
    document.head.appendChild(script);
  };

  resetSearchState = () => {
    this.setState({searchState: ''});
  };

  renderFocus = () => {
    if (window.location.pathname.includes('unread')) {
      if (this.props.focusPosts) {
        return (
          <Link
            to={`/post/${this.props.focusPosts[this.props.currentPost]}`}
            href={`/post/${this.props.focusPosts[this.props.currentPost]}`}
            className="mr-3">
            <TrackChangesIcon
              className="focusBtn"
              style={{
                fontSize: '40px',
                cursor: 'pointer',
                color: Colors.primary,
              }}
              onClick={this.startFocus}
            />
          </Link>
        );
      }
      return (
        <TrackChangesIcon
          className="focusBtn mr-3"
          style={{fontSize: '40px', cursor: 'pointer', color: Colors.primary}}
        />
      );
    }
    return null;
  };

  startFocus = () => {
    if (this.props.focusPosts) {
      window.analytics.track('Focus mode began', this.props.focusPosts);
      const startTime = new Date();
      this.props.dispatch(UnreadActions.changeFocus(true, startTime));
      if (document.fullscreenElement === null) {
        this.openFullscreen();
      }
    }
  };

  renderTimer = () => {
    if (this.props.focusActive && window.location.pathname.includes('post')) {
      const totalTime = this.props.timeLimit * 60000;
      return (
        <div className="pr-3">
          <Timer
            startImmediately={true}
            initialTime={totalTime}
            direction="backward"
            lastUnit="m"
            checkpoints={[
              {
                time: 0,
                callback: () => {
                  this.setState({showStats: true});
                },
              },
            ]}>
            <h3
              className="my-auto"
              style={{fontWeight: '300', color: Colors.primary}}>
              <Timer.Minutes /> min.
            </h3>
          </Timer>
        </div>
      );
    }
    return null;
  };

  toggleShowStats = () => {
    this.setState({
      showStats: !this.state.showStats,
    });
  };

  openFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  renderLogo = () => {
    if (!this.props.focusActive) {
      return (
        <div>
          <Nav.Item className="d-none d-xl-block">
            <Nav.Link as={Link} to="/" href="/" className="px-0">
              <Image fluid rounded src={logo} style={{height: 41}} />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id="mobile-menu-icon" className="d-block d-xl-none">
            <Nav.Link onClick={this.handleMenuClick} className="px-0">
              <MenuIcon fontSize="large" />
            </Nav.Link>
          </Nav.Item>
        </div>
      );
    }
    return (
      <div>
        <Nav.Item className="d-none d-xl-block">
          <Nav.Link className="px-0">
            <Image fluid rounded src={logo} style={{height: 41}} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item id="mobile-menu-icon" className="d-block d-xl-none">
          <Nav.Link className="px-0">
            <MenuIcon fontSize="large" />
          </Nav.Link>
        </Nav.Item>
      </div>
    );
  };

  renderNav = () => (
    <Col xl={3} className="d-none d-xl-block pt-3 pl-4">
      <NavigationBar />
    </Col>
  );

  renderRightCol = () => (
    <>
      <Card
        className="shadow-sm"
        style={{borderRadius: 10, backgroundColor: 'white'}}>
        <h2
          style={{
            paddingLeft: 10,
            fontSize: 18,
            color: 'grey',
            marginTop: 6,
            marginBottom: 6,
            fontWeight: 'bold',
          }}>
          Unread
        </h2>
        <hr style={{marginTop: 0, marginBottom: 4}} />
        <Card.Body
          align="left"
          style={{
            paddingTop: 6,
            paddingBottom: 6,
            paddingRight: 4,
            paddingLeft: 0,
          }}>
          <UnreadSideCard />
        </Card.Body>
        <hr style={{marginTop: 4, marginBottom: 0}} />
        <div
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 0,
            fontSize: 14,
          }}
          align="left">
          <Link to="/unread">
            <div style={{paddingLeft: 10}}>Show More</div>
          </Link>
        </div>
      </Card>
      <Card
        className="shadow-sm"
        style={{marginTop: 30, height: '50%', borderRadius: 10}}>
        <h2
          style={{
            paddingLeft: 10,
            fontSize: 18,
            color: 'grey',
            marginTop: 6,
            marginBottom: 6,
            fontWeight: 'bold',
          }}>
          Wanderers to Follow
        </h2>
        <hr style={{marginTop: 0, marginBottom: 4}} />
        <Card.Body align="center" style={{padding: 4}}>
          <ToFollowSideCard />
        </Card.Body>
        <hr style={{marginTop: 4, marginBottom: 0}} />
        <div
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 10,
            fontSize: 14,
          }}
          align="left">
          <Link to="/follow">Show More</Link>
        </div>
      </Card>
    </>
  );

  renderMain = () => {
    if (window.location.pathname.includes('post')) {
      return (
        <NativeReader
          path="/post/:id"
          showFocusStats={this.state.showStats}
          toggleStats={this.toggleShowStats}
        />
      );
    } else if (
      window.location.pathname === '/' ||
      window.location.pathname.includes('explore') ||
      window.location.pathname.includes('collection') ||
      window.location.pathname.includes('playlist')
    ) {
      return (
        <Row className="mx-0">
          {this.renderNav()}
          <Col xl={6} lg={9} xs={12}>
            <Switch>
              <ProtectedRoute path="/explore">
                <ExploreFeed />
              </ProtectedRoute>
              <Playlist path="/playlist/:id" />
              <Playlist path="/collection/:id" />
              <ProtectedRoute path="/">
                <SocialFeed />
              </ProtectedRoute>
            </Switch>
          </Col>
          <Col
            lg={3}
            className="d-none d-lg-block pt-3"
            style={{position: 'fixed', right: 0}}>
            {this.renderRightCol()}
          </Col>
        </Row>
      );
    }

    // <Row className='mx-0 pt-3'>
    //   <Col xs={12}>
    //     <div className="referral" data-grsf-block-form data-grsf-email={this.props.email}></div>
    //   </Col>
    // </Row>
    // <Row className="mx-0 d-block">
    //   <Col xs={12} className='text-center'>
    //     <div className="d-block"
    //       data-grsf-max-progress-icons="6"
    //       data-grsf-title-style="{'font-size': '12px'}"
    //       data-grsf-card-style="{'margin-left': '1%', 'margin-right': '1%', 'margin-top': '2%', 'margin-bottom': '2%'}"
    //       data-grsf-block-rewards
    //       data-grsf-horizontal data-grsf-horizontal-scroll
    //       data-grsf-email={this.props.email} >
    //     </div>
    //   </Col>
    // </Row>

    return (
      <Row className="mx-0">
        {this.renderNav()}
        <Col xl={9} xs={12} className="pl-xl-0">
          <Switch>
            <ProtectedRoute path="/profile/:id">
              <Profile />
            </ProtectedRoute>
            <ProtectedRoute path="/follow">
              <Follow />
            </ProtectedRoute>
            <ProtectedRoute path="/googleCallback">
              <Unread callback />
            </ProtectedRoute>
            <ProtectedRoute path="/unread">
              <Unread />
            </ProtectedRoute>
            <ProtectedRoute path="/admin">
              <Admin />
            </ProtectedRoute>
            <ProtectedRoute path="/settings">
              <Settings />
            </ProtectedRoute>
          </Switch>
        </Col>
      </Row>
    );
  };

  render() {
    const postPage = window.location.pathname.includes('post');
    // const followPage = window.location.pathname.includes('follow')
    // this.growsurfLoad();
    return (
      <div id={postPage ? 'post-wrapper' : 'wrapper'}>
        <InstantSearch indexName="users" searchClient={searchClient}>
          <ToastContainer
            enableMultiContainer
            containerId="N"
            transition={Zoom}
            hideProgressBar
            position={toast.POSITION.TOP_RIGHT}
          />
          <div className={this.state.grayName} onClick={this.handleMenuClick} />
          <div id="appContent">
            {this.renderToast()}
            <Navbar
              className="justify-start"
              id="sticky-nav"
              fixed="top"
              bg="light"
              variant="light"
              expand="lg">
              <Nav>{this.renderLogo()}</Nav>
              <div className="d-flex flex-row ml-auto my-auto">
                <div
                  style={
                    window.innerWidth > 1000
                      ? {width: '200px', marginRight: 10}
                      : {width: '150px', marginRight: 15}
                  }>
                  <Configure hitsPerPage={3} />

                  {/* <Index
                indexName="posts"/> */}
                  {/* <SearchBar handleSearch={this.handleSearch}/> */}
                  {/* <SearchBox
              searchAsYouType={true}
              onChange={this.handleSearch}
              autoFocus={true}/> */}

                  <CustomSearchBox handleSearch={this.handleSearch} />
                </div>
                {this.renderTimer()}
                {this.renderFocus()}
                <Dropdown className="pl-0" alignRight>
                  <Dropdown.Toggle
                    className="shadow-none"
                    variant="outline-primary">
                    <SettingsRoundedIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => this.props.history.push('/settings')}>
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleSetPassword}>
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.privacyToggle}>
                      {' '}
                      Privacy Policy{' '}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({giveDemo: true});
                        this.demoToggle();
                      }}>
                      Give Me A Demo
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-danger"
                      onClick={this.handleLogout}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                  <Demo
                    show={this.state.showDemo}
                    close={this.demoToggle}
                    finish={this.finishDemo}
                    first={!this.state.giveDemo}
                  />
                  <Privacy
                    show={this.state.showPrivacy}
                    close={this.privacyToggle}
                  />
                </Dropdown>
                {this.renderInbox()}
              </div>
              <SetPassword
                show={this.state.showPasswordModal}
                onHide={this.handleModalDismiss}
              />
            </Navbar>
            {this.state.searchState !== '' && (
              <span
                style={{
                  position: 'fixed',
                  marginLeft: '55vw',
                  marginRight: '30vw',
                  border: '2px solid grey',
                  top: 80,
                  borderRadius: 5,
                  zIndex: 10,
                  backgroundColor: 'white',
                  minWidth: 600,
                }}>
                <Container fluid={false}>
                  <Row style={{marginBottom: 5}}>
                    <Col style={{color: 'grey', fontWeight: 'bold'}}>USERS</Col>
                  </Row>
                  <Index indexName="users">
                    <SearchHits
                      resetSearch={this.resetSearchState}
                      style={{marginBottom: 5}}
                    />
                  </Index>
                  <Row>
                    <Col style={{color: 'grey', fontWeight: 'bold'}}>POSTS</Col>
                  </Row>
                  <Index indexName="posts">
                    <SearchHits
                      resetSearch={this.resetSearchState}
                      style={{marginBottom: 5}}
                    />
                  </Index>
                </Container>
              </span>
            )}
            <div
              id={postPage ? 'post-pageContent' : 'pageContent'}
              onClick={() => this.resetSearchState()}>
              {this.renderMain()}
            </div>
            <NotificationPrompt
              show={this.state.showNotificationPrompt}
              onHide={this.handleNotificationPromptHide}
            />
          </div>
          <MiniNavBar
            show={this.state.showNavbar}
            handleClose={this.handleMenuClick}
          />
          <NotificationCenter
            visible={this.state.showSidebar}
            hideCenter={() =>
              this.setState({showSidebar: !this.state.showSidebar})
            }
          />
        </InstantSearch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  notifications: state.notifications.notifications,
  showToast: state.notifications.showToast,
  toastTitle: state.notifications.toastTitle,
  toastMessage: state.notifications.toastMessage,
  storeInitialized: state.config.storeInitialized,
  userValid: !!state.user.id,
  promptedForNotifications: state.user.promptedForNotifications,
  currentPost: state.unread.currentPost,
  focusActive: state.unread.focusActive,
  focusPosts: state.unread.focusPosts,
  timeLimit: state.unread.timeLimit,
});
export default connect(mapStateToProps)(App);
