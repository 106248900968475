// @flow

import React, {Component} from 'react';
import './Styles/TagFilter.css';
import {Container, Spinner, Row, Col} from 'react-bootstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import TagComponent from './Tag';
import type {Tag} from '../Config/Types';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import FeedActions from '../Redux/FeedRedux';
import API from '../Services/API';
import {connect} from 'react-redux';
import Immutable from 'seamless-immutable';

const ArrowLeft = <ArrowBackIosRoundedIcon />;
const ArrowRight = <ArrowForwardIosRoundedIcon />;

type Props = {
  tags: Tag[],
  dispatch: ({}) => void,
};
type State = {
  selectedTags: number[],
  fetchedTags: number[],
  refreshingTags: boolean,
};

class TagFilter extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      refreshingTags: false,
      selectedTags: [-1],
      fetchedTags: [],
    };
  }

  updatePosts = (tags: number[]) => {
    this.setState({refreshingTags: true});
    this.api.getExplorePosts(20, [], tags).then((response) => {
      if (response.ok) {
        this.props.dispatch(FeedActions.setExplorePosts(response.data));
        this.setState({fetchedTags: tags});
      }
      this.setState({refreshingTags: false});
    });
  };

  handleClearTags = () => {
    this.setState({
      selectedTags: [],
    });
    this.updatePosts([]);
  };

  onTagPress = (tagID: number) => {
    // let newTags = []
    // if (this.state.selectedTags.includes(tagID)) {
    //   newTags = this.state.selectedTags.filter((selectedTag) => selectedTag !== tagID)
    // } else {
    //   newTags = this.state.selectedTags.concat(tagID)
    // }

    // Segment Event
    window.analytics.track(
      'TagFilter Selected',
      this.props.tags.filter((selectedTag) => selectedTag.tag_id === tagID),
    );
    this.setState({selectedTags: [tagID]});
    this.updatePosts([tagID]);
  };

  onAllTagPress = () => {
    // Segment Event
    window.analytics.track('TagFilter Cleared');
    this.setState({selectedTags: [-1]});
    this.updatePosts([]);
  };

  renderOverlay = () => {
    if (this.state.refreshingTags) {
      return (
        <div id="loadingOverlay">
          <div
            id="loadingContent"
            className="d-flex flex-row justify-content-between align-content-center">
            <Spinner animation="border" />
            <h5 className="my-auto">Updating posts...</h5>
          </div>
        </div>
      );
    }
    return null;
  };

  renderTagButtons = () => {
    const tags = Immutable.asMutable(
      this.props.tags.map<any>((tag: Tag) => {
        const isSelected = this.state.selectedTags.includes(tag.tag_id);
        let tagClass = '';
        if (!isSelected && this.state.selectedTags.length > 0) {
          tagClass = 'unselected';
        }
        return (
          <TagComponent
            className={`mx-2 tagButton my-auto ${tagClass}`}
            key={tag.tag_id}
            clickable
            id={tag.tag_id}
            text={tag.text}
            color={tag.hex_bg_color}
            textColor={tag.hex_text_color}
            handlePress={() => this.onTagPress(tag.tag_id)}
            isSelected={isSelected}
          />
        );
      }),
    );

    // Add 'all' tag to beginning of list
    const allTagID = -1;
    const isSelected = this.state.selectedTags.includes(allTagID);
    let tagClass = '';
    if (!isSelected && this.state.selectedTags.length > 0) {
      tagClass = 'unselected';
    }
    tags.unshift(
      <TagComponent
        className={`mx-2 tagButton my-auto ${tagClass}`}
        key={allTagID}
        clickable
        id={allTagID}
        text="All"
        color="#30AD63"
        textColor="#FFFFFF"
        handlePress={this.onAllTagPress}
        isSelected={isSelected}
      />,
    );

    return tags;
  };

  render() {
    return (
      <Container fluid id="tagFilterContainer" className="px-4 mb-4">
        <Row className="justify-content-center">
          <Col md={8} xs={12} className="px-1">
            <ScrollMenu
              dragging={false}
              alignCenter={false}
              data={this.renderTagButtons()}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
            />
            {this.renderOverlay()}
          </Col>
          {/* <Col sm='auto' className='my-auto'> */}
          {/*  <Button */}
          {/*    variant='success' */}
          {/*    disabled={JSON.stringify(this.state.selectedTags) === JSON.stringify(this.state.fetchedTags)} */}
          {/*    onClick={() => this.updatePosts(this.state.selectedTags)} */}
          {/*  > */}
          {/*    Filter */}
          {/*  </Button> */}
          {/* </Col> */}
          {/* <Col sm='auto' className='my-auto'> */}
          {/*  <Button */}
          {/*    disabled={this.state.selectedTags.length === 0} */}
          {/*    onClick={this.handleClearTags} */}
          {/*    variant='secondary' */}
          {/*  > */}
          {/*    Clear */}
          {/*  </Button> */}
          {/* </Col> */}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  tags: state.feed.tags,
});
export default connect(mapStateToProps)(TagFilter);
