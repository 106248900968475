// @flow

import {Nav, Button, Modal} from 'react-bootstrap';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import FeedbackBox from './FeedbackBox';
import HomeIcon from '@material-ui/icons/Home';
import ExploreIcon from '@material-ui/icons/Explore';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import './Styles/NavigationBar.css';
import CreateLink from './CreateLink';

type Props = {
  user: {
    id: number,
    name: string,
  },
  location: {
    pathname: string,
  },
};
type State = {
  showModal: boolean,
  showFeedback: boolean,
};

class NavigationBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      showFeedback: false,
      modal: true,
    };
  }
  componentDidMount() {
    const createPage = window.location.pathname.includes('create');
    if (createPage) {
      this.handleNewPostPress();
    }
  }
  handleModalDismiss = () => {
    this.setState({showModal: false});
  };

  handleNewPostPress = () => {
    this.setState({showModal: true});
    window.analytics.track('New Post Dialog Box Opened');
  };

  handleFeedbackPress = () => {
    window.open(
      'https://thinkwander.feedbear.com/boards/feature-requests',
      '_blank',
    );
    // this.setState({ showFeedback: !this.state.showFeedback})
  };

  checkCreatePost = () => {
    if (window.location.href.indexOf('create') > -1) {
      this.setState({showModal: true});
    }
  };
  render() {
    const postPage = window.location.pathname.includes('post');
    return (
      <div
        className="d-none d-xl-block"
        id={postPage ? 'post-sidenav' : 'sidenav1'}>
        <Nav
          id="nav-bar"
          activeKey={this.props.location.pathname}
          className="flex-column px-2">
          <Nav.Item>
            <Nav.Link
              onClick={() => {
                window.analytics.track('home tab clicked');
              }}
              as={Link}
              to="/"
              href="/"
              style={{fontSize: '35px', fontWeight: '200'}}
              id={
                this.props.location.pathname === '/' ? 'home' : 'home-inactive'
              }>
              <HomeIcon style={{marginBottom: '5px'}} fontSize="large" />
              &nbsp;&nbsp;&nbsp;Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={() => {
                window.analytics.track('explore tab clicked');
              }}
              as={Link}
              to="/explore"
              href="/explore"
              style={{fontSize: '35px', fontWeight: '200'}}
              id={
                this.props.location.pathname === '/explore'
                  ? 'explore'
                  : 'explore-inactive'
              }>
              <ExploreIcon style={{marginBottom: '5px'}} fontSize="large" />
              &nbsp;&nbsp;&nbsp;Explore
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={() => {
                window.analytics.track('unread tab clicked');
              }}
              as={Link}
              to="/unread"
              href="/unread"
              style={{fontSize: '35px', fontWeight: '200'}}
              id={
                this.props.location.pathname === '/unread'
                  ? 'unread'
                  : 'unread-inactive'
              }>
              <BookmarkBorderIcon
                style={{marginBottom: '5px'}}
                fontSize="large"
              />
              &nbsp;&nbsp;&nbsp;Unread
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              onClick={() => {
                window.analytics.track('profile tab clicked');
              }}
              to={`/profile/${this.props.user.id}`}
              href={`/profile/${this.props.user.id}`}
              style={{fontSize: '35px', fontWeight: '200'}}
              id={
                this.props.location.pathname ===
                `/profile/${this.props.user.id}`
                  ? 'profile'
                  : 'profile-inactive'
              }>
              <PersonIcon style={{marginBottom: '5px'}} fontSize="large" />
              &nbsp;&nbsp;&nbsp;Profile
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              onClick={() => {
                window.analytics.track('follow tab clicked');
              }}
              as={Link}
              to="/follow"
              href="/follow"
              style={{fontSize: '35px', fontWeight: '200'}}
              id={
                this.props.location.pathname === '/follow'
                  ? 'follow'
                  : 'follow-inactive'
              }>
              <PeopleIcon style={{marginBottom: '3px'}} fontSize="large" />
              &nbsp;&nbsp;&nbsp;Follow
            </Nav.Link>
          </Nav.Item>
          {this.props.user.role === 'admin' ? (
            <Nav.Item>
              <Nav.Link
                onClick={() => {
                  window.analytics.track('admin tab clicked');
                }}
                as={Link}
                to="/admin"
                href="/admin"
                style={{fontSize: '35px', fontWeight: '200'}}
                id={
                  this.props.location.pathname === '/admin'
                    ? 'admin'
                    : 'admin-inactive'
                }>
                <AssignmentIcon
                  style={{marginBottom: '3px'}}
                  fontSize="large"
                />
                &nbsp;&nbsp;&nbsp;Admin
              </Nav.Link>
            </Nav.Item>
          ) : null}
          <Nav.Item className="px-3 pt-3 pb-2">
            <Button
              block
              id="new-post-button"
              style={{borderRadius: '15px'}}
              variant="primary"
              className="shadow-none"
              onClick={this.handleNewPostPress}>
              New Post
            </Button>
          </Nav.Item>
          <Nav.Item className="px-3 pt-3 pb-2">
            <Button
              block
              style={{borderRadius: '15px'}}
              variant="primary"
              className="shadow-none"
              onClick={this.handleFeedbackPress}>
              Leave Feedback
            </Button>
          </Nav.Item>
        </Nav>
        <CreateLink
          show={this.state.showModal}
          onHide={this.handleModalDismiss}
          onShow={this.handleNewPostPress}
          modal={this.state.modal}
        />
        <Modal
          show={this.state.showFeedback}
          onHide={this.handleFeedbackPress}
          centered>
          <FeedbackBox />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: {
    name: state.user.name,
    id: state.user.id,
    role: state.user.role,
  },
  playlists: state.user.playlists,
});
export default connect(mapStateToProps)(withRouter(NavigationBar));
