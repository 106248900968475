import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setTestUsers: ['testUsers'],
  addTestUser: ['user'],
  removeTestUser: ['userId'],
  setAdminUsers: ['adminUsers'],
  addAdminUser: ['user'],
  removeAdminUser: ['userId'],
  reset: [],
});

export const UnreadTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  testUsers: [],
  adminUsers: [],
});

/* ------------- Reducers ------------- */

export const setTestUsers = (state, {testUsers}) =>
  state.merge({
    testUsers,
  });

export const addTestUser = (state, {user}) =>
  state.merge({
    testUsers: [...state.testUsers, user],
  });

export const removeTestUser = (state, {userId}) =>
  state.merge({
    testUsers: state.testUsers.filter((user) => user.user_id !== userId),
  });

export const setAdminUsers = (state, {adminUsers}) =>
  state.merge({
    adminUsers,
  });

export const addAdminUser = (state, {user}) =>
  state.merge({
    adminUsers: [...state.adminUsers, user],
  });

export const removeAdminUser = (state, {userId}) =>
  state.merge({
    adminUsers: state.adminUsers.filter((user) => user.user_id !== userId),
  });

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_TEST_USERS]: setTestUsers,
  [Types.ADD_TEST_USER]: addTestUser,
  [Types.REMOVE_TEST_USER]: removeTestUser,
  [Types.SET_ADMIN_USERS]: setAdminUsers,
  [Types.ADD_ADMIN_USER]: addAdminUser,
  [Types.REMOVE_ADMIN_USER]: removeAdminUser,
  [Types.RESET]: reset,
});
