/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-bind */
// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import API from '../Services/API';
import './Styles/Admin.css';
import {Button, Row, Col} from 'react-bootstrap';
import Colors from '../Config/Colors';

type Props = {
  dispatch: ({}) => void,
};

type State = {};

const emailNotifTypes = ['none', 'all', 'shoutouts and tags'];

class Settings extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      emailNotificationType: '',
      currentEmailNotifType: '',
      loaded: false,
    };
  }

  componentDidMount() {
    this.api
      .getEmailNotificationType()
      .then((response) => {
        this.setState({
          currentEmailNotifType: response.data.email_notif_type,
          emailNotificationType: response.data.email_notif_type,
          loaded: true,
        });
      })
      .catch((error) => console.warn(error));
  }

  handleEmailNotificationSave(emailNotifType) {
    this.api
      .changeEmailNotificationType(emailNotifType)
      .then(() => this.setState({currentEmailNotifType: emailNotifType}))
      .catch(() => console.warn('Error'));
  }

  renderEmailNotificationSetting() {
    return (
      <div>
        <Row>
          <Col xs="auto" />
          <Col xs={5} md={5} className="text-right px-0 my-auto">
            <p className="my-auto">Email Notification Type:</p>
          </Col>
          <Col xs={6} sm={4}>
            <select
              className="form-control"
              aria-label="Email Notification Types"
              onChange={(event) =>
                this.setState({emailNotificationType: event.target.value})
              }>
              {emailNotifTypes.map((notifType) => {
                if (notifType !== this.state.currentEmailNotifType) {
                  return (
                    <option value={notifType} key={notifType}>
                      {notifType}
                    </option>
                  );
                } else {
                  return (
                    <option selected value={notifType} disabled={true}>
                      {notifType}
                    </option>
                  );
                }
              })}
            </select>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col xs={12}>
            <Button
              variant="primary"
              onClick={() =>
                this.handleEmailNotificationSave(
                  this.state.emailNotificationType,
                )
              }
              disabled={
                this.state.currentEmailNotifType ===
                this.state.emailNotificationType
              }>
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <Row className="mt-5 mx-0 pt-5 pt-lg-0 pb-4 pb-lg-0 justify-content-center">
            <Col xs={12}>
              <Row>
                <Col
                  lg={{span: 8, order: 1}}
                  xs={{span: 12, order: 1}}
                  className="text-center">
                  <Row>
                    <Col xs={12} className="pb-3">
                      <h3
                        style={{fontWeight: '300', color: Colors.primary}}
                        className="adminHeader">
                        Settings
                      </h3>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div>
        <Row className="mt-5 mx-0 pt-5 pt-lg-0 pb-4 pb-lg-0 justify-content-center">
          <Col xs={12}>
            <Row>
              <Col
                lg={{span: 8, order: 1}}
                xs={{span: 12, order: 1}}
                className="text-center">
                <Row>
                  <Col xs={12} className="pb-3">
                    <h3
                      style={{fontWeight: '300', color: Colors.primary}}
                      className="adminHeader">
                      Settings
                    </h3>
                  </Col>
                </Row>
                {this.renderEmailNotificationSetting()}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(Settings);
