// @flow

import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import API from '../Services/API';
import UnreadActions from '../Redux/UnreadRedux';
import FeedActions from '../Redux/FeedRedux';
import PlaylistActions from '../Redux/PlaylistRedux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import './Styles/Unread.css';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TodayIcon from '@material-ui/icons/Today';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Dropdown from 'react-bootstrap/Dropdown';
import {toast} from 'react-toastify';
import DeletePost from '../Components/DeletePost';
import PrivatePost from '../Components/PrivatePost';
import SchedulePost from '../Components/SchedulePost';
import CalendarView from '../Components/CalendarView';
import FlipMove from 'react-flip-move';
import {Image, Button, Modal} from 'react-bootstrap';
import Colors from '../Config/Colors';
import PlaylistBar from '../Components/PlaylistBar';

const niceMessages = [
  "Bravo! You're killing it.",
  'Wow, you just keep getting smarter!',
  'Another post checked off the list? Well done!',
  'Just wait until your family hears about what you just accomplished...',
  'Hooray! One less thing in your queue.',
  'You just made my day!',
  "You're awesome, smartypants!",
  'Bet you thought no one would notice... Well, I did, and I’m impressed.',
  "You're an intellectual machine!",
  'I’ve got a feeling this is only the beginning of your intellectual journey.',
  'Cheers, you brainiac!',
  'You amaze me!',
];

type Props = {
  posts: [],
  user_id: number,
  stillFetching: boolean,
  callback: boolean,
  calendarPrompted: boolean,
  dispatch: ({}) => void,
};

type State = {
  showDelete: boolean,
  showModal: boolean,
  showRead: boolean,
  showAllPast: boolean,
  showAllToday: boolean,
  showAllUpcoming: boolean,
  showScheduleWindow: boolean,
  showCalendarView: boolean,
  showPlaylists: boolean,
  postIDModifying: number,
  hidePosts: {},
  gifSrc: any,
};

class Unread extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      showDelete: false,
      showModal: false,
      showRead: false,
      showAllPast: false,
      showAllToday: false,
      showAllUpcoming: false,
      showScheduleWindow: false,
      showCalendarView: false,
      showPlaylists: false,
      postIDModifying: 0,
      hidePosts: {},
      gifSrc: null,
    };
  }

  static fetch(match, location, options) {
    options.dispatch(UnreadActions.setUnreadPosts([]));
    const api = API();
    const promises = [];
    promises.push(options.dispatch(UnreadActions.resetFocus()));
    promises.push(
      api
        .getUnreadPosts()
        .then((response) => {
          if (response.ok) {
            options.dispatch(UnreadActions.setUnreadPosts(response.data));
          }
        })
        .catch((error) => console.warn(error)),
    );
    return Promise.all(promises);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.analytics.page('Unread');
    if (this.props.posts) {
      for (const post of this.props.posts) {
        this.setState({
          hidePosts: {
            ...this.state.hidePosts,
            [post.post_id]: false,
          },
        });
      }
    }
    if (this.props.callback) {
      this.setState({showModal: true});
    }
  }

  updateUnread() {
    return this.api
      .getUnreadPosts()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(UnreadActions.setUnreadPosts(response.data));
          for (const post of response.data) {
            this.setState({
              hidePosts: {
                ...this.state.hidePosts,
                [post.post_id]: false,
              },
            });
          }
        }
      })
      .catch((error) => console.warn(error));
  }

  handleStarred = (post_id) => {
    const trackingData = {
      user_id: this.props.user_id,
      postID: post_id,
    };
    window.analytics.track('Post Starred', trackingData);
    this.api
      .changeStarred(post_id)
      .then((response) => {
        if (response.ok) {
          this.updateUnread();
        }
      })
      .catch((error) => console.warn(error));
  };

  animateRead = (post_id, isPublic) => {
    const trackingData = {
      user_id: this.props.user_id,
      postID: post_id,
      makePublic: isPublic,
    };
    window.analytics.track('Post Marked Read', trackingData);
    this.setState({
      hidePosts: {
        ...this.state.hidePosts,
        [post_id]: true,
      },
    });
    this.reloadGif();
  };

  finishUpdate = (post_id) => {
    this.updateUnread();
    this.api
      .getSocialPosts()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setSocialPosts(response.data));
        }
      })
      .catch((error) => console.warn(error));

    this.api
      .getExplorePosts()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setExplorePosts(response.data));
        }
      })
      .catch((error) => console.warn(error));

    toast(niceMessages[Math.floor(Math.random() * niceMessages.length)], {
      containerId: 'N',
    });
    // toast('Successfully marked post as Read.', { containerId: 'N' })
    this.setState({
      postIDModifying: post_id,
      showPlaylists: true,
    });
  };

  handleRead = (post_id, isPublic) => {
    this.animateRead(post_id, isPublic);
    setTimeout(() => {
      this.api
        .markRead(post_id, isPublic)
        .then((response) => {
          if (response.ok) {
            this.finishUpdate(post_id);
          }
        })
        .catch((error) => {
          toast('An error occurred when adding to Unread.', {containerId: 'N'});
          console.warn(error);
          this.setState({
            hidePosts: {
              ...this.state.hidePosts,
              [post_id]: false,
            },
          });
        });
    }, 100);
  };

  handleDelete = (post_id) => {
    const trackingData = {
      user_id: this.props.user_id,
      postID: post_id,
    };
    this.confirmDelete();
    window.analytics.track('Post Deleted', trackingData);
    this.api
      .deleteUserPost(post_id)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(UnreadActions.deletePost(post_id));
          this.props.dispatch(FeedActions.deleteSinglePost(post_id));
          this.props.dispatch(PlaylistActions.deletePlaylistPost(post_id));
          toast('The post has been deleted.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        console.warn(error);
        toast('The post could not be deleted.', {containerId: 'N'});
      });
  };

  confirmDelete = () => {
    this.setState({
      showDelete: !this.state.showDelete,
    });
  };

  confirmRead = () => {
    this.setState({
      showRead: !this.state.showRead,
    });
  };

  closeScheduleWindow = () => {
    this.setState({
      showScheduleWindow: !this.state.showScheduleWindow,
    });
  };

  openCalendarView = () => {
    this.setState({
      showScheduleWindow: false,
      showCalendarView: true,
    });
  };

  closeCalendarView = () => {
    this.setState({
      showCalendarView: false,
    });
  };

  addPostToSchedule = (post_id, scheduleDate) => {
    const trackingData = {
      user_id: this.props.user_id,
      postID: post_id,
      date: scheduleDate,
    };
    window.analytics.track('Post Scheduled', trackingData);
    this.api
      .scheduleUnread(post_id, scheduleDate)
      .then((response) => {
        if (response.ok) {
          this.updateUnread().then(() =>
            toast('Successfully added post to your Schedule.', {
              containerId: 'N',
            }),
          );
        }
      })
      .catch((error) => {
        console.warn(error);
        toast('An error occurred when adding post to Schedule.', {
          containerId: 'N',
        });
      });
  };

  performRead = (user_id, post_id, isPublic) => {
    if (this.props.user_id !== user_id || isPublic) {
      this.handleRead(post_id, true);
    } else {
      this.confirmRead();
      this.setState({
        postIDModifying: post_id,
      });
    }
  };

  handleModalHide = () => {
    this.setState({showModal: false});
  };

  handleSelectCalendarTime = (time) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.api.setPreferredEventTime(time.toUpperCase(), timeZone);
    this.handleModalHide();
    this.setState({
      showScheduleWindow: true,
    });
  };

  querySelectCalendarTime = () => {
    this.api
      .queryPreferredEventTime()
      .then((response) => {
        const preferredSet = response.data.preferred_time;
        if (!preferredSet) {
          this.setState({showModal: true});
        } else {
          this.setState({
            showScheduleWindow: true,
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  removePostFromUnread = (postId) => {
    const trackingData = {
      postID: postId,
    };
    window.analytics.track('Post Removed From Unread', trackingData);
    this.api
      .removeFromUnread(postId)
      .then((response) => {
        if (response.ok) {
          this.updateUnread().then(() =>
            toast('Post removed from your Unread.', {containerId: 'N'}),
          );
        }
      })
      .catch((error) => {
        console.warn(error);
        toast('An error occurred when removing post from Unread.', {
          containerId: 'N',
        });
      });
  };

  /* DONT CARE ABOUT ALL OF THIS STUFF */

  reloadGif = async () => {
    this.setState({gifSrc: null});
    setTimeout(
      () => this.setState({gifSrc: require('../static/poof.gif')}),
      50,
    );
  };

  renderStarred = (post) => {
    if (!post.starred) {
      return (
        <StarBorderRoundedIcon
          onClick={() => {
            this.handleStarred(post.post_id);
          }}
          style={{color: 'gray', fontSize: '25px', cursor: 'pointer'}}
          className="my-auto"
        />
      );
    } else {
      return (
        <StarRoundedIcon
          onClick={() => {
            this.handleStarred(post.post_id);
          }}
          style={{color: 'gray', fontSize: '25px', cursor: 'pointer'}}
          className="my-auto"
        />
      );
    }
  };

  renderTitle = (post) => {
    if (post.native === false && post.embed_link === '') {
      return (
        <span
          style={{cursor: 'pointer'}}
          onClick={() => {
            window.analytics.track('Post Opened', post);
            window.open(post.link);
          }}>
          {post.title}
        </span>
      );
    } else {
      return (
        <span style={{cursor: 'pointer'}}>
          <Link
            style={{color: 'black', textDecoration: 'none'}}
            to={`/post/${post.post_id}`}>
            {post.title}
          </Link>
        </span>
      );
    }
  };

  renderPost = (post, node) => {
    if (this.state.hidePosts[post.post_id]) {
      return (
        <Image
          fluid
          key={post.post_id}
          className="mx-auto my-auto w-50"
          src={this.state.gifSrc}
          style={{
            height: 70,
            zIndex: 1,
          }}
        />
      );
    } else {
      return node;
    }
  };

  renderTimeRows = () => {
    // 8am, 1pm, 6pm, 10pm
    const times = ['Morning', 'Afternoon', 'Evening', 'Night'];
    return times.map((time) => (
      <Row key={time}>
        <Col className="my-1">
          <Button
            variant="light"
            className="btn-block"
            onClick={() => this.handleSelectCalendarTime(time)}>
            {time}
          </Button>
        </Col>
      </Row>
    ));
  };

  renderCalModal() {
    return (
      <Modal show={this.state.showModal} onHide={this.handleModalHide} centered>
        <Modal.Body>
          <p className="pt-3" style={{textAlign: 'center'}}>
            You have not yet set a time to schedule Wander readings. On your
            average day, when is a good time for you to read?
          </p>
          {this.renderTimeRows()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({
                showModal: false,
              });
            }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderPlaylists() {
    return (
      <Modal
        show={this.state.showPlaylists}
        onHide={() => this.setState({showPlaylists: false})}
        centered>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <p className="pt-3" style={{textAlign: 'center'}}>
                Want to add that post to a collection?
              </p>
              <PlaylistBar post_id={this.state.postIDModifying} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  renderPosts() {
    return (
      <FlipMove>
        {this.props.posts.map((post) => {
          if (post.clear_date !== null) {
            return null;
          }
          return this.renderPost(
            post,
            <Row
              className="shadow mx-0 py-2 mb-2"
              style={{fontWeight: '300', borderRadius: '5px'}}
              key={post.post_id}>
              <Col xs="auto">{this.renderStarred(post)}</Col>
              <Col
                xs={2}
                className="d-none d-md-block text-truncate text-left px-1 my-auto">
                <p className="my-auto text-truncate">{post.domain}</p>
              </Col>
              <Col className="text-left px-0 my-auto text-truncate">
                <p className="my-auto text-truncate">
                  {this.renderTitle(post)}
                </p>
              </Col>
              <Col xs="auto">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary shadow-none"
                    className="unreadToggler px-0 py-0">
                    <MoreHorizIcon
                      style={{color: 'gray', fontSize: '25px'}}
                      className="my-auto"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="shadow"
                    style={{border: 'none'}}
                    alignRight>
                    <Dropdown.Item
                      onClick={() => {
                        this.performRead(
                          post.user_id,
                          post.post_id,
                          post.public,
                        );
                      }}>
                      <Row>
                        <Col xs={12} className="unread-option py-0 px-0">
                          <BookmarkBorderIcon className="mb-1" />
                          <p className="my-auto">&nbsp;Mark Read</p>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.setState({
                          postIDModifying: post.post_id,
                        });
                        this.querySelectCalendarTime();
                      }}>
                      <Row>
                        <Col xs={12} className="schedule-option py-0 px-0">
                          <TodayIcon className="mb-1" />
                          <p className="my-auto">&nbsp;Schedule...</p>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        this.removePostFromUnread(post.post_id);
                      }}
                      className={
                        this.props.user_id !== post.user_id
                          ? 'mb-0'
                          : 'dropdown-item'
                      }>
                      <Row>
                        <Col xs={12} className="unread-option px-0 py-0">
                          <RemoveCircleIcon className="mb-1" />
                          <p className="my-auto">&nbsp;Remove</p>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="last"
                      hidden={this.props.user_id !== post.user_id}
                      onClick={() => {
                        this.setState({
                          showDelete: true,
                          postIDModifying: post.post_id,
                        });
                      }}>
                      <Row>
                        <Col xs={12} className="unread-option px-0 py-0">
                          <DeleteOutlineIcon className="mb-1" />{' '}
                          <p className="my-auto">&nbsp;Delete</p>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>,
          );
        })}
      </FlipMove>
    );
  }

  renderPast = () => {
    const rightNow = new Date();
    const day = rightNow.getDate();
    const month = rightNow.getMonth();
    const year = rightNow.getFullYear();
    const recentlyReadPosts = this.props.posts
      .filter((post) => {
        let recentlyFinished = false;
        if (post.clear_date !== null) {
          const clearDate = new Date(post.clear_date);
          recentlyFinished = clearDate.getTime() > rightNow.getTime();
        }
        return recentlyFinished;
      })
      .map((post) => (
        <Row className="my-1 text-left" key={post.post_id + 1}>
          <Col xs="auto" className="my-auto">
            <RadioButtonCheckedIcon style={{color: Colors.primary}} />
          </Col>
          <Col className="text-truncate pl-0 my-auto">
            {this.renderTitle(post)}
          </Col>
        </Row>
      ));
    const pastDuePosts = this.props.posts
      .filter((post) => {
        let pastDue = false;
        if (post.scheduled !== null) {
          const postScheduled = new Date(post.scheduled);
          if (postScheduled.getFullYear() < year) pastDue = true;
          else if (
            postScheduled.getFullYear() === year &&
            postScheduled.getMonth() < month
          )
            pastDue = true;
          else if (
            postScheduled.getFullYear() === year &&
            postScheduled.getMonth() === month &&
            postScheduled.getDate() < day
          )
            pastDue = true;
        }
        return pastDue;
      })
      .map((post) => (
        <Row className="my-1 text-left" key={post.post_id + 1}>
          <Col xs="auto" className="my-auto">
            <RadioButtonUncheckedIcon />
          </Col>
          <Col className="text-truncate pl-0 my-auto">
            {this.renderTitle(post)}
          </Col>
        </Row>
      ));
    const renderArray = [];
    recentlyReadPosts.forEach((post) => {
      renderArray.push(post);
    });
    pastDuePosts.forEach((post) => {
      renderArray.push(post);
    });
    if (renderArray.length === 0) {
      return null;
    } else if (renderArray.length < 6) {
      return <div>{renderArray}</div>;
    } else {
      if (this.state.showAllPast) {
        return (
          <div>
            {renderArray}
            <Row className="my-1 text-left">
              <Col xs={12} className="text-left">
                <span style={{cursor: 'pointer'}}>
                  <ArrowDropUpIcon
                    onClick={() => {
                      this.setState({
                        showAllPast: false,
                      });
                    }}
                  />
                </span>
              </Col>
            </Row>
          </div>
        );
      }
      return (
        <div>
          {renderArray.slice(0, 5)}
          <Row className="my-1 text-left">
            <Col xs={12} className="text-left">
              <span style={{cursor: 'pointer'}}>
                <ArrowDropDownIcon
                  onClick={() => {
                    this.setState({
                      showAllPast: true,
                    });
                  }}
                />
              </span>
            </Col>
          </Row>
        </div>
      );
    }
  };

  renderToday = () => {
    const rightNow = new Date();
    const day = rightNow.getDate();
    const month = rightNow.getMonth();
    const year = rightNow.getFullYear();
    const todayPosts = this.props.posts
      .filter((post) => {
        const postScheduled = new Date(post.scheduled);
        return (
          postScheduled.getFullYear() === year &&
          postScheduled.getMonth() === month &&
          postScheduled.getDate() === day
        );
      })
      .map((post) => (
        <Row className="my-1 text-left" key={post.post_id + 1}>
          <Col xs="auto" className="my-auto">
            <RadioButtonUncheckedIcon />
          </Col>
          <Col className="text-truncate pl-0 my-auto">
            {this.renderTitle(post)}
          </Col>
        </Row>
      ));
    if (todayPosts.length === 0) {
      return null;
    } else if (todayPosts.length < 6) {
      return <div>{todayPosts}</div>;
    } else {
      if (this.state.showAllToday) {
        return (
          <div>
            {todayPosts}
            <Row className="my-1 text-left">
              <Col xs={12} className="text-left">
                <span style={{cursor: 'pointer'}}>
                  <ArrowDropUpIcon
                    onClick={() => {
                      this.setState({
                        showAllToday: false,
                      });
                    }}
                  />
                </span>
              </Col>
            </Row>
          </div>
        );
      }
      return (
        <div>
          {todayPosts.slice(0, 5)}
          <Row className="my-1 text-left">
            <Col xs={12} className="text-left">
              <span style={{cursor: 'pointer'}}>
                <ArrowDropDownIcon
                  onClick={() => {
                    this.setState({
                      showAllToday: true,
                    });
                  }}
                />
              </span>
            </Col>
          </Row>
        </div>
      );
    }
  };

  renderUpcoming = () => {
    const rightNow = new Date();
    const day = rightNow.getDate();
    const month = rightNow.getMonth();
    const year = rightNow.getFullYear();
    const upcomingPosts = this.props.posts
      .filter((post) => {
        const postScheduled = new Date(post.scheduled);
        let upcoming = false;
        if (postScheduled.getFullYear() > year) upcoming = true;
        else if (
          postScheduled.getFullYear() === year &&
          postScheduled.getMonth() > month
        )
          upcoming = true;
        else if (
          postScheduled.getFullYear() === year &&
          postScheduled.getMonth() === month &&
          postScheduled.getDate() > day
        )
          upcoming = true;
        return upcoming;
      })
      .map((post) => (
        <Row className="my-1 text-left" key={post.post_id + 1}>
          <Col xs="auto" className="my-auto">
            <RadioButtonUncheckedIcon />
          </Col>
          <Col className="text-truncate pl-0 my-auto">
            {this.renderTitle(post)}
          </Col>
        </Row>
      ));
    if (upcomingPosts.length === 0) {
      return null;
    } else if (upcomingPosts.length < 6) {
      return <div>{upcomingPosts}</div>;
    } else {
      if (this.state.showAllUpcoming) {
        return (
          <div>
            {upcomingPosts}
            <Row className="my-1 text-left">
              <Col xs={12} className="text-left">
                <span style={{cursor: 'pointer'}}>
                  <ArrowDropUpIcon
                    onClick={() => {
                      this.setState({
                        showAllUpcoming: false,
                      });
                    }}
                  />
                </span>
              </Col>
            </Row>
          </div>
        );
      }
      return (
        <div>
          {upcomingPosts.slice(0, 5)}
          <Row className="my-1 text-left">
            <Col xs={12} className="text-left">
              <span style={{cursor: 'pointer'}}>
                <ArrowDropDownIcon
                  onClick={() => {
                    this.setState({
                      showAllUpcoming: true,
                    });
                  }}
                />
              </span>
            </Col>
          </Row>
        </div>
      );
    }
  };

  render() {
    if (!this.props.stillFetching) {
      if (this.props.posts && this.props.posts.length > 0) {
        return (
          <div>
            {/* <Row className='mx-0 py-4 d-none d-lg-block'>
              <Col xs={12} className='text-center'>
                <h1 style={{ fontWeight: '300', color: Colors.primary }} className='unreadHeader'>Unread</h1>
              </Col>
            </Row> */}
            <Row className="mt-5 mx-0 pt-5 pt-lg-0 pb-4 pb-lg-0 justify-content-center">
              <Col sm={11} xs={12}>
                <Row>
                  <Col
                    lg={{span: 8, order: 1}}
                    xs={{span: 12, order: 2}}
                    className="text-center">
                    <Row>
                      <Col xs={12} className="pb-3">
                        <h3
                          style={{fontWeight: '300', color: Colors.primary}}
                          className="unreadHeader">
                          Unread Posts
                        </h3>
                      </Col>
                    </Row>
                    {this.renderPosts()}
                    <PrivatePost
                      show={this.state.showRead}
                      post_id={this.state.postIDModifying}
                      onHide={this.confirmRead}
                      markRead={this.handleRead}
                      animate={this.animateRead}
                      finish={this.finishUpdate}
                      errorReset={(post_id) => {
                        this.setState({
                          hidePosts: {
                            ...this.state.hidePosts,
                            [post_id]: false,
                          },
                        });
                      }}
                    />
                    <DeletePost
                      show={this.state.showDelete}
                      onHide={this.confirmDelete}
                      performDelete={() => {
                        this.handleDelete(this.state.postIDModifying);
                      }}
                    />
                    <SchedulePost
                      show={this.state.showScheduleWindow}
                      post_id={this.state.postIDModifying}
                      onHide={this.closeScheduleWindow}
                      onOther={this.openCalendarView}
                      performSchedule={this.addPostToSchedule}
                    />
                    <CalendarView
                      show={this.state.showCalendarView}
                      post_id={this.state.postIDModifying}
                      onHide={this.closeCalendarView}
                      performSchedule={this.addPostToSchedule}
                    />
                  </Col>
                  <Col
                    lg={{span: 4, order: 2}}
                    xs={{span: 12, order: 1}}
                    style={{fontWeight: '300'}}
                    className="text-center pl-lg-4">
                    <Row>
                      <Col xs={12}>
                        <Row>
                          <Col xs={12} className="pb-3">
                            <h3
                              style={{fontWeight: '300', color: Colors.primary}}
                              className="unreadHeader">
                              Scheduled Reads
                            </h3>
                          </Col>
                        </Row>
                        <Row className="mb-1 text-left">
                          <Col xs={12}>
                            <Row>
                              <Col xs={12}>
                                <h5
                                  style={{fontWeight: '300'}}
                                  className="mb-1">
                                  Pasts
                                </h5>
                              </Col>
                            </Row>
                            {this.renderPast()}
                          </Col>
                        </Row>
                        <Row className="mb-1 text-left">
                          <Col xs={12}>
                            <Row>
                              <Col xs={12}>
                                <h5
                                  style={{fontWeight: '300'}}
                                  className="mb-1">
                                  Today
                                </h5>
                              </Col>
                            </Row>
                            {this.renderToday()}
                          </Col>
                        </Row>
                        <Row className="mb-4 mb-lg-0 text-left">
                          <Col xs={12}>
                            <Row>
                              <Col xs={12}>
                                <h5
                                  style={{fontWeight: '300'}}
                                  className="mb-1">
                                  Upcoming
                                </h5>
                              </Col>
                            </Row>
                            {this.renderUpcoming()}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {this.renderCalModal()}
            {this.renderPlaylists()}
          </div>
        );
      } else {
        return (
          <div>
            <Row className="mx-0 pt-4">
              <Col xs={12} className="text-center">
                <h1 style={{fontWeight: '300'}}>
                  Add some posts to your Unread section!
                </h1>
              </Col>
            </Row>
          </div>
        );
      }
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  user_id: state.user.id,
  posts: state.unread.posts,
  stillFetching: state.config.isFetching,
  calendarPrompted: state.user.calendarPrompted,
});
export default connect(mapStateToProps)(Unread);
