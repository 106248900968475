import React, {Component} from 'react';
import {Modal, Row, Col} from 'react-bootstrap';
import {toast} from 'react-toastify';
import API from '../Services/API';
import Colors from '../Config/Colors';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FeedActions from '../Redux/FeedRedux';
import UserActions from '../Redux/UserRedux';
import {connect} from 'react-redux';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import {Multiselect} from 'multiselect-react-dropdown';
import './Styles/CreatePost.css';

type Props = {
  show: boolean,
  postLink: string,
  changeLink: (event: any) => void,
  goBack: () => void,
  dismiss: () => void,
};

type State = {
  selectedUsers: [],
  selectedPlaylists: [],
  selectedTags: [],
  showAdd: boolean,
  percent: string,
  message: string,
  title: string,
  titleScreen: Boolean,
  latestFollowingUsers: [],
};

class CreatePost extends Component<Props, State> {
  api: any;
  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      selectedUsers: [],
      selectedTags: [],
      selectedPlaylists: [],
      showAdd: false,
      percent: '0%',
      message: '',
      title: '',
      returnedLinkInfo: {},
      titleScreen: false,
      latestFollowingUsers: [],
    };
  }

  componentDidMount() {
    this.api
      .getFollowingUsers()
      .then((response) => {
        if (response.ok) {
          this.setState({latestFollowingUsers: response.data});
        }
      })
      .catch((error) => console.warn(error));
  }

  updateAllPosts = () => {
    this.api
      .getSocialPosts()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setSocialPosts(response.data));
        }
      })
      .catch((error) => console.warn(error));

    this.api
      .getExplorePosts()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setExplorePosts(response.data));
        }
      })
      .catch((error) => console.warn(error));
  };

  resetState = () => {
    this.setState({
      selectedUsers: [],
      selectedTags: [],
      selectedPlaylists: [],
      showAdd: false,
      percent: '0%',
      message: '',
      title: '',
      returnedLinkInfo: {},
      titleScreen: false,
    });
  };

  createDismiss = () => {
    this.props.dismiss();
    this.resetState();
  };

  createBack = () => {
    this.props.goBack();
    this.resetState();
  };

  handleSelect = (selectedList, selectedItem) => {
    this.setState({
      selectedUsers: this.state.selectedUsers.concat(selectedItem),
    });
  };

  handleRemove = (selectedList, selectedItem) => {
    this.setState({
      selectedUsers: this.state.selectedUsers.filter(
        (user) => user.user_id !== selectedItem.user_id,
      ),
    });
  };

  handleSelectPlaylist = (selectedList, selectedItem) => {
    window.analytics.track('Post Added To Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.setState({
      selectedPlaylists: selectedList,
    });
  };

  handleRemovePlaylist = (selectedList, selectedItem) => {
    window.analytics.track('Post Removed From Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.setState({
      selectedPlaylists: selectedList,
    });
  };

  onTagPress = (tags, tag) => {
    if (this.state.selectedTags.includes(tag.tag_id)) {
      window.analytics.track(
        'Tag Unselected',
        this.props.tags.filter((tag1) => tag1.tag_id === tag.tag_id),
      );
      this.setState({
        selectedTags: this.state.selectedTags.filter(
          (selectedTag) => selectedTag !== tag.tag_id,
        ),
      });
    } else {
      window.analytics.track(
        'Tag Selected',
        this.props.tags.filter((tag1) => tag1.tag_id === tag.tag_id),
      );
      this.setState({
        selectedTags: this.state.selectedTags.concat(tag.tag_id),
      });
    }
  };

  handleChangeTitle = (event: any) => {
    this.setState({title: event.target.value});
  };

  handleSubmit = () => {
    if (!this.state.titleScreen) {
      if (
        this.props.postLink.length > 0 &&
        this.state.selectedTags.length > 0
      ) {
        this.setState({
          showAdd: true,
          message: 'Creating New Post...',
          percent: '50%',
        });
        window.analytics.track('Post Created', this.state);
        this.api
          .createPost(
            this.props.postLink,
            this.state.selectedTags,
            this.state.selectedUsers.map((user) => user.user_id),
          )
          .then((response) => {
            if (response.ok && response.status === 201) {
              this.setState({
                message: 'New Public Post Created!',
                percent: '100%',
              });
              this.updateAllPosts();
              toast('Your post has been created.', {containerId: 'N'});
              this.state.selectedPlaylists.map((playlist) =>
                this.api
                  .addToPlaylist(playlist.playlist_id, response.data.post_id)
                  .catch((error) => console.warn(error)),
              );
              this.createDismiss();
              this.api
                .getMainUserPlaylists()
                .then((response) => {
                  if (response.ok) {
                    this.props.dispatch(
                      UserActions.setPlaylists(response.data),
                    );
                  }
                })
                .catch((error) => console.warn(error));
            } else if (response.ok) {
              this.setState({
                message: 'Post Needs Title',
                percent: '0%',
                returnedLinkInfo: response.data,
                titleScreen: true,
              });
            } else {
              this.setState({
                message: 'Invalid post link',
                percent: '0%',
              });
            }
          })
          .catch((error) => {
            console.warn(error);
            toast('An error occurred when creating your post.', {
              containerId: 'N',
            });
            this.createDismiss();
          });
      } else if (this.props.postLink.length === 0) {
        this.setState({
          showAdd: true,
          message: 'Must enter a link',
        });
      } else {
        this.setState({
          showAdd: true,
          message: 'Must select at least one tag',
        });
      }
    } else {
      if (this.state.title.length > 0) {
        this.setState({
          message: 'Creating Post with Title...',
          percent: '50%',
        });
        window.analytics.track('Post with Title Created', this.state);
        this.api
          .createPostWithTitle(
            this.state.title,
            this.state.returnedLinkInfo.link,
            this.state.returnedLinkInfo.embed_link,
            this.state.returnedLinkInfo.domain,
            this.state.returnedLinkInfo.tag_ids,
            this.state.selectedUsers.map((user) => user.user_id),
          )
          .then((response) => {
            if (response.ok) {
              this.setState({
                message: 'New Public Post Created!',
                percent: '100%',
              });
              this.updateAllPosts();
              toast('Your post has been created.', {containerId: 'N'});
              this.state.selectedPlaylists.map((playlist) =>
                this.api
                  .addToPlaylist(playlist.playlist_id, response.data.post_id)
                  .catch((error) => console.warn(error)),
              );
              this.createDismiss();
              this.api
                .getMainUserPlaylists()
                .then((response) => {
                  if (response.ok) {
                    this.props.dispatch(
                      UserActions.setPlaylists(response.data),
                    );
                  }
                })
                .catch((error) => console.warn(error));
            } else {
              this.setState({
                message: 'Unable to Create Post',
                percent: '0%',
              });
            }
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              message: 'Unable to Create Post',
              percent: '0%',
            });
          });
      } else {
        this.setState({
          message: 'Post must have a title',
        });
      }
    }
  };

  renderBack = () => {
    if (!this.state.titleScreen) {
      return (
        <ArrowBackIosRoundedIcon
          style={{cursor: 'pointer', color: 'gray'}}
          onClick={this.createBack}
        />
      );
    }
    return (
      <ArrowBackIosRoundedIcon style={{visibility: 'hidden', color: 'gray'}} />
    );
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.createDismiss} centered>
        <Modal.Body className={this.state.showAdd ? 'px-0 pb-0' : 'px-0'}>
          <Row className="px-3">
            <Col xs={12}>
              <Row className="pb-3">
                <Col xs="auto" className="text-left">
                  {this.renderBack()}
                </Col>
                <Col className="text-center">
                  <h5 style={{color: 'gray'}}>New Post</h5>
                </Col>
                <Col xs="auto" className="text-right">
                  <CloseRoundedIcon
                    style={{cursor: 'pointer', color: 'gray'}}
                    onClick={this.createDismiss}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              className={this.state.titleScreen ? 'd-none' : 'showPost'}>
              <div className="form__group field">
                <input
                  type="input"
                  onChange={this.props.changeLink}
                  className="form__field"
                  value={this.props.postLink}
                  placeholder="Paste Link"
                  name="addLink"
                  style={{zIndex: '1'}}
                />
                <label
                  htmlFor="addLink"
                  className="form__label"
                  style={{zIndex: '0'}}>
                  Paste Link
                </label>
              </div>
            </Col>
            <Col
              xs={12}
              className={this.state.titleScreen ? 'showTitle' : 'd-none'}>
              <div className="form__group field">
                <input
                  type="input"
                  onChange={this.handleChangeTitle}
                  className="form__field"
                  value={this.state.title}
                  placeholder="Add Title"
                  name="addTitle"
                  style={{zIndex: '1'}}
                />
                <label
                  htmlFor="addTitle"
                  className="form__label"
                  style={{zIndex: '0'}}>
                  Add Title
                </label>
              </div>
            </Col>
            <Col
              xs={12}
              className={this.state.titleScreen ? 'd-none' : 'showPost pt-3'}>
              <Multiselect
                options={this.state.latestFollowingUsers}
                onSelect={this.handleSelect}
                onRemove={this.handleRemove}
                displayValue="full_name"
                placeholder="Select Wanderers to shout-out"
                id="shoutout-select"
                avoidHighlightFirstOption
                hidePlaceholder
                style={{
                  optionContainer: {border: 'none'},
                  chips: {whiteSpace: 'normal', background: Colors.primary},
                  searchBox: {
                    border: 'none',
                    borderBottom: '1px solid #9b9b9b',
                    borderRadius: '0px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  },
                }}
              />
            </Col>
          </Row>
          <Row className={this.state.titleScreen ? 'd-none' : 'showPost px-3'}>
            <Col xs={8} className="pt-4">
              <Multiselect
                options={this.props.tags}
                onSelect={this.onTagPress}
                onRemove={this.onTagPress}
                displayValue="text"
                placeholder="Search Tags"
                avoidHighlightFirstOption
                hidePlaceholder
                style={{
                  optionContainer: {border: 'none'},
                  chips: {whiteSpace: 'normal', background: Colors.primary},
                  searchBox: {background: '#eeeeee', border: 'none'},
                }}
              />
            </Col>
          </Row>
          <Row className={this.state.titleScreen ? 'd-none' : 'showPost px-3'}>
            <Col xs={8} className="pt-3">
              <Multiselect
                options={this.props.playlists}
                onSelect={this.handleSelectPlaylist}
                onRemove={this.handleRemovePlaylist}
                displayValue="title"
                placeholder="Search Collections"
                avoidHighlightFirstOption
                hidePlaceholder
                style={{
                  optionContainer: {border: 'none'},
                  chips: {whiteSpace: 'normal', background: Colors.primary},
                  searchBox: {background: '#eeeeee', border: 'none'},
                }}
              />
            </Col>
          </Row>
          <Row className="px-3 pt-3">
            <Col xs={12} className="text-right">
              <SendRoundedIcon
                fontSize="large"
                onClick={this.handleSubmit}
                style={{cursor: 'pointer', color: Colors.primary}}
              />
            </Col>
          </Row>
          <Row className={this.state.showAdd ? 'd-block mt-2 px-0' : 'd-none'}>
            <Col xs={12} className="text-center">
              <p className="mb-0" style={{fontWeight: '400', color: 'gray'}}>
                {this.state.message}
              </p>
              <hr
                style={{
                  borderColor: Colors.primary,
                  backgroundColor: Colors.primary,
                  width: this.state.percent,
                }}
                className={
                  this.state.percent === '0%'
                    ? 'notLoading pb-0 px-0 mx-0 mb-0'
                    : 'loading pb-0 px-0 mx-0 mb-0'
                }
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  tags: state.feed.tags,
  playlists: state.user.playlists,
});
export default connect(mapStateToProps)(CreatePost);
