// @flow

import React, {Component} from 'react';
import {
  Card,
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import API from '../Services/API';
import {connect} from 'react-redux';
import ProfileActions from '../Redux/ProfileRedux';
import UserActions from '../Redux/UserRedux';
import FollowButton from '../Components/FollowButton';
import {Link} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import defaultPic from '../static/placeholder.png';
import './Styles/Profile.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {toast} from 'react-toastify';
import Edit from '../Components/Edit.js';
import AddPlaylist from '../Components/AddPlaylist.js';
import ShowFollowing from '../Components/ShowFollowing.js';
import ShowFollowers from '../Components/ShowFollowers.js';

import TagComponent from '../Components/Tag';
import KnowledgeIcon from '../static/knowledge_icon.png';
import defaultWander from '../static/DefaultWander.png';

type Props = {
  user: {
    name: string,
    id: number,
  },
  profileUser: {
    id: number,
    name: string,
    userName: string,
    playlists: {}[],
    following: boolean,
    latestFive: {}[],
    numPosts: number,
    numFollowers: number,
    numFollowing: number,
    bio: string,
    profilePic: string,
    points: number,
    top_tags: [],
  },
  stillFetching: boolean,
  dispatch: ({}) => void,
};
type State = {
  showAddPlaylist: boolean,
  showEditMenu: boolean,
};

class Profile extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      showAddPlaylist: false,
      showEditMenu: false,
      showFollowing: false,
      showFollowers: false,
      topTags: [],
      alreadyRendered: false,
    };
  }

  componentDidMount() {
    // Segment pageview call
    window.scrollTo(0, 0);
    window.analytics.page('Profile');
    this.setState({alreadyRendered: false});
  }

  componentDidUpdate() {
    // Segment track user
    window.analytics.identify(this.props.user_id, {
      name: this.props.name,
      username: this.props.username,
    });
  }

  static fetch(match, location, options) {
    options.dispatch(ProfileActions.setProfileInfo(-1, '', '', [], null, null));
    options.dispatch(ProfileActions.setFollowStatus(false));
    options.dispatch(ProfileActions.setLatestPosts([]));
    options.dispatch(ProfileActions.setProfileStats(0, 0, 0));

    const api = API();
    const promises = [];
    promises.push(
      api.getUser(match.params.id).then((response) => {
        if (response.ok) {
          // eslint-disable-next-line camelcase
          const {
            user_id,
            full_name,
            username,
            playlists,
            bio,
            profile_pic,
            points,
          } = response.data;
          options.dispatch(
            ProfileActions.setProfileInfo(
              user_id,
              full_name,
              username,
              playlists,
              bio,
              profile_pic,
              points,
            ),
          );
        }
      }),
    );
    promises.push(
      api.getFollowStatus(match.params.id).then((response) => {
        if (response.ok) {
          options.dispatch(
            ProfileActions.setFollowStatus(response.data.following),
          );
        }
      }),
    );
    promises.push(
      api.getLatestPosts(match.params.id).then((response) => {
        if (response.ok) {
          const latestPosts = [];
          for (let i = 0; i < response.data.length; i++) {
            latestPosts.push([
              response.data[i].post_id,
              response.data[i].title,
              response.data[i].link,
            ]);
          }
          options.dispatch(ProfileActions.setLatestPosts(latestPosts));
        }
      }),
    );
    promises.push(
      api.getProfileStats(match.params.id).then((response) => {
        if (response.ok) {
          options.dispatch(
            ProfileActions.setProfileStats(
              response.data.posts,
              response.data.followers,
              response.data.following,
            ),
          );
        }
      }),
    );
    promises.push(
      api.getProfileFollowing(match.params.id).then((response) => {
        if (response.ok) {
          options.dispatch(
            ProfileActions.setProfileFollowing(response.data.following),
          );
          if (response.data.followers) {
            options.dispatch(
              UserActions.setUserFollowers(response.data.followers),
            );
          }
        }
      }),
    );
    promises.push(
      api.setTopTags(match.params.id).then((response) => {
        if (response.ok) {
          options.dispatch(ProfileActions.setTopTags(response.data));
        }
      }),
    );
    return Promise.all(promises);
  }

  handleCreatePlaylist = (event: any) => {
    event.preventDefault();
    const playlistTitleTrimmed = event.target.elements.newPlaylist.value.trim();
    if (playlistTitleTrimmed === '') {
      toast('Your collection title was invalid.', {containerId: 'N'});
      this.handlePlaylistClose();
      return;
    }
    // Segment pageview call
    window.analytics.track('Collection Created', {playlistTitleTrimmed});
    this.api
      .createPlaylist(playlistTitleTrimmed)
      .then((response) => {
        if (response.ok) {
          this.updateUserPlaylists();
          this.handlePlaylistClose();
          toast('Your collection was created.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        console.warn(error);
        this.handleEditClose();
        toast('Your collection could not be created.', {containerId: 'N'});
      });
  };

  handlePlaylistOpen = () => {
    this.setState({
      showAddPlaylist: true,
    });
  };

  handlePlaylistClose = () => {
    this.setState({
      showAddPlaylist: false,
    });
  };

  updateUserPlaylists = () => {
    this.api
      .getUserPlaylists(this.props.user.id)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(
            ProfileActions.setProfilePlaylists(response.data),
          );
        }
      })
      .catch((error) => console.warn(error));

    this.api
      .getMainUserPlaylists()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(UserActions.setPlaylists(response.data));
        }
      })
      .catch((error) => console.warn(error));
  };

  getTags = () => {
    const tags = [];
    for (let i = 0; i < this.props.profileUser.top_tags.length; i++) {
      if (i === 4) {
        break;
      }
      tags.push(
        <p style={{display: 'inline-block', justifyContent: 'space-between'}}>
          <TagComponent
            className="mr-1 ml-0 my-auto"
            id={this.props.profileUser.top_tags[i].tag_id}
            key={this.props.profileUser.top_tags[i].tag_id}
            color={this.props.profileUser.top_tags[i].hex_bg_color}
            text={this.props.profileUser.top_tags[i].text}
            textColor={this.props.profileUser.top_tags[i].hex_text_color}
          />
        </p>,
      );
    }
    return tags;
  };

  handleEditOpen = () => {
    this.setState({
      showEditMenu: true,
    });
  };

  handleEditClose = () => {
    this.setState({
      showEditMenu: false,
    });
  };

  handleShowFollowingClick = () => {
    this.setState({
      showFollowing: !this.state.showFollowing,
    });
  };

  handleShowFollowersClick = () => {
    this.setState({
      showFollowers: !this.state.showFollowers,
    });
  };

  handleEditSubmit = (event: any) => {
    event.preventDefault();
    if (event.target.elements.newProfPic.files.length > 0) {
      if (
        event.target.elements.newProfPic.files[0].type === 'image/jpeg' ||
        event.target.elements.newProfPic.files[0].type === 'image/png' ||
        event.target.elements.newProfPic.files[0].type === 'image/gif'
      ) {
        this.api
          .setProfPic(event.target.elements.newProfPic.files[0])
          .then((response) => {
            if (response.ok) {
              this.props.dispatch(
                ProfileActions.setProfilePicture(response.data.profile_pic),
              );
              this.props.dispatch(
                UserActions.setUserImg(response.data.profile_pic),
              );
              toast('Your profile picture has been changed.', {
                containerId: 'N',
              });
            }
          })
          .catch((error) => {
            console.warn(error);
            toast('Your profile picture could not be changed.', {
              containerId: 'N',
            });
          });
      }
    }
    const bioTrimmed = event.target.elements.newBio.value.trim();
    // Segment pageview call
    window.analytics.track('Bio Set', bioTrimmed);
    this.api
      .editBio(bioTrimmed)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(ProfileActions.setBio(bioTrimmed));
          this.handleEditClose();
          toast('Your bio has been edited.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        console.warn(error);
        this.handleEditClose();
        toast('Your bio could not be edited.', {containerId: 'N'});
      });
  };

  renderPlaylists() {
    return this.props.profileUser.playlists.map<any>((playlist: any) => {
      let coverImage = defaultPic;
      if (playlist.cover) {
        coverImage = playlist.cover;
      } else if (!playlist.cover && playlist.playlist_length > 0) {
        coverImage = defaultWander;
      }

      return (
        <Col
          md={4}
          sm={6}
          xs={12}
          className="text-center"
          key={playlist.playlist_id}>
          <div id="playlist-card" className="px-0">
            <Card className="shadow-sm" bg="light" text="dark">
              <Link
                style={{color: 'black'}}
                to={`/collection/${playlist.playlist_id}`}>
                <Card.Img
                  className="playlistIMG"
                  variant="top"
                  src={coverImage}
                />
                <Card.Title
                  className="text-left px-2 my-auto py-2 font-weight-light text-truncate"
                  style={{fontSize: 15}}>
                  {' '}
                  {playlist.title}{' '}
                </Card.Title>
              </Link>
            </Card>
          </div>
        </Col>
      );
    });
  }

  renderPoints() {
    return (
      <p className="mt-1 mb-0" style={{fontSize: 17, display: 'inline-block'}}>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tip">
              Knowledge points show your dedication to reading the content that
              matters to you on Wander. You can earn points by reading items
              you've marked unread.
            </Tooltip>
          }>
          <span>
            <img
              src={KnowledgeIcon}
              alt=""
              style={{
                width: 'auto',
                height: '20px',
                display: 'inline-block',
                justifyContent: 'space-between',
                verticalAlign: 'top',
              }}
            />{' '}
            {this.props.profileUser.points} Knowledge{' '}
            {this.props.profileUser.points === 1 ? 'Point' : 'Points'}
          </span>
        </OverlayTrigger>
      </p>
    );
  }

  renderProfile = () => {
    if (this.props.profileUser.id === this.props.user.id) {
      const latest = [];
      if (this.props.profileUser.latestFive.length) {
        for (let i = 0; i < this.props.profileUser.latestFive.length; i++) {
          latest.push(
            <Row
              className="text-left"
              key={this.props.profileUser.latestFive[i][0]}>
              <Col xs={12} className="font-weight-light">
                <p style={{fontSize: 15}}>
                  {this.props.profileUser.latestFive[i][0] ? (
                    <Link
                      style={{color: 'black'}}
                      to={`/post/${this.props.profileUser.latestFive[i][0]}`}>
                      {this.props.profileUser.latestFive[i][1]}
                    </Link>
                  ) : (
                    <span
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        // Segment pageview call
                        window.analytics.track(
                          'Post Opened',
                          this.props.profileUser.latestFive[i],
                        );
                        window.open(this.props.profileUser.latestFive[i][2]);
                      }}>
                      {this.props.profileUser.latestFive[i][1]}
                    </span>
                  )}
                </p>
              </Col>
            </Row>,
          );
        }
      }
      let imageLink = defaultWander;
      if (this.props.profileUser.profilePic !== null) {
        imageLink = this.props.profileUser.profilePic;
      }
      let bioText = <br />;
      if (
        this.props.profileUser.bio !== null &&
        this.props.profileUser.bio.trim() !== ''
      ) {
        bioText = this.props.profileUser.bio;
      }
      return (
        <div>
          <Container fluid>
            <Row className="justify-content-center">
              <div className="col-xl-11 col-lg-8 col-md-11 col-sm-12 col-xs-12 text-center">
                <Row className="justify-content-center my-4 my-md-5">
                  <Col md={4} xs={12} className="pb-3 pb-md-0 my-auto">
                    <Image
                      src={imageLink}
                      className="profileIMG"
                      roundedCircle
                      height="170"
                      width="170"
                    />
                  </Col>
                  <Col md={8} xs={11} className="pl-xl-0">
                    <Row>
                      <Col xs={7} className="text-left font-weight-normal">
                        <p className="my-0" style={{fontSize: 19}}>
                          @{this.props.profileUser.userName} | {this.getTags()}
                        </p>
                        <p className="my-0 profName">
                          {this.props.profileUser.name}
                        </p>
                      </Col>
                      <Col xs={5} className="text-right">
                        <Button
                          variant="outline-primary shadow-none editBtn"
                          onClick={this.handleEditOpen}>
                          Edit
                        </Button>
                        <Edit
                          show={this.state.showEditMenu}
                          changeBio={this.handleEditSubmit}
                          cancel={this.handleEditClose}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        className="text-left font-weight-light text-muted mt-3">
                        <p
                          className="my-0 text-truncate"
                          style={{fontSize: 18}}>
                          {bioText}
                        </p>
                        <p className="mt-1 mb-0" style={{fontSize: 17}}>
                          {this.renderPoints()} &nbsp;
                          {this.props.profileUser.numPosts} Posts &nbsp;{' '}
                          <span
                            className="text-primary"
                            style={{cursor: 'pointer'}}
                            onClick={this.handleShowFollowingClick}>
                            {this.props.profileUser.numFollowing} Following{' '}
                          </span>
                          &nbsp;{' '}
                          <span
                            className="text-primary"
                            style={{cursor: 'pointer'}}
                            onClick={this.handleShowFollowersClick}>
                            {this.props.profileUser.numFollowers} Followers
                          </span>
                        </p>
                        <ShowFollowing
                          show={this.state.showFollowing}
                          close={this.handleShowFollowingClick}
                        />
                        <ShowFollowers
                          show={this.state.showFollowers}
                          close={this.handleShowFollowersClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="col-xl-12 col-lg-9 col-md-11 col-sm-12 col-xs-12 text-center">
                <Row className="justify-content-center">
                  <Col xl={6} xs={6}>
                    <Row>
                      <Col xs={4} className="text-left">
                        <p className="secTitles">Collections</p>
                      </Col>
                      <Col
                        xs={8}
                        className="justify-content-end addPlaylist font-weight-light">
                        <p
                          className="text-primary d-none d-md-block"
                          style={{fontSize: 19}}>
                          Add Collection &#8202;
                        </p>
                        <span
                          style={{cursor: 'pointer'}}
                          onClick={this.handlePlaylistOpen}>
                          <AddCircleIcon className="text-primary" />
                        </span>
                        <AddPlaylist
                          show={this.state.showAddPlaylist}
                          cancel={this.handlePlaylistClose}
                          newPlaylist={this.handleCreatePlaylist}
                        />
                      </Col>
                    </Row>
                    <Row>{this.renderPlaylists()}</Row>
                  </Col>
                  <Col xl={6} xs={5}>
                    <Row>
                      <Col xs={12} className="text-left">
                        <p className="secTitles">Latest Posts</p>
                      </Col>
                    </Row>
                    {latest}
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else {
      const latest = [];
      for (let i = 0; i < this.props.profileUser.latestFive.length; i++) {
        latest.push(
          <Row
            className="text-left"
            key={this.props.profileUser.latestFive[i][0]}>
            <Col xs={12} className="font-weight-light">
              <p style={{fontSize: 15}}>
                {this.props.profileUser.latestFive[i][0] ? (
                  <Link
                    style={{color: 'black'}}
                    to={`/post/${this.props.profileUser.latestFive[i][0]}`}>
                    {this.props.profileUser.latestFive[i][1]}
                  </Link>
                ) : (
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      // Segment pageview call
                      window.analytics.track(
                        'Post Opened',
                        this.props.profileUser.latestFive[i],
                      );
                      window.open(this.props.profileUser.latestFive[i][2]);
                    }}>
                    {this.props.profileUser.latestFive[i][1]}
                  </span>
                )}
              </p>
            </Col>
          </Row>,
        );
      }
      let imageLink = defaultWander;
      if (this.props.profileUser.profilePic !== null) {
        imageLink = this.props.profileUser.profilePic;
      }
      let bioText = <br />;
      if (
        this.props.profileUser.bio !== null &&
        this.props.profileUser.bio.trim() !== ''
      ) {
        bioText = this.props.profileUser.bio;
      }
      // without sidebar, xl={3} xl={9} no pl-xl-0
      return (
        <div>
          <Container fluid>
            <Row className="justify-content-center">
              <div className="col-lg-8 col-md-11 col-sm-12 col-xs-12 text-center">
                <Row className="justify-content-center my-4 my-md-5">
                  <Col md={4} xs={12} className="pb-3 pb-md-0 my-auto">
                    <Image
                      src={imageLink}
                      className="profileIMG"
                      roundedCircle
                      height="200"
                      width="200"
                    />
                  </Col>
                  <Col md={8} xs={11} className="pl-xl-0">
                    <Row>
                      <Col xs={12} className="text-left font-weight-normal">
                        <p
                          className="my-0"
                          style={{fontSize: 19, paddingTop: 10}}>
                          @{this.props.profileUser.userName} | {this.getTags()}{' '}
                        </p>
                        <p
                          className="my-0 profName"
                          style={{
                            display: 'inline-block',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            verticalAlign: 'middle',
                            fontSize: 42,
                            top: '-10px',
                            position: 'relative',
                          }}>
                          {this.props.profileUser.name}{' '}
                        </p>
                        <p
                          style={{
                            display: 'inline-block',
                            justifyContent: 'space-between',
                            paddingLeft: '15px',
                            top: '-9px',
                            position: 'relative',
                          }}>
                          {' '}
                          <FollowButton
                            following={this.props.profileUser.following}
                            userID={this.props.profileUser.id}
                            name={this.props.profileUser.name}
                          />{' '}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        className="text-left font-weight-light text-muted mt-n2">
                        <p
                          className="my-0 text-truncate"
                          style={{fontSize: 19}}>
                          {bioText}{' '}
                        </p>
                        <p className="mt-1 mb-0" style={{fontSize: 17}}>
                          {this.renderPoints()} &nbsp;{' '}
                          {this.props.profileUser.numPosts} Posts &nbsp;{' '}
                          <span
                            className="text-primary"
                            style={{cursor: 'pointer'}}
                            onClick={this.handleShowFollowingClick}>
                            {this.props.profileUser.numFollowing} Following
                          </span>
                        </p>
                        <ShowFollowing
                          show={this.state.showFollowing}
                          close={this.handleShowFollowingClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="col-lg-9 col-md-11 col-sm-12 col-xs-12 text-center">
                <Row className="justify-content-center">
                  <Col xl={6} xs={6}>
                    <Row>
                      <Col xs={12} className="text-left">
                        <p className="secTitles">Collections</p>
                      </Col>
                    </Row>
                    <Row>{this.renderPlaylists()}</Row>
                  </Col>
                  <Col xl={6} xs={5}>
                    <Row>
                      <Col xs={12} className="text-left">
                        <p className="secTitles">Latest Posts</p>
                      </Col>
                    </Row>
                    {latest}
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </div>
      );
    }
  };

  render() {
    if (!this.props.stillFetching && this.props.profileUser.id >= 0) {
      return this.renderProfile();
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  user_id: state.user.id,
  username: state.user.username,
  name: state.user.name,
  user: {
    name: state.user.name,
    id: state.user.id,
  },
  profileUser: {
    id: state.profile.user_id,
    name: state.profile.name,
    userName: state.profile.username,
    playlists: state.profile.playlists,
    following: state.profile.following,
    latestFive: state.profile.latestPosts,
    numPosts: state.profile.numPosts,
    numFollowers: state.profile.numFollowers,
    numFollowing: state.profile.numFollowing,
    bio: state.profile.bio,
    profilePic: state.profile.profile_pic,
    points: state.profile.points,
    top_tags: state.profile.top_tags,
  },
  stillFetching: state.config.isFetching,
});
export default connect(mapStateToProps)(Profile);
