// @flow

import React, {Component} from 'react';
import {Button, Card, Row, Col, InputGroup, FormControl} from 'react-bootstrap';
import CommentComponent from './Comment';
import './Styles/Comments.css';
import type {Comment, User} from '../Config/Types';
import {animateScroll} from 'react-scroll';
import {connect} from 'react-redux';
import Image from 'react-bootstrap/Image';
import defaultWander from '../static/DefaultWander.png';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

type Props = {
  handleCommentSubmit: (string) => void,
  handleCommentDelete: (number) => void,
  comments: Comment[],
  followingUsers: User[],
  user: {
    name: string,
    id: number,
  },
};
type State = {
  comment: string,
  mentions: {}[],
  triedSubmitting: boolean,
  showAdvanceFormatting: boolean,
};

class Comments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      comment: '',
      triedSubmitting: false,
      showAdvanceFormatting: false,
      mentions: [],
      advancedEditor: null,
    };
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line arrow-body-style
      mentions: this.props.followingUsers.map((user) => {
        return {
          id: '@' + user.full_name,
          userId: user.user_id,
          name: user.full_name,
          link: `/profile/${user.user_id}`,
        };
      }),
    });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  handleCommentSubmit = () => {
    if (this.state.comment.length > 0) {
      this.props.handleCommentSubmit(this.state.comment);
      this.setState({
        comment: '',
        triedSubmitting: false,
        showAdvanceFormatting: false,
      });
    } else {
      this.setState({
        triedSubmitting: true,
      });
    }
  };

  scrollToBottom = () => {
    if (this.props.comments.length > 0) {
      animateScroll.scrollToBottom({
        containerId: 'commentsContainer',
        duration: 0,
      });
    }
  };

  filterTagUsers(search: string, callback) {
    return this.props.followingUsers
      .filter((user) =>
        user.full_name.toLowerCase().startsWith(search.toLowerCase()),
      )
      .map((user) => ({
        id: user.user_id,
        name: user.full_name,
      }));
  }

  renderComments = () => {
    const comments = [];

    for (let i = 0; i < this.props.comments.length; i++) {
      const comment = this.props.comments[i];
      comments.push(
        <CommentComponent
          key={comment.comment_id}
          id={comment.comment_id}
          user={comment.user}
          text={comment.comment_text}
          time={comment.comment_time}
          user_data={this.props.user}
          performDelete={this.props.handleCommentDelete}
        />,
      );
      if (i >= 0 && i < this.props.comments.length - 1) {
        comments.push(<div key={i} className="commentDivider my-2" />);
      }
    }
    return comments;
  };

  renderCommentsContainer = () => {
    if (this.props.comments && this.props.comments.length > 0) {
      return (
        <div
          id="commentsContainer"
          className="overflow-auto w-100 commentsContainer py-2">
          {this.renderComments()}
        </div>
      );
    }
    return null;
  };

  // runs after the ckeditor is rendered
  runAfterRender = () => {
    // focus ckeditor if it's been loaded + exists
    if (this.state.advancedEditor !== null) {
      this.state.advancedEditor.editing.view.focus();
    }
  };

  render() {
    // without navbar, xl={9} xl={3}
    // xl={7} lg={8} xs={12}
    let imageLink = defaultWander;
    if (this.props.mainProfilePic !== null) {
      imageLink = this.props.mainProfilePic;
    }
    const config = {
      toolbar: ['bold', 'italic', 'Link', 'numberedList', 'bulletedList'],
      placeholder: 'Share your thoughts...',
      mention: {
        feeds: [
          {
            marker: '@',
            feed: this.state.mentions,
          },
        ],
      },
    };
    if (this.state.showAdvanceFormatting) {
      return (
        <div>
          {this.renderCommentsContainer()}
          <Card.Footer
            style={{
              backgroundColor: 'white',
              borderRadius: '0px 0px 10px 10px',
            }}>
            <Row className="align-items-center">
              <Col xs="auto">
                <Image
                  src={imageLink}
                  className="profileIMG"
                  roundedCircle
                  height="40"
                  width="40"
                />
              </Col>
              <Col
                className="pl-0 pr-lg-0 advanced"
                onLoad={this.runAfterRender()}>
                <CKEditor
                  config={config}
                  editor={Editor}
                  data={this.state.comment}
                  onReady={(editor) => {
                    // store the editor to use for focusing + other functions when needed
                    this.setState({advancedEditor: editor});
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    this.setState({
                      comment: data,
                    });
                  }}
                />
              </Col>
              {/* Cancel & submit buttons for larger screens */}
              <Col xs="auto" className="d-none d-sm-block ml-3 mr-2">
                <Row className="mb-2">
                  <Button
                    size="sm"
                    className="shadow-none"
                    onClick={() =>
                      this.setState({showAdvanceFormatting: false, comment: ''})
                    }
                    variant="outline-primary">
                    <span className="small">Cancel</span>
                  </Button>
                </Row>
                <Row>
                  <Button
                    size="sm"
                    className="shadow-none"
                    onClick={this.handleCommentSubmit}
                    variant="primary">
                    <span className="small">Submit</span>
                  </Button>
                </Row>
              </Col>
            </Row>
            {/* Cancel & submit buttons for smaller screens */}
            <Row lg="auto" xs="auto" className="d-block d-sm-none pt-2">
              <Row className="justify-content-center">
                <Col xs="auto" className="pr-3">
                  <Button
                    size="sm"
                    className="shadow-none"
                    onClick={() =>
                      this.setState({showAdvanceFormatting: false, comment: ''})
                    }
                    variant="outline-primary">
                    <span className="small">Cancel</span>
                  </Button>
                </Col>
                <Col xs="auto" className="pl-3">
                  <Button
                    size="sm"
                    className="shadow-none"
                    onClick={this.handleCommentSubmit}
                    variant="primary">
                    <span className="small">Submit</span>
                  </Button>
                </Col>
              </Row>
            </Row>
          </Card.Footer>
        </div>
      );
    }
    return (
      <div>
        {this.renderCommentsContainer()}
        <Card.Footer
          style={{backgroundColor: 'white', borderRadius: '0px 0px 10px 10px'}}>
          <Row className="align-items-center">
            <Col xs="auto">
              <Image
                src={imageLink}
                className="profileIMG"
                roundedCircle
                height="40"
                width="40"
              />
            </Col>
            <Col className="pl-0 pr-lg-0">
              <InputGroup className="text-muted pr-3">
                <FormControl
                  className="comment-editor"
                  placeholder="Share your thoughts..."
                  aria-label="comments-box"
                  aria-describedby="basic-addon1"
                  onClick={() => this.setState({showAdvanceFormatting: true})}
                />
              </InputGroup>
            </Col>
          </Row>
        </Card.Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  followingUsers: state.user.followingUsers,
  mainProfilePic: state.user.user_img,
});
export default connect(mapStateToProps)(Comments);
