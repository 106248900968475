// @flow

import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
  show: Boolean,
  close: () => void,
};

class ShowFollowers extends Component<Props> {
  renderFollowers = () => {
    const followers = [];
    for (const user_id in this.props.followers) {
      followers.push(
        <Row key={user_id}>
          <Col xs={12}>
            <Link
              className="w-100"
              to={`/profile/${user_id}`}
              onClick={this.props.close}>
              <ListGroup.Item className="pl-2" action>
                {this.props.followers[user_id]}
              </ListGroup.Item>
            </Link>
          </Col>
        </Row>,
      );
    }
    return followers;
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.close} centered>
        <Modal.Header closeButton>
          <h5 className="mb-0">Followers</h5>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">{this.renderFollowers()}</ListGroup>
        </Modal.Body>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  followers: state.user.userFollowers,
});
export default connect(mapStateToProps)(ShowFollowers);
