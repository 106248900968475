// @flow

import React, {Component} from 'react';
import {Image, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import FollowButton from './FollowButton';
import defaultWander from '../static/DefaultWander.png';

class ProfileUser extends Component {
  render() {
    const user = this.props.user;
    let imageLink = defaultWander;
    if (user.img) {
      imageLink = user.img;
    }
    let bio = user.bio;
    if (bio && bio.length > 50) {
      bio = bio.slice(0, 50) + '...';
    }
    return (
      <div>
        <Row style={{margin: '0px'}}>
          <Col xs={3} align="left" style={{paddingLeft: 10, paddingRight: 0}}>
            <Link className="w-100" to={`/profile/${user.user_id}`}>
              <Image
                src={imageLink}
                style={{boxShadow: 'none'}}
                className="profileIMG"
                roundedCircle
                height="50"
                width="50"
              />
            </Link>
          </Col>

          <Col xs={6} align="left" style={{paddingLeft: 0}}>
            <Row style={{margin: '0px'}}>
              <Link
                style={{fontSize: 14, color: 'black'}}
                to={`/profile/${user.user_id}`}>
                <div style={{fontWeight: 'bold'}}> {user.full_name} </div>
                <div style={{color: 'grey'}}>{`@${user.username}`}</div>
                {/* {user.full_name.length > 14 ? user.full_name.slice(0, 14) + '...' : user.full_name} */}
              </Link>
            </Row>
          </Col>
          <Col
            xs={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 0,
              paddingRight: 0,
            }}>
            <Row style={{margin: '0px'}}>
              <Link
                className="w-100 text-decoration-none text-dark"
                to={`/profile/${user.user_id}`}>
                {bio}
              </Link>
            </Row>

            <Row
              style={{margin: '0px', verticalAlign: 'middle'}}
              className="justify-content-end">
              <Link className="w-50" to={`/profile/${user.user_id}`} />
              <FollowButton
                sidecard={true}
                userID={user.user_id}
                name={user.full_name}
                following={user.following}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProfileUser;
