// @flow

import React, {Component} from 'react';
import Button from 'react-bootstrap/Button';
import {toast} from 'react-toastify';
import './Styles/FollowButton.css';
import {connect} from 'react-redux';
import FeedActions from '../Redux/FeedRedux';
import API from '../Services/API';
import UserActions from '../Redux/UserRedux';
import ProfileActions from '../Redux/ProfileRedux';

type Props = {
  userID: number,
  name: string,
  following: boolean,
  sidecard: boolean,
  dispatch: ({}) => void,
};

class FollowButton extends Component<Props> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      following: this.props.following,
    };
    this.api = API();
  }

  updateSocialPosts = () => {
    this.api
      .getSocialPosts()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setSocialPosts(response.data));
        }
      })
      .catch((error) => console.warn(error));
  };

  handleFollow = () => {
    // Segment Event
    window.analytics.track('User Followed', {
      user_id: this.props.userID,
      name: this.props.name,
    });
    this.props.dispatch(
      UserActions.setFollowStatusForUser(this.props.userID, true),
    );
    this.setState({following: true});

    toast('You are now following ' + this.props.name, {containerId: 'N'});

    // API request to follow
    this.api
      .followUser(this.props.userID)
      .then(() => {
        this.updateSocialPosts();
        this.props.dispatch(
          UserActions.editFollowStatus(this.props.userID, true),
        );
        this.props.dispatch(ProfileActions.setFollowStatus(true));
      })
      .catch((error) => console.warn(error));
  };

  handleUnfollow = () => {
    // Segment Event
    window.analytics.track('User Unfollowed', {
      user_id: this.props.userID,
      name: this.props.name,
    });
    this.props.dispatch(
      UserActions.setFollowStatusForUser(this.props.userID, false),
    );
    this.setState({following: false});

    toast(this.props.name + ' has been unfollowed', {containerId: 'N'});

    // API request to unfollow
    this.api
      .unfollowUser(this.props.userID)
      .then(() => {
        this.updateSocialPosts();
        this.props.dispatch(
          UserActions.editFollowStatus(this.props.userID, false),
        );
        this.props.dispatch(ProfileActions.setFollowStatus(false));
      })
      .catch((error) => console.warn(error));
  };

  render() {
    if (this.state.following) {
      return (
        <Button
          className={!this.props.sidecard ? 'followbutton' : 'sidecardfollow'}
          variant="outline-primary shadow-none"
          onClick={this.handleUnfollow}>
          Unfollow
        </Button>
      );
    } else {
      return (
        <Button
          className={!this.props.sidecard ? 'followbutton' : 'sidecardfollow'}
          variant="primary shadow-none"
          onClick={this.handleFollow}>
          Follow
        </Button>
      );
    }
  }
}

export default connect()(FollowButton);
