// @flow

import React, {Component} from 'react';
import ProfileCard from '../Components/ProfileCard';
import NewsletterFollow from '../Components/NewsletterFollow';
import {connect} from 'react-redux';
import API from '../Services/API';
import UserActions from '../Redux/UserRedux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
  usersToFollow: {
    full_name: string,
    username: string,
    following: boolean,
    user_id: number,
    email: string,
    newsletter: boolean,
  }[],
  profileUser: {
    username: string,
  },
  dispatch: ({}) => void,
};

class Follow extends Component<Props> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
  }

  componentDidMount() {
    if (window.growsurf) {
      window.growsurf.init();
    }
    window.scrollTo(0, 0);
    /* if (this.props.profileRun) {
      this.props.dispatch(TourActions.setProfileRun(false))
      console.log('dispatch')
    } */
    this.fetchFollowStatuses();
    // Segment pageview call
    window.analytics.page('Follow');
  }

  componentDidUpdate(prevProps) {
    // Segment track user
    window.analytics.identify(this.props.user_id, {
      name: this.props.name,
      username: this.props.username,
    });
    if (prevProps.usersToFollow !== this.props.usersToFollow) {
      this.fetchFollowStatuses();
    }
  }

  fetchFollowStatuses = () => {
    if (!this.props.usersToFollow) return;
    for (const user of this.props.usersToFollow) {
      if (user.following === undefined) {
        this.api
          .getFollowStatus(user.userlink)
          .then((response) => {
            if (response.ok && response.data.following !== undefined) {
              this.props.dispatch(
                UserActions.setFollowStatusForUser(
                  user.user_id,
                  response.data.following,
                ),
              );
            }
          })
          .catch((error) => console.warn(error));
      }
    }
  };

  renderUsers = () => {
    if (!this.props.usersToFollow) return null;

    // eslint-disable-next-line array-callback-return
    return this.props.usersToFollow.map<any>((user) => {
      if (!user.newsletter) {
        return (
          <Col key={user.user_id} md={4} sm={6} xs={12}>
            <ProfileCard user={user} />
          </Col>
        );
      }
    });
  };

  renderNewsletters = () => {
    if (!this.props.usersToFollow) return null;

    // eslint-disable-next-line array-callback-return
    return this.props.usersToFollow.map<any>((user) => {
      if (user.newsletter) {
        return (
          <Col key={user.user_id} md={4} sm={6} xs={12}>
            <ProfileCard user={user} />
          </Col>
        );
      }
    });
  };

  render() {
    return (
      <div>
        <NewsletterFollow renderFunction={this.renderNewsletters} />
        <div className="follow pt-3">
          <Row className="mx-0">{this.renderUsers()}</Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.user.id,
  email: state.user.email,
  username: state.user.username,
  name: state.user.name,
  usersToFollow: state.user.usersToFollow,
  profileUser: {
    username: state.user.username,
  },
  // homeRun: state.tour.homeRun,
  // exploreRun: state.tour.exploreRun,
  // profileRun: state.tour.profileRun,
  // followRun: state.tour.followRun
});
export default connect(mapStateToProps)(Follow);
