import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setInfo: ['name', 'username', 'email', 'userID', 'role', 'profile_pic'],
  setRole: ['role'],
  setPlaylists: ['playlists'],
  setRatedPosts: ['ratedPosts'],
  removeRating: ['postId'],
  addRating: ['postId'],
  setUsersToFollow: ['usersToFollow'],
  dismissNotifier: [],
  setFollowStatusForUser: ['userID', 'following'],
  setFollowStatus: ['userID', 'isFollowing'],
  editFollowStatus: ['userID', 'isFollowing'],
  setFollowingUsers: ['users'],
  setNotificationPrompted: ['prompted'],
  setCalendarPrompted: ['prompted'],
  setUserFollowers: ['userFollowers'],
  setUserImg: ['user_img'],
  reset: [],
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  name: '',
  username: '',
  email: '',
  id: null,
  role: 'regular',
  playlists: [],
  ratedPosts: [],
  usersToFollow: [],
  usersFollowStatus: {},
  promptedForNotifications: false,
  calendarPrompted: false,
  userFollowers: {},
  followingUsers: [],
  showUnreadNotifier: true,
  user_img: null,
});

/* ------------- Reducers ------------- */

export const setUserImg = (state, {user_img}) => state.merge({user_img});

export const setInfo = (
  state,
  {name, username, email, userID, role, profile_pic},
) =>
  state.merge({name, username, email, id: userID, role, user_img: profile_pic});

export const setRole = (state, {role}) =>
  state.merge({
    role,
  });

export const setPlaylists = (state, {playlists}) => state.merge({playlists});

export const setRatedPosts = (state, {ratedPosts}) => state.merge({ratedPosts});

export const setUsersToFollow = (state, {usersToFollow}) =>
  state.merge({usersToFollow});

export const dismissNotifier = (state) =>
  state.merge({showUnreadNotifier: false});

export const setNotificationPrompted = (state, {prompted}) =>
  state.merge({
    promptedForNotifications: prompted,
  });

export const setCalendarPrompted = (state, {prompted}) =>
  state.merge({
    calendarPrompted: prompted,
  });

export const setFollowingUsers = (state, {users}) =>
  state.merge({
    followingUsers: users,
  });

export const reset = (state) => state.merge(INITIAL_STATE);

export const setFollowStatusForUser = (state, {userID, following}) =>
  state.merge({
    usersToFollow: state.usersToFollow.map((user) => {
      if (user.user_id === userID) {
        return {
          ...user,
          following,
        };
      }
      return user;
    }),
  });

export const setFollowStatus = (state, {userID, isFollowing}) =>
  state.merge({
    usersFollowStatus: {
      ...state.usersFollowStatus,
      [userID]: isFollowing,
    },
  });

export const editFollowStatus = (state, {userID, isFollowing}) => {
  // only edit existing user_ids, never create a pair
  if (userID in state.usersFollowStatus) {
    return state.merge({
      usersFollowStatus: {
        ...state.usersFollowStatus,
        [userID]: isFollowing,
      },
    });
  } else {
    return state;
  }
};

export const setUserFollowers = (state, {userFollowers}) =>
  state.merge({
    userFollowers,
  });

export const removeRating = (state, {postId}) =>
  state.merge({
    ratedPosts: state.ratedPosts.filter((post) => post.post_id !== postId),
  });

export const addRating = (state, {postId}) =>
  state.merge({
    ratedPosts: [...state.ratedPosts, postId],
  });

/* ------------- Hook Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_INFO]: setInfo,
  [Types.SET_ROLE]: setRole,
  [Types.SET_PLAYLISTS]: setPlaylists,
  [Types.SET_RATED_POSTS]: setRatedPosts,
  [Types.REMOVE_RATING]: removeRating,
  [Types.ADD_RATING]: addRating,
  [Types.SET_USERS_TO_FOLLOW]: setUsersToFollow,
  [Types.DISMISS_NOTIFIER]: dismissNotifier,
  [Types.SET_FOLLOW_STATUS_FOR_USER]: setFollowStatusForUser,
  [Types.SET_FOLLOW_STATUS]: setFollowStatus,
  [Types.EDIT_FOLLOW_STATUS]: editFollowStatus,
  [Types.SET_NOTIFICATION_PROMPTED]: setNotificationPrompted,
  [Types.SET_CALENDAR_PROMPTED]: setCalendarPrompted,
  [Types.SET_USER_FOLLOWERS]: setUserFollowers,
  [Types.SET_FOLLOWING_USERS]: setFollowingUsers,
  [Types.SET_USER_IMG]: setUserImg,
  [Types.RESET]: reset,
});
