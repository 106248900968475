export function register () {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      const swPath = `${process.env.PUBLIC_URL}/sw.js`
      navigator.serviceWorker.register(swPath).then(function (registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope)
      }, function (err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err)
      })
    })
  }
}

export function unregister () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister()
      })
      .catch(error => {
        console.error(error.message)
      })
  }
}
