// @flow

import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

type Props = {
  show: Boolean,
  close: () => void,
};

class FocusFinish extends Component<Props> {
  render() {
    const correctNum = Math.min(
      this.props.numRead,
      this.props.focusPosts.length,
    );
    const currentTime = new Date();
    const timeDiff = Math.ceil(
      (currentTime - this.props.startTime) / 1000 / 60,
    );
    const correctTime = Math.min(this.props.timeLimit, timeDiff);
    return (
      <Modal show={this.props.show} onHide={this.props.close} centered>
        <Modal.Header className="justify-content-center">
          <h5 className="mb-0" style={{fontWeight: '300'}}>
            Focus Results
          </h5>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p style={{fontWeight: '300'}}>
            Well done! You focused in and read {correctNum} unread{' '}
            {correctNum === 1 ? 'post' : 'posts'} in {correctTime}{' '}
            {correctTime === 1 ? 'minute' : 'minutes'}.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/unread" href="/unread" onClick={this.props.close}>
            <Button variant="primary">Finish</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  numRead: state.unread.numRead,
  focusPosts: state.unread.focusPosts,
  startTime: state.unread.startTime,
  timeLimit: state.unread.timeLimit,
});
export default connect(mapStateToProps)(FocusFinish);
