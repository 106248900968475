import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Row from 'react-bootstrap/Row';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FeedbackBox from './FeedbackBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const NewsletterFollow = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg">
        <DialogTitle>free your inbox...free your mind</DialogTitle>
        <DialogContent>
          <Row className="mx-0">{props.renderFunction()}</Row>
        </DialogContent>
        <DialogActions align="center">
          <Accordion style={{width: '30%'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Favorite newsletter not here?
            </AccordionSummary>
            <AccordionDetails>
              <FeedbackBox />
            </AccordionDetails>
          </Accordion>
        </DialogActions>
      </Dialog>
      <Button
        onClick={() => {
          setDialogOpen(true);
          window.analytics.track('Newsletter Dialog Opened');
        }}
        align="center"
        color="inherit"
        style={{
          width: '100%',
          height: '75px',
          borderColor: '#ff5c00',
          color: '#ff5c00',
        }}
        variant="outlined">
        Newsletters
      </Button>
      <br />
    </>
  );
};

export default NewsletterFollow;
