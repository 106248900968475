import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setSocialScroll: ['scroll'],
  setExploreScroll: ['scroll'],
  reset: [],
  setFetching: ['isFetching'],
  setInitialized: ['initialized'],
});

export const ConfigTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  socialScroll: 0,
  exploreScroll: 0,
  isFetching: false,
  storeInitialized: false,
});

/* ------------- Reducers ------------- */

export const setSocialScroll = (state, {scroll}) =>
  state.merge({
    socialScroll: scroll,
  });

export const setExploreScroll = (state, {scroll}) =>
  state.merge({
    exploreScroll: scroll,
  });

export const setInitialized = (state, {initialized}) =>
  state.merge({
    storeInitialized: initialized,
  });

export const setFetching = (state, {isFetching}) => state.merge({isFetching});

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SOCIAL_SCROLL]: setSocialScroll,
  [Types.SET_EXPLORE_SCROLL]: setExploreScroll,
  [Types.SET_FETCHING]: setFetching,
  [Types.SET_INITIALIZED]: setInitialized,
  [Types.RESET]: reset,
});
