import React from 'react';
import {Container, Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ErrorPage() {
  return (
    <div>
      <Container
        className="mt-5"
        style={{fontFamily: 'poppins', fontSize: '25px'}}>
        <Row className="justify-content-center pl-4 pr-4">
          Oops! We can't seem to find the page you're looking for. <br></br>
          Try searching or going to your home page.
        </Row>
        <Row className="justify-content-center mt-5">
          <Col xs="auto" />
          <Col xs={8} sm={4}>
            <Button variant="primary" size="lg" href="/" block>
              Go Home
            </Button>
          </Col>
          <Col xs="auto" />
        </Row>
      </Container>
    </div>
  );
}

export default ErrorPage;
