// @flow

import React, {Component} from 'react';
import {Button, Modal, Form, Spinner} from 'react-bootstrap';
import {toast} from 'react-toastify';
import API from '../Services/API';
import {connect} from 'react-redux';

type Props = {
  show: boolean,
  onHide: () => void,
  onSubmit: (string, string[]) => void,
};
type State = {
  oldPassword: string,
  newPassword: string,
  submitted: boolean,
  loading: boolean,
};

class CreatePost extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      oldPassword: '',
      newPassword: '',
      submitted: false,
      loading: false,
    };
  }

  handleSetPassword = (event: any) => {
    event.preventDefault();

    if (
      this.state.oldPassword.length > 0 &&
      this.state.newPassword.length > 0
    ) {
      this.setState({loading: true});
      this.api
        .setPassword(this.state.oldPassword, this.state.newPassword)
        .then((response) => {
          if (response.ok) {
            this.setState({loading: false});
            this.handleDismiss();
            toast('Your password has been changed.', {containerId: 'N'});
          }
        })
        .catch((error) => {
          this.setState({loading: false});
          this.handleDismiss();
          toast('An error occurred when changing your password.', {
            containerId: 'N',
          });
          console.warn(error);
        });
    } else {
      this.setState({
        submitted: true,
      });
    }
  };

  handleOldPwChange = (event: any) => {
    this.setState({oldPassword: event.target.value});
  };

  handleNewPwChange = (event: any) => {
    this.setState({newPassword: event.target.value});
  };

  handleDismiss = () => {
    this.props.onHide();
    this.setState({
      oldPassword: '',
      newPassword: '',
      submitted: false,
    });
  };

  getFormClassName = (text) => {
    if (this.state.submitted && text.length === 0) {
      return 'border-danger';
    }
    return '';
  };

  renderButton = () => {
    if (!this.state.loading) {
      return 'Change Password';
    } else {
      return <Spinner animation="border" />;
    }
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleDismiss} centered>
        <Modal.Header closeButton>
          <h5>Change Password</h5>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSetPassword}>
            <Form.Group>
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                className={this.getFormClassName(this.state.oldPassword)}
                value={this.state.oldPassword}
                onChange={this.handleOldPwChange}
                type="password"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                className={this.getFormClassName(this.state.newPassword)}
                value={this.state.newPassword}
                onChange={this.handleNewPwChange}
                type="password"
              />
            </Form.Group>
            <Button
              type="submit"
              onClick={this.handleSetPassword}
              disabled={this.state.loading}>
              {this.renderButton()}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  tags: state.feed.tags,
});
export default connect(mapStateToProps)(CreatePost);
