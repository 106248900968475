// @flow

import React, {Component} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';

type Props = {
  show: Boolean,
  cancel: () => void,
  newPlaylist: (e) => void,
};

class AddPlaylist extends Component<Props> {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.cancel} centered>
        <Modal.Header closeButton>
          <h5 className="mb-0">Create New Collection</h5>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.props.newPlaylist}>
            <Form.Group>
              <Form.Label>New Collection Title</Form.Label>
              <Form.Control
                required
                type="text"
                name="newPlaylist"
                placeholder="Give the collection a title"></Form.Control>
              <Form.Text className="text-muted">
                Collection must be assigned a nonblank title.
              </Form.Text>
            </Form.Group>
            <Button variant="primary shadow-none" type="submit">
              Create New Collection
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default AddPlaylist;
