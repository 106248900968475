import API from '../Services/API';
import FeedActions from '../Redux/FeedRedux';
import UserActions from '../Redux/UserRedux';
import UnreadActions from '../Redux/UnreadRedux';
import * as Notifications from '../Services/Notifications';
import NotificationActions from '../Redux/NotificationRedux';

export const loadPosts = (dispatch) => {
  const api = API();
  const promises = [];
  promises.push(
    api
      .getSocialPosts(10)
      .then((response) => {
        if (response.ok) {
          // console.log(response.data)
          dispatch(FeedActions.setSocialPosts(response.data));
        } else {
          console.warn(response.originalError);
        }
      })
      .catch((error) => console.warn(error)),
  );

  promises.push(
    api
      .getExplorePosts(10)
      .then((response) => {
        if (response.ok) {
          dispatch(FeedActions.setExplorePosts(response.data));
        }
      })
      .catch((error) => console.warn(error)),
  );

  promises.push(
    api
      .getUnreadPosts()
      .then((response) => {
        if (response.ok) {
          dispatch(UnreadActions.setUnreadPosts(response.data));
        }
      })
      .catch((error) => console.warn(error)),
  );

  return Promise.all(promises);
};

export const initNotifications = async (dispatch) => {
  const newNotifications = await Notifications.getMissedNotifications();
  dispatch(NotificationActions.setNotifications(newNotifications));
};

export const loadUserData = (dispatch) => {
  const api = API();
  const promises = [];
  promises.push(
    api
      .getMainUserPlaylists()
      .then((response) => {
        if (response.ok) {
          dispatch(UserActions.setPlaylists(response.data));
        }
      })
      .catch((error) => console.warn(error)),
  );

  promises.push(
    api
      .getUsersToFollow()
      .then((response) => {
        if (response.ok) {
          dispatch(UserActions.setUsersToFollow(response.data));
        }
      })
      .catch((error) => console.warn(error)),
  );

  promises.push(
    api.getFollowingUsers().then((response) => {
      if (response.ok) {
        dispatch(UserActions.setFollowingUsers(response.data));
      }
    }),
  );

  return Promise.all(promises);
};

export const loadTags = (dispatch) => {
  const api = API();
  return api
    .getTags()
    .then((response) => {
      if (response.ok) {
        dispatch(FeedActions.setTags(response.data));
      }
    })
    .catch((error) => console.warn(error));
};
