import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setProfileInfo: [
    'userID',
    'name',
    'username',
    'playlists',
    'bio',
    'profile_pic',
    'points',
  ],
  setFollowStatus: ['following'],
  setLatestPosts: ['latest'],
  setProfileStats: ['numPosts', 'numFollowers', 'numFollowing'],
  setBio: ['bio'],
  setProfilePlaylists: ['playlists'],
  setProfilePicture: ['profile_pic'],
  reset: [],
  setProfileFollowing: ['profileFollowing'],
  setTopTags: ['top_tags'],
});

export const ProfileTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user_id: -1,
  name: '',
  username: '',
  playlists: [],
  following: false,
  latestPosts: [],
  numPosts: 0,
  numFollowers: 0,
  numFollowing: 0,
  bio: null,
  points: 0,
  profile_pic: null,
  profileFollowing: {},
  top_tags: [],
});

/* ------------- Reducers ------------- */

export const setProfileInfo = (
  state,
  {userID, name, username, playlists, bio, profile_pic, points},
) =>
  state.merge({
    user_id: userID,
    name,
    username,
    playlists,
    bio,
    profile_pic,
    points,
  });

export const setFollowStatus = (state, {following}) =>
  state.merge({
    following,
  });

export const setLatestPosts = (state, {latest}) =>
  state.merge({latestPosts: latest});

export const setProfileStats = (
  state,
  {numPosts, numFollowers, numFollowing},
) =>
  state.merge({
    numPosts,
    numFollowers,
    numFollowing,
  });

export const setBio = (state, {bio}) =>
  state.merge({
    bio,
  });

export const setProfilePlaylists = (state, {playlists}) =>
  state.merge({
    playlists,
  });

export const setProfilePicture = (state, {profile_pic}) =>
  state.merge({
    profile_pic,
  });

export const setProfileFollowing = (state, {profileFollowing}) =>
  state.merge({
    profileFollowing,
  });

export const setTopTags = (state, {top_tags}) => state.merge({top_tags});

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PROFILE_INFO]: setProfileInfo,
  [Types.SET_FOLLOW_STATUS]: setFollowStatus,
  [Types.SET_LATEST_POSTS]: setLatestPosts,
  [Types.SET_PROFILE_STATS]: setProfileStats,
  [Types.SET_BIO]: setBio,
  [Types.SET_PROFILE_PLAYLISTS]: setProfilePlaylists,
  [Types.SET_PROFILE_PICTURE]: setProfilePicture,
  [Types.SET_PROFILE_FOLLOWING]: setProfileFollowing,
  [Types.SET_TOP_TAGS]: setTopTags,
  [Types.RESET]: reset,
});
