import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import configureStore from './CreateStore';
import rootSaga from '../Sagas';
import ReduxPersist from '../Config/ReduxPersist';

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  user: require('./UserRedux').reducer,
  feed: require('./FeedRedux').reducer,
  playlist: require('./PlaylistRedux').reducer,
  profile: require('./ProfileRedux').reducer,
  config: require('./ConfigRedux').reducer,
  notifications: require('./NotificationRedux').reducer,
  // tour: require('./TourRedux').reducer,
  unread: require('./UnreadRedux').reducer,
  post: require('./PostRedux').reducer,
  admin: require('./AdminRedux').reducer,
});

export default () => {
  let finalReducers = reducers;
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig;
    finalReducers = persistReducer(persistConfig, reducers);
  }

  let {store, sagasManager, sagaMiddleware} = configureStore(
    finalReducers,
    rootSaga,
  );

  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(require('.').reducers);

      const newYieldedSagas = require('../Sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return store;
};
