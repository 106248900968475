import API from "./API";
// import { openDB } from "idb";
import is from "is_js";

if (navigator.serviceWorker) {
  navigator.serviceWorker.ready.then(async () => {
    if (getPermissions() === "granted") {
      const key = await getPublicKey();
      const subscription = await subscribeToNotifications(key);
      await sendSubscriptionToServer(subscription);
    }
  });
}

export const requestPermissions = () => {
  if (
    !is.safari() &&
    !is.ie() &&
    !navigator.userAgent.match("CriOS") &&
    "Notification" in window
  ) {
    return Notification.requestPermission();
  }
  return "denied";
};

export const getPermissions = () => {
  if (
    !is.safari() &&
    !is.ie() &&
    !navigator.userAgent.match("CriOS") &&
    "Notification" in window
  ) {
    return Notification.permission;
  }
  return "denied";
};

export const getPublicKey = () => {
  const api = API();
  return api.getNotificationPublicKey().then((response) => {
    if (response.ok) {
      return urlB64ToUint8Array(response.data.public_key);
    }
    return null;
  });
};

// const openDatabase = () => {
//   return openDB("wander", 1, {
//     upgrade(db) {
//       db.createObjectStore("notifications", { keyPath: "id" });
//     },
//   });
// };

export const getMissedNotifications = () => {
  const api = API();
  return api.getNotifications().then((response) => {
    if (response.ok) {
      return response.data;
    }
    return [];
  });
};

export const sendSubscriptionToServer = (subscription) => {
  const api = API();
  api.subscribeToNotifications(subscription);
};

export const subscribeToNotifications = (publicKey) => {
  return navigator.serviceWorker.ready.then((serviceWorker) => {
    // subscribe and return the subscription
    if (serviceWorker.pushManager) {
      return serviceWorker.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicKey,
        })
        .then((subscription) => {
          return subscription.toJSON();
        });
    }
  });
};

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
