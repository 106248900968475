// @flow

import React, {Component} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import {connect} from 'react-redux';

type Props = {
  show: Boolean,
  changeBio: (e) => void,
  cancel: () => void,
  currentBio: string,
};

class Edit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      label: 'Upload a profile picture.',
    };
  }

  render() {
    let bioCurrent = this.props.currentBio;
    if (bioCurrent === null) {
      bioCurrent = '';
    }
    return (
      <Modal show={this.props.show} onHide={this.props.cancel} centered>
        <Modal.Header closeButton>
          <h5 className="mb-0">Edit Profile</h5>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.props.changeBio}>
            <Form.Group>
              <Form.Label>Profile Picture</Form.Label>
              <Form.File
                name="newProfPic"
                className="text-truncate"
                label={this.state.label}
                onChange={(e) => {
                  this.setState({
                    label: e.currentTarget.files[0].name,
                  });
                }}
                custom
              />
              <Form.Label className="pt-2">Current Bio</Form.Label>
              <Form.Control
                type="text"
                name="newBio"
                defaultValue={bioCurrent}></Form.Control>
              <Form.Text className="text-muted">
                Directly change your current bio above to your desired bio.
              </Form.Text>
            </Form.Group>
            <Button variant="primary shadow-none" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentBio: state.profile.bio,
});
export default connect(mapStateToProps)(Edit);
