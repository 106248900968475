// @flow

import React, {Component} from 'react';
import Cookies from 'js-cookie';
import {connect} from 'react-redux';
import UserActions from '../Redux/UserRedux';
import API from '../Services/API';
import {toast} from 'react-toastify';
import * as Startup from '../Utils/Startup';
import {Spinner, Modal, Form, Col, Button} from 'react-bootstrap';
import './Styles/Login.css';

function InvalidCredentials(props) {
  return props.show ? (
    <p style={{color: 'red'}}>Error: incorrect email or password</p>
  ) : null;
}

function ErrorLoggingIn(props) {
  return props.show ? (
    <p style={{color: 'red'}}>Error: unable to login</p>
  ) : null;
}

type Props = {
  dispatch: ({}) => void,
  history: any,
};
type State = {
  mode: string,
  email: string,
  password: string,
  username: string,
  firstName: string,
  lastName: string,
  agree: boolean,
  loading: boolean,
  showInvalidWarning: boolean,
  showErrorWarning: boolean,
  createPassword: string,
  createLoading: boolean,
  showCreateLogin: boolean,
  createLoginInvalid: boolean,
};

class Login extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      mode: this.props.location.mode,
      email: '',
      password: '',
      username: '',
      firstName: '',
      lastName: '',
      agree: false,
      loading: false,
      showInvalidWarning: false,
      showErrorWarning: false,
      createPassword: '',
      createLoading: false,
      showCreateLogin: false,
      createLoginInvalid: false,
      showForgetPassword: false,
    };
  }

  componentDidMount() {
    // Segment pageview call
    window.analytics.page('Login/Signup');
  }

  componentDidUpdate() {
    /* if(window.growsurf) {
      console.log("growsurf reloading");
      window.growsurf.init();
    } */
  }

  setMode = (mode: string) => this.setState({mode});

  handleEmailChange = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };

  handlePasswordChange = (event: any) => {
    this.setState({
      password: event.target.value,
    });
  };

  handleUsernameChange = (event: any) => {
    this.setState({
      username: event.target.value,
    });
  };

  handleFirstNameChange = (event: any) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  handleLastNameChange = (event: any) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  handleAgree = (event: any) => {
    this.setState({
      agree: event.target.checked,
    });
  };

  authenticate = () => {
    this.api
      .authenticate(this.state.email, this.state.password)
      .then((response) => {
        if (response.ok) {
          const token = response.data.access_token;
          this.api.setAccessToken(token);
          Cookies.set('accessToken', token, {expires: 60});
          this.props.dispatch(
            UserActions.setInfo(
              response.data.full_name,
              response.data.username,
              response.data.email,
              response.data.user_id,
              response.data.role,
              response.data.profile_pic,
            ),
          );
          const promises = [];
          promises.push(Startup.loadPosts(this.props.dispatch));
          promises.push(Startup.loadUserData(this.props.dispatch));
          promises.push(Startup.loadTags(this.props.dispatch));
          Promise.all(promises).then(() => {
            this.setState({loading: false});
            this.props.history.push('/');
          });
        } else if (response.status === 401) {
          this.setState({
            loading: false,
            showInvalidWarning: true,
            showErrorWarning: false,
          });
        } else {
          this.setState({
            loading: false,
            showInvalidWarning: false,
            showErrorWarning: true,
          });
        }
      })
      .catch((error) => {
        console.warn(error);
        this.setState({loading: false});
      });
  };

  handleLogin = (event: any) => {
    event.preventDefault();
    if (this.state.email !== '' && this.state.password !== '') {
      this.setState({loading: true});
      this.authenticate();
    }
  };

  handleSignUp = (event: any) => {
    event.preventDefault();
    if (
      this.state.email !== '' &&
      this.state.password !== '' &&
      this.state.firstName !== '' &&
      this.state.lastName !== '' &&
      this.state.username !== '' &&
      this.state.agree
    ) {
      this.setState({loading: true});
      let referredBy = '';
      const q = window.location.search;
      if (q && q.substring(0, 6) === '?grsf=') {
        referredBy = q.substring(6);
      }
      this.api
        .signUp(
          this.state.email,
          this.state.password,
          this.state.firstName,
          this.state.lastName,
          this.state.username,
          referredBy,
        )
        .then((response) => {
          if (response.ok) {
            this.authenticate();
          }
        })
        .catch((error) => console.warn(error));
    }
  };

  handleForgetPasswordOpen = () => {
    this.setState({
      showForgetPassword: true,
    });
  };

  handleForgetPasswordClose = () => {
    this.setState({
      showForgetPassword: false,
    });
  };

  handleForgetPasswordSubmit = (event: any) => {
    event.preventDefault();
    const user_email = event.target.elements.userEmail.value.trim();
    // Segment pageview call
    window.analytics.track('Forget Password Requested', {email: user_email});
    this.api
      .forgetPassword(user_email)
      .then((response) => {
        if (response.ok) {
          this.handleForgetPasswordClose();
          toast('Your new password is on the way to your email.', {
            containerId: 'N',
          });
        }
      })
      .catch((error) => {
        console.warn(error);
        this.handleForgetPasswordClose();
        toast('There was an error, email yash@thinkwander.com', {
          containerId: 'N',
        });
      });
  };

  handleCreateAuth = (event: any) => {
    event.preventDefault();
    if (this.state.createPassword !== '') {
      this.setState({createLoading: true});
      this.api
        .createPageAuthenticate(this.state.createPassword)
        .then((response) => {
          if (response.ok) {
            this.setState({mode: 'sign_up'});
            this.handleModalDismiss();
          } else {
            this.setState({createLoginInvalid: true});
          }
          this.setState({createLoading: false});
        });
    }
  };

  handleModalDismiss = () => {
    this.setState({
      showCreateLogin: false,
      createPassword: '',
      createLoading: false,
      createLoginInvalid: false,
    });
  };

  renderButton = (loading, text: string) => {
    if (!loading) {
      return text;
    } else {
      return <Spinner animation="border" />;
    }
  };

  renderInvalidCreatePw = () => {
    if (this.state.createLoginInvalid) {
      return <span style={{color: 'red'}}>Invalid access code</span>;
    }
    return null;
  };

  renderModal() {
    return (
      <Modal
        centered
        show={this.state.showCreateLogin}
        onHide={this.handleModalDismiss}>
        <Modal.Body>
          <p>
            Thanks for your interest in Wander! If you've been invited to the
            platform by the Wander team, please enter the access code to create
            an account.
          </p>
          <Form onSubmit={this.handleCreateAuth}>
            {this.renderInvalidCreatePw()}
            <Form.Row>
              <Col>
                <Form.Control
                  type="password"
                  value={this.state.createPassword}
                  onChange={(e) =>
                    this.setState({createPassword: e.target.value})
                  }
                  placeholder="Access Code"
                />
              </Col>
              <Col>
                <Button type="submit" onClick={this.handleCreateAuth}>
                  {this.renderButton(this.state.createLoading, 'Enter')}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  loadCreateAccount = () => {
    // let referredBy = '';
    // const q = window.location.search;
    // if (q && q.substring(0, 6) === '?grsf=') {
    //   referredBy = q.substring(6);
    // }

    this.setState({mode: 'create-account'});
    // if (referredBy === '') {
    //   this.setState({ showCreateLogin: true })
    // }
    // else {
    //   this.setState({ mode: 'create-account' })
    // }
  };

  forgetPassword() {
    return (
      <Modal
        show={this.state.showForgetPassword}
        onHide={this.handleForgetPasswordClose}
        centered>
        <Modal.Body>
          <Form onSubmit={this.handleForgetPasswordSubmit}>
            <Form.Group>
              <Form.Label>
                Enter your email, and we'll send you a new password
              </Form.Label>
              <Form.Control
                required
                type="email"
                name="userEmail"
                placeholder="Email"></Form.Control>
              <Form.Text className="text-muted">
                You can change your password from settings after logging in.
              </Form.Text>
            </Form.Group>
            <Button variant="primary shadow-none" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    if (this.state.mode === 'login') {
      return (
        <div className="login-page">
          {this.renderModal()}
          {this.forgetPassword()}
          <div className="form">
            <InvalidCredentials show={this.state.showInvalidWarning} />
            <ErrorLoggingIn show={this.state.showErrorWarning} />
            <form className="login-form" onSubmit={this.handleLogin}>
              <input
                type="text"
                placeholder="email address"
                onChange={this.handleEmailChange}
              />
              <input
                type="password"
                placeholder="password"
                onChange={this.handlePasswordChange}
              />
              <button
                onClick={this.handleLogin}
                disabled={this.state.loading}
                type="submit">
                {this.renderButton(this.state.loading, 'Login')}
              </button>
              <p className="message">
                Not registered?
                <span
                  onClick={this.loadCreateAccount}
                  className="text-primary loginSwitcher">
                  {' '}
                  Create an Account
                </span>
              </p>
              <p className="message">
                <span
                  onClick={this.handleForgetPasswordOpen}
                  className="text-primary loginSwitcher">
                  {' '}
                  Forgot password?
                </span>
              </p>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className="login-page">
          {this.renderModal()}
          <div className="form">
            <form className="login-form" onSubmit={this.handleSignUp}>
              <input
                type="text"
                placeholder="email address"
                onChange={this.handleEmailChange}
              />
              <input
                type="text"
                placeholder="first name"
                onChange={this.handleFirstNameChange}
              />
              <input
                type="text"
                placeholder="last name"
                onChange={this.handleLastNameChange}
              />
              <input
                type="text"
                placeholder="username"
                onChange={this.handleUsernameChange}
              />
              <input
                type="password"
                placeholder="password"
                onChange={this.handlePasswordChange}
              />
              <p className="message">
                I understand that Wander has no tolerance for objectionable
                content or abusive users
                <input
                  type="checkbox"
                  checked={this.state.agree}
                  onChange={this.handleAgree}
                />
              </p>

              <button
                onClick={this.handleSignUp}
                disabled={this.state.loading}
                type="submit">
                {this.renderButton(this.state.loading, 'Sign Up')}
              </button>
              <p className="message">
                Already registered?
                <span
                  onClick={() => this.setMode('login')}
                  className="text-primary loginSwitcher">
                  {' '}
                  Log In
                </span>
              </p>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default connect()(Login);
