// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import API from '../Services/API';
import PostActions from '../Redux/PostRedux';
import UnreadActions from '../Redux/UnreadRedux';
import type {Playlist} from '../Config/Types';
import './Styles/NativeReader.css';
import {Card, Row, Col, Modal} from 'react-bootstrap';
import ActionPanel from '../Components/ActionPanel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RateReviewRoundedIcon from '@material-ui/icons/RateReviewRounded';
import FocusFinish from '../Components/FocusFinish';
import {Link} from 'react-router-dom';
import Colors from '../Config/Colors';
import defaultWander from '../static/DefaultWander.png';
import {Helmet} from 'react-helmet';
import ErrorPage from '../Components/ErrorPage';

type Props = {
  stillFetching: boolean,
  showFocusStats: boolean,
  toggleStats: () => void,
  user: {
    name: string,
    id: number,
  },
  playlists: Playlist[],
  post: any,
  dispatch: ({}) => void,
  history: any,
};

class NativeReader extends Component<Props, State> {
  api: any;

  constructor(props) {
    super(props);
    this.api = API();
    this.state = {
      showActionPanel: false,
    };
  }

  static fetch(match, location, options) {
    const api = API();
    const promises = [];
    promises.push(
      api
        .getNativeCode(match.params.id)
        .then((response) => {
          if (response.ok) {
            options.dispatch(
              PostActions.setPostCode(response.data.native_code),
            );
          }
        })
        .catch((error) => console.warn(error)),
    );
    promises.push(
      api
        .getPost(match.params.id)
        .then((response) => {
          if (response.ok) {
            options.dispatch(PostActions.setPost(response.data));
          } else {
            options.dispatch(
              PostActions.setPost({link: 'https://theuselessweb.com/'}),
            );
          }
        })
        .catch((error) => {
          options.dispatch(
            PostActions.setPost({link: 'https://theuselessweb.com/'}),
          );
          console.warn(error);
        }),
    );
    return Promise.all(promises);
  }

  componentDidMount() {
    // Segment Event
    window.analytics.track('Post Opened');
    window.scrollTo(0, 0);
  }

  nextFocus = () => {
    if (
      this.props.focusPosts &&
      this.props.focusPosts.length - 1 > this.props.currentPost
    ) {
      this.props.dispatch(UnreadActions.nextPost(this.props.currentPost + 1));
      this.props.dispatch(UnreadActions.setNumRead(this.props.numRead + 1));
    } else {
      const promises = [];
      promises.push(
        this.props.dispatch(UnreadActions.setNumRead(this.props.numRead + 1)),
      );
      // promises.push(this.props.dispatch(UnreadActions.resetFocus()))
      Promise.all(promises).then(() => {
        this.props.toggleStats();
      });
    }
  };

  backFocus = () => {
    if (!this.props.focusActive) {
      window.history.back();
    } else {
      if (this.props.currentPost === 0) {
        window.history.back();
      } else {
        this.props.dispatch(UnreadActions.setNumRead(this.props.numRead - 1));
        this.props.dispatch(UnreadActions.nextPost(this.props.currentPost - 1));
      }
    }
  };

  finishFocus = () => {
    const promises = [];
    promises.push(this.props.dispatch(UnreadActions.resetFocus()));
    Promise.all(promises).then(() => {
      this.props.toggleStats();
      this.closeFullscreen();
      window.analytics.track('focus mode finished');
    });
  };

  closeFullscreen = () => {
    if (document.fullscreenElement === null) {
      return;
    }
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  renderMobileNext = () => {
    if (!this.props.focusActive) {
      return <ArrowForwardIcon className="invisible" />;
    } else {
      if (
        this.props.focusPosts &&
        this.props.focusPosts.length - 1 > this.props.currentPost
      ) {
        return (
          <Link
            to={`/post/${this.props.focusPosts[this.props.currentPost + 1]}`}
            href={`/post/${this.props.focusPosts[this.props.currentPost + 1]}`}>
            <ArrowForwardIcon
              style={{cursor: 'pointer'}}
              onClick={this.nextFocus}
            />
          </Link>
        );
      } else {
        return (
          <ArrowForwardIcon
            style={{cursor: 'pointer'}}
            onClick={this.nextFocus}
          />
        );
      }
    }
  };

  renderNext = () => {
    if (this.props.focusActive) {
      if (
        this.props.focusPosts &&
        this.props.focusPosts.length - 1 > this.props.currentPost
      ) {
        return (
          <Link
            to={`/post/${this.props.focusPosts[this.props.currentPost + 1]}`}
            href={`/post/${this.props.focusPosts[this.props.currentPost + 1]}`}>
            <ArrowForwardIcon
              fontSize="large"
              style={{cursor: 'pointer'}}
              onClick={this.nextFocus}
            />
          </Link>
        );
      } else {
        return (
          <ArrowForwardIcon
            fontSize="large"
            style={{cursor: 'pointer', color: Colors.primary}}
            onClick={this.nextFocus}
          />
        );
      }
    }
    return (
      <ArrowForwardIcon
        fontSize="large"
        style={{cursor: 'pointer', color: Colors.primary}}
      />
    );
  };

  renderBack = (btnSize) => {
    if (!this.props.focusActive) {
      if (btnSize === 'l') {
        return (
          <ArrowBackIcon
            fontSize="large"
            className="backButton"
            style={{cursor: 'pointer', color: Colors.primary}}
            onClick={this.backFocus}
          />
        );
      } else {
        return (
          <ArrowBackIcon
            className="backButton"
            style={{cursor: 'pointer', color: Colors.primary}}
            onClick={this.backFocus}
          />
        );
      }
    } else {
      if (this.props.currentPost === 0) {
        return <ArrowBackIcon className="invisible" />;
      } else {
        if (btnSize === 'l') {
          return (
            <Link
              to={`/post/${this.props.focusPosts[this.props.currentPost - 1]}`}
              href={`/post/${
                this.props.focusPosts[this.props.currentPost - 1]
              }`}>
              <ArrowBackIcon
                fontSize="large"
                style={{cursor: 'pointer'}}
                onClick={this.backFocus}
              />
            </Link>
          );
        } else {
          return (
            <Link
              to={`/post/${this.props.focusPosts[this.props.currentPost - 1]}`}
              href={`/post/${
                this.props.focusPosts[this.props.currentPost - 1]
              }`}>
              <ArrowBackIcon
                style={{cursor: 'pointer'}}
                onClick={this.backFocus}
              />
            </Link>
          );
        }
      }
    }
  };

  renderPostCode() {
    return {__html: this.props.post.post_code};
  }

  renderPost = () => {
    const filtered = this.props.post.post_code.includes('<img');
    const postUrl = this.props.post.link;
    const domain = new URL(postUrl).hostname;
    return (
      <div width="100%" height="100%">
        <Row className="mx-0 px-1">
          <Col xs={12} className="text-center px-1 pt-4 pb-3">
            <h1 style={{fontWeight: '300'}}>{this.props.post.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center px-1 pb-3">
            <h5>
              <span
                style={{cursor: 'pointer', color: Colors.primary}}
                onClick={() => {
                  window.open(this.props.post.link);
                }}>
                Open link on {domain}
              </span>
            </h5>
          </Col>
        </Row>
        <Row
          className={
            this.props.post.img != null && filtered === false
              ? 'd-block'
              : 'd-none'
          }>
          <Col xs={12} className="text-center px-5 pb-4">
            <div className="nativeWrapper">
              <Card.Img
                className="nativeImage shadow"
                variant="top"
                src={this.props.post.img}
              />
            </div>
          </Col>
        </Row>
        <Row className="mx-0 px-1">
          <Col xs={12} style={{fontWeight: '300'}}>
            <div
              className="htmlContent"
              dangerouslySetInnerHTML={this.renderPostCode()}
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderYoutubePost = () => {
    const postUrl = this.props.post.link;
    const domain = new URL(postUrl).hostname;
    return (
      <div width="100%" height="100%">
        <Row className="mx-0 px-1">
          <Col xs={12} className="text-center px-1 pt-4 pb-3">
            <h1 style={{fontWeight: '300'}}>{this.props.post.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-center px-1 pb-3">
            <h5>
              <span
                style={{cursor: 'pointer', color: Colors.primary}}
                onClick={() => {
                  window.open(this.props.post.link);
                }}>
                Open link on {domain}
              </span>
            </h5>
          </Col>
        </Row>
      </div>
    );
  };

  renderPostContainer = () => {
    if (
      (this.props.post.post_id !== 0 && !this.props.post.embed_link) ||
      this.props.post.post_code
    ) {
      return (
        <Col
          sm={8}
          xs={11}
          className="h-100 w-100 shadow"
          style={{overflow: 'auto', backgroundColor: 'white'}}>
          {this.renderPost()}
        </Col>
      );
    } else if (this.props.post.embed_link.includes('www.youtube.com')) {
      // allow users to play the yt video in the post
      return (
        <Col
          sm={8}
          xs={11}
          className="h-100 w-100 shadow"
          style={{overflow: 'auto', backgroundColor: 'white'}}>
          {this.renderYoutubePost()}
          <embed
            src={this.props.post.embed_link}
            className="shadow mb-4"
            width="100%"
            height="100%"
          />
        </Col>
      );
    }
    return (
      <Col sm={8} xs={11} className="px-0">
        <embed
          src={this.props.post.embed_link}
          className="shadow"
          width="100%"
          height="100%"
        />
      </Col>
    );
  };

  renderMobileContainer = () => {
    if (
      (this.props.post.post_id !== 0 && !this.props.post.embed_link) ||
      this.props.post.post_code
    ) {
      return (
        <Row
          className="mx-3 px-0 justify-content-center"
          style={{height: '90%', backgroundColor: 'white'}}>
          <Col
            xs={12}
            style={{overflow: 'auto'}}
            className="h-100 w-100 shadow">
            {this.renderPost()}
          </Col>
        </Row>
      );
    } else if (this.props.post.embed_link.includes('www.youtube.com')) {
      // allow users to play the yt video in the post
      return (
        <Col
          xs={12}
          className="h-100 w-100 shadow"
          style={{overflow: 'auto', backgroundColor: 'white'}}>
          {this.renderYoutubePost()}
          <embed
            src={this.props.post.embed_link}
            className="shadow mb-4"
            width="100%"
            height="100%"
          />
        </Col>
      );
    }
    return (
      <Row className="mx-0 px-0 justify-content-center" style={{height: '90%'}}>
        <Col xs={12}>
          <embed
            src={this.props.post.embed_link}
            className="shadow"
            width="100%"
            height="100%"
          />
        </Col>
      </Row>
    );
  };

  render() {
    if (!this.props.stillFetching) {
      if (this.props.post.link === 'https://theuselessweb.com/') {
        return <ErrorPage />;
      }

      let imageLink = defaultWander;
      if (this.props.post.img !== null) {
        imageLink = this.props.post.img;
      }
      return (
        <div className="h-100 w-100">
          <Helmet>
            <meta property="og:title" content={this.props.post.title} />
            <meta
              property="og:description"
              content={
                this.props.post.description
                  ? this.props.post.description
                  : 'Read more on Wander!'
              }
            />
            <meta property="og:image" content={imageLink} />
          </Helmet>
          <Modal
            size="lg"
            show={this.state.showActionPanel}
            onHide={() => this.setState({showActionPanel: false})}
            centered>
            <Modal.Body className="actionModal">
              <ActionPanel {...this.props.post} />
            </Modal.Body>
          </Modal>
          <FocusFinish
            show={this.props.showFocusStats}
            close={this.finishFocus}
          />
          <div
            className="h-100 w-100 d-none d-sm-block"
            style={{background: '#E6E6E6'}}>
            <Row className="mx-0 h-100 justify-content-center">
              <Col sm={2} xs={1} className="mt-auto px-3 pb-3">
                {this.renderBack('l')}
              </Col>
              {this.renderPostContainer()}
              <Col sm={2} xs={1} className="d-none pl-0 pr-3">
                <ActionPanel {...this.props.post} />
              </Col>
              <Col sm={2} xs={1} className="h-100 d-block text-right px-0">
                <Row className="mx-0">
                  <Col xs={12} className="pt-3 px-3">
                    <RateReviewRoundedIcon
                      style={{cursor: 'pointer', fontSize: '40px'}}
                      onClick={() => this.setState({showActionPanel: true})}
                    />
                  </Col>
                </Row>
                <Row
                  className={this.props.focusActive ? 'd-block' : 'd-none'}
                  style={{position: 'absolute', bottom: '0', width: '100%'}}>
                  <Col xs={12} className="px-3 pb-3">
                    {this.renderNext()}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className="h-100 w-100 d-block d-sm-none"
            style={{background: '#E6E6E6'}}>
            {this.renderMobileContainer()}
            <Row className="mx-0" style={{height: '10%'}}>
              <Col xs="auto" className="pl-3 my-auto">
                {this.renderBack('r')}
              </Col>
              <Col className="text-center my-auto">
                <RateReviewRoundedIcon
                  fontSize="large"
                  style={{cursor: 'pointer'}}
                  onClick={() => this.setState({showActionPanel: true})}
                />
              </Col>
              <Col xs="auto" className="pr-3 my-auto">
                {this.renderMobileNext()}
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: {
    name: state.user.name,
    id: state.user.id,
  },
  playlists: state.user.playlists,
  post: state.post,
  stillFetching: state.config.isFetching,
  currentPost: state.unread.currentPost,
  focusPosts: state.unread.focusPosts,
  focusActive: state.unread.focusActive,
  numRead: state.unread.numRead,
});
export default connect(mapStateToProps)(NativeReader);
