import Cookies from 'js-cookie'
import * as Startup from '../Utils/Startup'
import ConfigActions from '../Redux/ConfigRedux'

// runs on startup
export function * startup ({ store }) {
  const token = Cookies.get('accessToken')
  const user = store.getState().user.id
  store.dispatch(ConfigActions.setInitialized(true))
  if (token && user) {
    const promises = []
    promises.push(Startup.loadPosts(store.dispatch))
    promises.push(Startup.loadUserData(store.dispatch))
    promises.push(Startup.loadTags(store.dispatch))
    promises.push(Startup.initNotifications(store.dispatch))
    yield Promise.all(promises)
  }
}
