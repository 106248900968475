// @flow

import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
  show: boolean,
  post_id: number,
  onHide: () => void,
  onOther: () => void,
  performSchedule: (post_id: number, scheduleDate: Date) => void,
};

class SchedulePost extends Component<Props> {
  printDayOfWeek = (day: Date) => {
    const weekday = new Array(7);
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';
    return weekday[day.getDay()];
  };

  renderContents = () => {
    const today = new Date();

    const day3 = new Date(today.getTime() + 86400000 * 2);
    const day4 = new Date(today.getTime() + 86400000 * 3);
    const day5 = new Date(today.getTime() + 86400000 * 4);
    const day6 = new Date(today.getTime() + 86400000 * 5);
    const day7 = new Date(today.getTime() + 86400000 * 6);
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} centered>
        <Modal.Body>
          <p className="pt-3" style={{textAlign: 'center'}}>
            Choose a day to read this post
          </p>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={() => {
                  this.props.performSchedule(this.props.post_id, today);
                  this.props.onHide();
                }}>
                Today
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={() => {
                  const day = new Date(today.getTime() + 86400000);
                  this.props.performSchedule(this.props.post_id, day);
                  this.props.onHide();
                }}>
                Tomorrow
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={() => {
                  const day = new Date(today.getTime() + 86400000 * 2);
                  this.props.performSchedule(this.props.post_id, day);
                  this.props.onHide();
                }}>
                {this.printDayOfWeek(day3)}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={() => {
                  const day = new Date(today.getTime() + 86400000 * 3);
                  this.props.performSchedule(this.props.post_id, day);
                  this.props.onHide();
                }}>
                {this.printDayOfWeek(day4)}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={() => {
                  const day = new Date(today.getTime() + 86400000 * 4);
                  this.props.performSchedule(this.props.post_id, day);
                  this.props.onHide();
                }}>
                {this.printDayOfWeek(day5)}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={() => {
                  const day = new Date(today.getTime() + 86400000 * 5);
                  this.props.performSchedule(this.props.post_id, day);
                  this.props.onHide();
                }}>
                {this.printDayOfWeek(day6)}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={() => {
                  const day = new Date(today.getTime() + 86400000 * 6);
                  this.props.performSchedule(this.props.post_id, day);
                  this.props.onHide();
                }}>
                {this.printDayOfWeek(day7)}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="my-1">
              <Button
                variant="light"
                className="btn-block"
                onClick={this.props.onOther}>
                Other...
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({
                calendarView: false,
              });
              this.props.onHide();
            }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    return this.renderContents();
  }
}

export default SchedulePost;
