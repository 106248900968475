import React, {Component} from 'react';
import {Multiselect} from 'multiselect-react-dropdown';
import API from '../Services/API';
import UserActions from '../Redux/UserRedux';
import Colors from '../Config/Colors';
import {connect} from 'react-redux';

class PlaylistBar extends Component {
  constructor(props) {
    super(props);
    this.api = API();
    this.state = {
      currentlyIn: [],
    };
  }

  componentDidMount() {
    this.api
      .getPostPlaylists(this.props.post_id)
      .then((response) => {
        this.setState({
          currentlyIn: response.data,
        });
      })
      .catch((error) => console.warn(error));
  }

  handleSelectPlaylist = (selectedList, selectedItem) => {
    // Segment Event
    window.analytics.track('Post Added To Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.api
      .addToPlaylist(selectedItem.playlist_id, this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.api
            .getMainUserPlaylists()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(UserActions.setPlaylists(response.data));
              }
            })
            .catch((error) => console.warn(error));
        }
      })
      .catch((error) => console.warn(error));
  };

  handleRemovePlaylist = (selectedList, selectedItem) => {
    // Segment Event
    window.analytics.track('Post Removed From Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.api
      .removeFromPlaylist(selectedItem.playlist_id, this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.api
            .getMainUserPlaylists()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(UserActions.setPlaylists(response.data));
              }
            })
            .catch((error) => console.warn(error));
        }
      })
      .catch((error) => console.warn(error));
  };

  render() {
    return (
      <Multiselect
        options={this.props.playlists}
        onRemove={this.handleRemovePlaylist}
        showCheckbox
        selectedValues={this.state.currentlyIn}
        onSelect={this.handleSelectPlaylist}
        displayValue="title"
        placeholder="Select Collections"
        emptyRecordMsg="Head to Profile to make a new Collection"
        style={{
          chips: {whiteSpace: 'normal', background: Colors.primary},
          multiselectContainer: {color: Colors.primary},
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  playlists: state.user.playlists,
});
export default connect(mapStateToProps)(PlaylistBar);
