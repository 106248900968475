// @flow

import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import * as Notifications from '../Services/Notifications';
import UserActions from '../Redux/UserRedux';
import {connect} from 'react-redux';

type Props = {
  show: boolean,
  onHide: () => void,
  dispatch: ({}) => void,
};

class NotificationPrompt extends Component<Props> {
  handleAllow = async () => {
    // Segment Event
    window.analytics.track('Notification Prompt Enabled');
    this.props.dispatch(UserActions.setNotificationPrompted(true));
    const permissionResult = await Notifications.requestPermissions();
    this.props.onHide();
    if (permissionResult === 'granted') {
      const key = await Notifications.getPublicKey();
      const subscription = await Notifications.subscribeToNotifications(key);
      await Notifications.sendSubscriptionToServer(subscription);
    }
  };

  handleDecline = () => {
    // Segment Event
    window.analytics.track('Notification Prompt Declined');
    this.props.dispatch(UserActions.setNotificationPrompted(true));
    this.props.onHide();
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Body>
          <h6>
            Would you like to enable notifications? You'll receive personalized
            notifications about relevant activity on Wander.
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleDecline}>
            Decline
          </Button>
          <Button variant="success" onClick={this.handleAllow}>
            Enable
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect()(NotificationPrompt);
