// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import Cookies from 'js-cookie';

const LOCAL = false;

// our "constructor"
export default () => {
  const api = apisauce.create({
    baseURL: LOCAL
      ? 'http://127.0.0.1:8000/api/'
      : 'https://wander-io.herokuapp.com/api/',
    headers: {
      Authorization: `JWT ${Cookies.get('accessToken')}`,
    },
    // 15 sec timeout
    // timeout: 15000
  });

  const setAccessToken = (token) =>
    api.setHeader('Authorization', `JWT ${token}`);

  const getNotificationPublicKey = () => api.get('push/subscribe/');

  const subscribeToNotifications = (subscription) =>
    api.post('push/subscribe/', {subscription});

  const getNotifications = () => api.get('new_notifications/');

  const getEmailNotificationType = () => api.get('get_email_notif_type/');

  const changeEmailNotificationType = (notificationType) =>
    api.put('change_notification_type/', {
      notif_type: notificationType,
    });

  const markAsRead = (notificationID) =>
    api.put('notification/mark_as_read/', {
      notification_id: notificationID,
    });

  const deleteNotif = (notificationID) =>
    api.delete('notification/delete_notif/', {
      notification_id: notificationID,
    });

  const setPreferredEventTime = (time, timeZone) =>
    api.put('set_preferred_event_time/', {
      time,
      time_zone: timeZone,
    });

  const queryPreferredEventTime = () => api.get('query_preferred_event_time/');

  const getUser = (userID) =>
    api.get('get_user/', {
      user_id: userID,
    });

  const deleteUser = (userID) =>
    api.delete('delete_user/', {
      user_id: userID,
    });

  const createTestUser = (username) =>
    api.post('create_test_user/', {
      username,
    });

  const setUserRole = (userID, role) =>
    api.put('set_role/', {
      user_id: userID,
      role,
    });

  const setUserAdmin = (username) =>
    api.put('set_admin/', {
      username,
    });

  const getUserByUsername = (username) =>
    api.get('username_get_user/', {
      username,
    });

  const getTestUsers = () => api.get('get_test_users/');

  const getAdminUsers = () => api.get('get_admin_users/');

  const onboard = () => api.get('onboard/');

  const reset = () => api.get('reset_redux/');

  const endResetWeb = () => api.put('end_reset_web/');

  const endResetMob = () => api.put('end_reset_mobile/');

  const endOnboard = () => api.put('end_onboard/');

  const getSocialPosts = (limit, cursor) =>
    api.get('social/', {
      limit,
      cursor,
    });

  const getExplorePosts = (limit, existing_post_ids, tagIDs) =>
    api.post('explore/', {
      limit,
      existing_post_ids,
      tags: tagIDs ? tagIDs.join(',') : tagIDs,
    });

  const getUnreadPosts = () => api.get('get_unread/', {});

  const getUserPlaylists = (userID) =>
    api.get('get_playlists/', {
      user_id: userID,
    });

  const getMainUserPlaylists = () => api.get('get_user_playlists/');

  const getPlaylistPosts = (limit, playlistID, cursor) =>
    api.get('posts_in_playlist/', {
      limit,
      cursor_post_id: cursor,
      playlist_id: playlistID,
    });

  const getPostPlaylists = (postID) =>
    api.get('playlists_for_post/', {
      post_id: postID,
    });

  const createPost = (link, tagIDs, shoutoutUsers) =>
    api.post('new_post/', {
      link,
      tags: tagIDs,
      shoutout_user_ids: shoutoutUsers,
    });

  const createPostWithTitle = (
    title,
    link,
    embed_link,
    domain,
    tagIDs,
    shoutoutUsers,
  ) =>
    api.post('new_post_with_title/', {
      title,
      link,
      embed_link,
      domain,
      tags: tagIDs,
      shoutout_user_ids: shoutoutUsers,
    });

  const markUnread = (postID) =>
    api.post('mark_unread/', {
      post_id: postID,
    });

  const markRead = (postID, makePublic) =>
    api.delete('mark_read/', {
      post_id: postID,
      make_public: makePublic,
    });

  const createPlaylist = (title) => api.post('create_playlist/', {title});

  const getPlaylist = (playlistID) =>
    api.get('get_playlist/', {
      playlist_id: playlistID,
    });

  const getCommentsForPost = (postID) =>
    api.get('comments/', {
      post_id: postID,
    });

  const getTagsForPost = (postID) =>
    api.get('post_tags/', {
      post_id: postID,
    });

  const setTopTags = (userID) =>
    api.get('get_top_tags/', {
      user_id: userID,
    });

  const getTags = () => api.get('tags/');

  const getUserRatings = () => api.get('get_rated_posts/');

  const getRatingForPost = (postID) =>
    api.get('get_rating/', {
      post_id: postID,
    });

  const setRatingForPost = (postID, rating) =>
    api.post('set_rating/', {
      rating,
      post_id: postID,
    });

  const removeRatingForPost = (postID) =>
    api.delete('remove_rating/', {
      post_id: postID,
    });

  const getPost = (postID) =>
    api.get('get_post/', {
      post_id: postID,
    });

  const addToPlaylist = (playlistID, postID) =>
    api.put('add_to_playlist/', {
      playlist_id: playlistID,
      post_id: postID,
    });

  const removeFromPlaylist = (playlistID, postID) =>
    api.delete('remove_from_playlist/', {
      playlist_id: playlistID,
      post_id: postID,
    });

  const getLengthofPlaylist = (playlistID) =>
    api.get('get_playlist_length/', {
      playlist_id: playlistID,
    });

  const removeFromUnread = (postID) =>
    api.delete('remove_unread/', {
      post_id: postID,
    });

  const createComment = (postID, commentText) =>
    api.post('new_comment/', {
      post_id: postID,
      text: commentText,
    });

  const authenticate = (email, password) =>
    api.post('auth/', {email, password});

  const createPageAuthenticate = (password) =>
    api.get('auth_create/', {password});

  const signUp = (email, password, firstName, lastName, username, referredBy) =>
    api.post('add_user/', {
      email,
      password,
      username,
      firstname: firstName,
      lastname: lastName,
      referredBy: referredBy,
    });

  const setPassword = (oldPassword, newPassword) =>
    api.put('set_password/', {
      old_password: oldPassword,
      new_password: newPassword,
    });

  const followUser = (toFollowID) =>
    api.post('new_follow/', {
      user_to_follow: toFollowID,
    });

  const unfollowUser = (toUnfollowID) =>
    api.delete('unfollow/', {
      user_to_unfollow: toUnfollowID,
    });

  const getFollowStatus = (otherUserID) =>
    api.get('following/', {
      following_user: otherUserID,
    });

  const getFollowingUsers = () => api.get('get_following/');

  const shoutoutUsers = (userIDs, postID) =>
    api.post('shoutout_users/', {
      post_id: postID,
      user_ids: userIDs,
    });

  const getUsersToFollow = () => api.get('users_to_follow/');

  const followFromTags = (selectedTags, userID) =>
    api.post('follow_from_tags/', {
      tag_ids: selectedTags,
      user_id: userID,
    });

  const deleteComment = (comment_identifier) =>
    api.delete('delete_comment/', {
      comment_id: comment_identifier,
    });

  const deleteUserPost = (postID) =>
    api.delete('delete_post/', {
      post_id: postID,
    });

  const getLatestPosts = (userID) =>
    api.get('latest_posts/', {
      user_id: userID,
    });

  const getProfileStats = (userID) =>
    api.get('get_stats/', {
      user_id: userID,
    });

  const editBio = (bio_in) =>
    api.put('set_bio/', {
      bio: bio_in,
    });

  const submitFeedback = (feedbackText) =>
    api.post('feedback/', {
      text: feedbackText,
    });

  const setProfPic = (profilePic) => {
    const form = new FormData();
    form.append('profile_pic', profilePic);
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return api.post('set_profile_pic/', form, {headers});
  };

  const getProfileFollowing = (userID) =>
    api.get('get_followers_following/', {
      user_id: userID,
    });

  const changeStarred = (postID) =>
    api.patch('change_starred/', {
      post_id: postID,
    });

  const scheduleUnread = (postID, date) =>
    api.patch('schedule_unread/', {
      post_id: postID,
      schedule_date: date,
    });

  const getNativeCode = (post_id) =>
    api.get('get_native_code/', {
      post_id,
    });

  const getNewsletterFollowing = () => api.get('get_newsletter_following/');

  const addUnread = (link) =>
    api.post('add_to_unread/', {
      link,
    });

  const addUnreadWithTitle = (title, link, embed_link, domain) =>
    api.post('add_to_unread_with_title/', {
      title,
      link,
      embed_link,
      domain,
    });

  const markReadPrivate = (post_id, tags) =>
    api.delete('mark_read_public/', {
      post_id,
      tags,
    });

  const forgetPassword = (email) =>
    api.put('forget_password/', {
      email,
    });

  const getUsersToFollowSideCard = () => api.get('users_to_follow_sidecard/');

  return {
    getSocialPosts,
    getExplorePosts,
    getUserPlaylists,
    getMainUserPlaylists,
    getPlaylistPosts,
    getUnreadPosts,
    getPostPlaylists,
    createPost,
    createPostWithTitle,
    markUnread,
    markRead,
    createPlaylist,
    getPlaylist,
    getCommentsForPost,
    getTags,
    setTopTags,
    getTagsForPost,
    getUserRatings,
    getRatingForPost,
    setRatingForPost,
    addToPlaylist,
    removeFromPlaylist,
    getLengthofPlaylist,
    removeFromUnread,
    createComment,
    authenticate,
    followUser,
    unfollowUser,
    getFollowStatus,
    followFromTags,
    getUsersToFollow,
    deleteUserPost,
    setAccessToken,
    getUser,
    deleteUser,
    createTestUser,
    setUserRole,
    setUserAdmin,
    getUserByUsername,
    getTestUsers,
    getAdminUsers,
    onboard,
    endOnboard,
    signUp,
    setPassword,
    deleteComment,
    getLatestPosts,
    getProfileStats,
    editBio,
    submitFeedback,
    createPageAuthenticate,
    getNotificationPublicKey,
    subscribeToNotifications,
    getNotifications,
    getEmailNotificationType,
    changeEmailNotificationType,
    markAsRead,
    deleteNotif,
    setProfPic,
    getProfileFollowing,
    getFollowingUsers,
    shoutoutUsers,
    getPost,
    changeStarred,
    scheduleUnread,
    removeRatingForPost,
    getNativeCode,
    setPreferredEventTime,
    queryPreferredEventTime,
    getNewsletterFollowing,
    addUnread,
    addUnreadWithTitle,
    markReadPrivate,
    reset,
    endResetWeb,
    endResetMob,
    forgetPassword,
    getUsersToFollowSideCard,
  };
};
