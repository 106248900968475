// @flow

import React, {Component} from 'react';
import PostComponent from '../Components/Post';
import {connect} from 'react-redux';
import type {Post} from '../Config/Types';
import _ from 'lodash';
import API from '../Services/API';
import PlaylistActions from '../Redux/PlaylistRedux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Props = {
  posts: Post[],
  title: string,
  unreadPosts: Post[],
};

class Playlist extends Component<Props> {
  static fetch(match, location, options) {
    // console.log("helloyo")
    options.dispatch(PlaylistActions.setCurrentTitle(''));
    options.dispatch(PlaylistActions.setCurrentPosts([]));

    const api = API();
    return api.getPlaylistPosts(10, match.params.id, null).then((response) => {
      if (response.ok) {
        options.dispatch(PlaylistActions.setCurrentTitle(response.data.title));
        options.dispatch(PlaylistActions.setCurrentPosts(response.data.posts));
      }
    });
  }

  constructor(props: Props) {
    super(props);
    this.api = API();
    this.fetchThreshold = -Infinity;
    this.state = {
      loadingPosts: false,
    };
    // console.log("helconstructorloyo")
  }

  scrollRef = React.createRef();

  componentDidMount() {
    // Segment pageview call
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.onScroll);
    window.analytics.page('Collection', this.props);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  componentDidUpdate() {
    if (this.scrollRef.current && this.props.posts.length > 0) {
      this.fetchThreshold =
        (this.scrollRef.current.scrollHeight / this.props.posts.length) * 10;
    }
  }

  onScroll = _.throttle(
    (info: any) => {
      if (
        !this.state.loadingPosts &&
        this.scrollRef.current.scrollHeight - window.scrollY <=
          this.fetchThreshold
      ) {
        this.loadMorePosts();
      }
    },
    500,
    {
      trailing: true,
      leading: false,
    },
  );

  loadMorePosts = () => {
    this.setState({loadingPosts: true});
    const cursorPost = this.props.posts[this.props.posts.length - 1];
    const items = window.location.href.split('/');
    this.api
      .getPlaylistPosts(20, items[items.length - 1], cursorPost.post_id)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(
            PlaylistActions.appendPlaylistPosts(response.data.posts),
          );
        }
        this.setState({loadingPosts: false});
      });
  };

  renderPosts = () => {
    if (!this.props.posts) return null;
    return this.props.posts.map<any>((post: any) => {
      let isUnread = false;
      this.props.unreadPosts.forEach((unreadPost) => {
        if (
          unreadPost.post_id === post.post_id &&
          unreadPost.clear_date == null
        ) {
          isUnread = true;
        }
      });
      return (
        <PostComponent
          comments={post.comments}
          rating={post.rating}
          tags={post.tags}
          img={post.img}
          title={post.title}
          description={post.description}
          postUser={post.user}
          link={post.link}
          post_id={post.post_id}
          key={post.post_id}
          rating_average={post.rating_average}
          explore_page={false}
          public={post.public}
          markedUnread={isUnread}
          embed_link={post.embed_link}
          estimatedTime={post.estimated_time}
          native={post.native}
        />
      );
    });
  };

  render() {
    return (
      <div ref={this.scrollRef}>
        <Row className="mx-0 pt-3">
          <Col xs={12} className="text-center mb-3">
            <h1 style={{fontWeight: '300'}} className="mb-0">
              {this.props.title}
            </h1>
          </Col>
        </Row>
        {this.renderPosts()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  posts: state.playlist.posts,
  title: state.playlist.title,
  unreadPosts: state.unread.posts,
});
export default connect(mapStateToProps)(Playlist);
