// @flow

import React, {Component} from 'react';
import {Button, Modal, Row, Col, Card} from 'react-bootstrap';
import API from '../Services/API';
import UnreadActions from '../Redux/UnreadRedux';
import Colors from '../Config/Colors';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import './Styles/CreateLink.css';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import CreatePost from './CreatePost';
import defaultWander from '../static/DefaultWander.png';
import Image from 'react-bootstrap/Image';

type Props = {
  show: boolean,
  onHide: () => void,
  dispatch: ({}) => void,
  onShow: () => void,
  modal: boolean,
};

type State = {
  link: string,
  title: string,
  showAdding: Boolean,
  disableButtons: Boolean,
  status: string,
  percent: string,
  showCreatePost: Boolean,
  needTitle: Boolean,
};

class CreateLink extends Component<Props, State> {
  api: any;
  constructor(props: Props) {
    super(props);
    this.api = API();
    this.state = {
      link: '',
      title: '',
      showAdding: false,
      disableButtons: false,
      status: 'Adding to Unread...',
      percent: '0%',
      showCreatePost: false,
      returnedLinkInfo: {},
      needTitle: false,
    };
  }

  handleDismiss = () => {
    this.props.onHide();
    this.setState({
      link: '',
      title: '',
      showAdding: false,
      disableButtons: false,
      status: 'Adding to Unread...',
      percent: '0%',
      returnedLinkInfo: {},
      needTitle: false,
    });
  };

  handlePostClick = () => {
    if (this.state.modal) {
      this.props.onHide();
    }
    this.setState({
      showCreatePost: true,
      showAdding: false,
      disableButtons: false,
      status: 'Adding to Unread...',
      percent: '0%',
    });
  };

  handlePostDismiss = () => {
    this.setState({
      link: '',
      title: '',
      showAdding: false,
      disableButtons: false,
      status: 'Adding to Unread...',
      percent: '0%',
      showCreatePost: false,
    });
  };

  handlePostBack = () => {
    this.setState({
      showAdding: false,
      disableButtons: false,
      status: 'Adding to Unread...',
      percent: '0%',
      showCreatePost: false,
    });
    this.props.onShow();
  };

  handleLinkTextChange = (event: any) => {
    this.setState({link: event.target.value});
  };

  handleTitleChange = (event: any) => {
    this.setState({title: event.target.value});
  };

  handleUnread = () => {
    if (!this.state.needTitle) {
      if (this.state.link.length > 0) {
        this.setState({
          showAdding: true,
          disableButtons: true,
          percent: '50%',
        });
        window.analytics.track('Unread Post Created', this.state);
        this.api
          .addUnread(this.state.link)
          .then((response) => {
            if (response.ok && response.status === 201) {
              if (window.location.pathname.includes('unread')) {
                this.api
                  .getUnreadPosts()
                  .then((response) => {
                    if (response.ok) {
                      this.props.dispatch(
                        UnreadActions.setUnreadPosts(response.data),
                      );
                    }
                  })
                  .catch((error) => console.warn(error));
              }
              this.setState({
                status: 'Read on Your Private Unread!',
                percent: '100%',
              });
            } else if (response.ok) {
              this.setState({
                status: 'Unread Post Needs Title',
                percent: '0%',
                returnedLinkInfo: response.data,
                needTitle: true,
                disableButtons: false,
              });
            } else {
              this.setState({
                status: 'Link Invalid',
                percent: '0%',
                disableButtons: false,
              });
            }
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              status: 'Link Invalid',
              percent: '0%',
              disableButtons: false,
            });
          });
      } else {
        this.setState({
          showAdding: true,
          disableButtons: false,
          status: 'Link Required',
          percent: '0%',
        });
      }
    } else {
      if (this.state.title.length > 0) {
        this.setState({
          status: 'Adding to Unread with Title...',
          percent: '50%',
        });
        window.analytics.track('Unread Post with Title Created', this.state);
        this.api
          .addUnreadWithTitle(
            this.state.title,
            this.state.returnedLinkInfo.link,
            this.state.returnedLinkInfo.embed_link,
            this.state.returnedLinkInfo.domain,
          )
          .then((response) => {
            if (response.ok) {
              if (window.location.pathname.includes('unread')) {
                this.api
                  .getUnreadPosts()
                  .then((response) => {
                    if (response.ok) {
                      this.props.dispatch(
                        UnreadActions.setUnreadPosts(response.data),
                      );
                    }
                  })
                  .catch((error) => console.warn(error));
              }
              this.setState({
                status: 'Read on Your Private Unread!',
                percent: '100%',
              });
            } else {
              this.setState({
                status: 'Unable to Create Post',
                percent: '0%',
                disableButtons: true,
              });
            }
          })
          .catch((error) => {
            console.warn(error);
            this.setState({
              status: 'Unable to Create Post',
              percent: '0%',
              disableButtons: true,
            });
          });
      } else {
        this.setState({
          status: 'Title Required',
        });
      }
    }
  };

  renderMessage = () => {
    if (this.state.percent === '100%') {
      return (
        <Link to="/unread" href="/unread" onClick={this.handleDismiss}>
          <p className="mb-0" style={{fontWeight: '400', color: 'gray'}}>
            {this.state.status}
          </p>
        </Link>
      );
    }
    return (
      <p className="mb-0" style={{fontWeight: '400', color: 'gray'}}>
        {this.state.status}
      </p>
    );
  };

  render() {
    if (this.props.modal) {
      return (
        <div>
          <Modal show={this.props.show} onHide={this.handleDismiss} centered>
            <Modal.Body
              className={this.state.showAdding ? 'px-0 pb-0' : 'px-0'}>
              <Row className="px-3">
                <Col xs={12} className="text-right">
                  <CloseRoundedIcon
                    style={{cursor: 'pointer', color: 'gray'}}
                    onClick={this.handleDismiss}
                  />
                </Col>
                <Col
                  xs={12}
                  className={this.state.needTitle ? 'd-none' : 'displayLink'}>
                  <div className="form__group field">
                    <input
                      type="input"
                      onChange={this.handleLinkTextChange}
                      className="form__field"
                      value={this.state.link}
                      placeholder="Paste Link"
                      name="addLink"
                      style={{zIndex: '1'}}
                    />
                    <label
                      htmlFor="addLink"
                      className="form__label"
                      style={{zIndex: '0'}}>
                      Paste Link
                    </label>
                  </div>
                </Col>
                <Col
                  xs={12}
                  className={this.state.needTitle ? 'displayTitle' : 'd-none'}>
                  <div className="form__group field">
                    <input
                      type="input"
                      onChange={this.handleTitleChange}
                      className="form__field"
                      value={this.state.title}
                      placeholder="Add Title"
                      name="addTitle"
                      style={{zIndex: '1'}}
                    />
                    <label
                      htmlFor="addTitle"
                      className="form__label"
                      style={{zIndex: '0'}}>
                      Add Title
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="text-center mt-3 px-3">
                <Col className="text-right pr-1">
                  <Button
                    disabled={this.state.disableButtons}
                    onClick={this.handleUnread}
                    className="shadow-none"
                    size="sm"
                    variant="primary">
                    Add to Unread
                  </Button>
                </Col>
                <Col className="text-left pl-1">
                  <Button
                    disabled={this.state.disableButtons || this.state.needTitle}
                    onClick={this.handlePostClick}
                    className="shadow-none"
                    size="sm"
                    variant="primary">
                    Create a Post
                  </Button>
                </Col>
              </Row>
              <Row
                className={
                  this.state.showAdding ? 'd-block mt-3 px-0' : 'd-none'
                }>
                <Col xs={12} className="text-center">
                  {this.renderMessage()}
                  <hr
                    style={{
                      borderColor: Colors.primary,
                      backgroundColor: Colors.primary,
                      width: this.state.percent,
                    }}
                    className={
                      this.state.percent === '0%'
                        ? 'notLoading pb-0 px-0 mx-0 mb-0'
                        : 'loading pb-0 px-0 mx-0 mb-0'
                    }
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          <CreatePost
            postLink={this.state.link}
            show={this.state.showCreatePost}
            changeLink={this.handleLinkTextChange}
            goBack={this.handlePostBack}
            dismiss={this.handlePostDismiss}
          />
        </div>
      );
    } else {
      let imageLink = defaultWander;
      if (this.props.user_img !== null) {
        imageLink = this.props.user_img;
      }
      return (
        <div>
          <Row className="mb-4 justify-content-center">
            <Col xs={12} className="post-inner">
              <Card className="shadow-sm" style={{borderRadius: '10px'}}>
                <Row className="mx-0 pt-3 justify-content-center">
                  <Col xs="auto" className="pr-0 my-auto d-none d-sm-block">
                    <Image
                      src={imageLink}
                      roundedCircle
                      className="profileIMG"
                      width="45"
                      height="45"
                    />
                  </Col>
                  <Col
                    xs={10}
                    className={this.state.needTitle ? 'd-none' : 'displayLink'}>
                    <div className="form__group field">
                      <input
                        type="input"
                        onChange={this.handleLinkTextChange}
                        className="form__field"
                        value={this.state.link}
                        placeholder="Paste Link"
                        name="addLink"
                        style={{zIndex: '1'}}
                      />
                      <label
                        htmlFor="addLink"
                        className="form__label"
                        style={{zIndex: '0'}}>
                        Paste Link
                      </label>
                    </div>
                  </Col>
                  <Col
                    xs={10}
                    className={
                      this.state.needTitle ? 'displayTitle' : 'd-none'
                    }>
                    <div className="form__group field">
                      <input
                        type="input"
                        onChange={this.handleTitleChange}
                        className="form__field"
                        value={this.state.title}
                        placeholder="Add Title"
                        name="addTitle"
                        style={{zIndex: '1'}}
                      />
                      <label
                        htmlFor="addTitle"
                        className="form__label"
                        style={{zIndex: '0'}}>
                        Add Title
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row className="text-center mt-3 px-3 mb-3">
                  <Col className="text-right pr-1">
                    <Button
                      disabled={this.state.disableButtons}
                      onClick={this.handleUnread}
                      className="shadow-none"
                      size="sm"
                      variant="primary"
                      style={{borderRadius: '10px', fontSize: '13px'}}>
                      Add to Unread
                    </Button>
                  </Col>
                  <Col className="text-left pl-1">
                    <Button
                      disabled={
                        this.state.disableButtons || this.state.needTitle
                      }
                      onClick={this.handlePostClick}
                      className="shadow-none"
                      size="sm"
                      variant="primary"
                      style={{borderRadius: '10px', fontSize: '13px'}}>
                      Create a Post
                    </Button>
                  </Col>
                </Row>
                <Row
                  className={
                    this.state.showAdding ? 'd-block mt-3 px-0' : 'd-none'
                  }>
                  <Col xs={12} className="text-center">
                    {this.renderMessage()}
                    <hr
                      style={{
                        borderColor: Colors.primary,
                        backgroundColor: Colors.primary,
                        width: this.state.percent,
                      }}
                      className={
                        this.state.percent === '0%'
                          ? 'notLoading pb-0 px-0 mx-0 mb-0'
                          : 'loading pb-0 px-0 mx-0 mb-0'
                      }
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <CreatePost
            postLink={this.state.link}
            show={this.state.showCreatePost}
            changeLink={this.handleLinkTextChange}
            goBack={this.handlePostBack}
            dismiss={this.handlePostDismiss}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  profileUser: {
    profilePic: state.profile.profile_pic,
  },
  user_img: state.user.user_img,
});

export default connect(mapStateToProps)(CreateLink);
