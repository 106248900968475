import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setPost: ['post'],
  setPostRating: ['post', 'rate', 'rate_count'],
  setPostCode: ['post_code'],
  newComment: ['comment'],
  removeComment: ['commentID'],
  reset: [],
});

export const PostTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  key: new Date(),
  link: '',
  embed_link: '',
  comments: [],
  rating: 0,
  rating_average: 0,
  rating_count: 0,
  tags: [],
  title: '',
  domain: '',
  post_id: 0,
  post_code: '',
  img: null,
  description: '',
});

/* ------------- Reducers ------------- */

export const setPost = (state, {post}) =>
  state.merge({
    link: post.link,
    embed_link: post.embed_link,
    comments: post.comments,
    rating: post.rating,
    rating_average: post.rating_average,
    rating_count: post.rating_count,
    tags: post.tags,
    title: post.title,
    post_id: post.post_id,
    img: post.img,
    description: post.description,
  });

export const setPostRating = (state, {post, rate, rate_count}) =>
  state.merge({
    link: post.link,
    embed_link: post.embed_link,
    comments: post.comments,
    rating: rate,
    rating_average: post.rating_average,
    rating_count: rate_count,
    tags: post.tags,
    title: post.title,
    post_id: post.post_id,
    img: post.img,
    description: post.description,
  });

export const setPostCode = (state, {post_code}) =>
  state.merge({
    post_code,
  });

export const newComment = (state, {comment}) =>
  state.merge({
    comments: state.comments.concat([comment]),
  });

export const removeComment = (state, {commentID}) =>
  state.merge({
    comments: state.comments.filter(
      (comment) => comment.comment_id !== commentID,
    ),
  });

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_POST]: setPost,
  [Types.SET_POST_RATING]: setPostRating,
  [Types.SET_POST_CODE]: setPostCode,
  [Types.NEW_COMMENT]: newComment,
  [Types.REMOVE_COMMENT]: removeComment,
  [Types.RESET]: reset,
});
