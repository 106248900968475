// @flow

import React, {Component} from 'react';
import {Card, Row, Col, Button, Modal} from 'react-bootstrap';
// new dropdown
import {DropdownMultiple} from 'reactjs-dropdown-component';
import Comments from './Comments';
import type {Comment, User, Tag, Playlist} from '../Config/Types';
// import {withStyles} from '@material-ui/styles';
import FeedActions from '../Redux/FeedRedux';
import PlaylistActions from '../Redux/PlaylistRedux';
import UserActions from '../Redux/UserRedux';
import UnreadActions from '../Redux/UnreadRedux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import API from '../Services/API';
import TagComponent from './Tag';
import './Styles/Post.css';
// icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UpvoteIcon from '@material-ui/icons/EmojiObjectsOutlined';
import UpvoteFilledIcon from '@material-ui/icons/EmojiObjects';
import CommentIcon from '@material-ui/icons/Comment';
// import Badge from '@material-ui/core/Badge';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkFilledIcon from '@material-ui/icons/Bookmark';
import ReportIcon from '@material-ui/icons/ReportProblem';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Dropdown from 'react-bootstrap/Dropdown';
import {toast} from 'react-toastify';
// import defaultPic from '../static/placeholder.png'
import wsj from '../static/wsj.png';
import bloomberg from '../static/bloomberg.png';
import Colors from '../Config/Colors';
import Image from 'react-bootstrap/Image';
import defaultWander from '../static/DefaultWander.png';
import _ from 'lodash';

// const StyledBadge = withStyles(() => ({
//   badge: {
//     fontWeight: 'bold',
//     fontFamily: 'Helvetica Neue',
//   },
// }))(Badge);

type Props = {
  user: {
    name: string,
    id: number,
  },
  post_id: number,
  postUser: User,
  title: string,
  domain: string,
  description: string,
  tags: Tag[],
  comments: Comment[],
  playlists: Playlist[],
  img: string,
  link: string,
  embed_link: string,
  rating?: number,
  rating_average: number,
  rating_count: number,
  explore_page: boolean,
  isFollowing: boolean,
  dispatch: ({}) => void,
  activityHeader: {
    activity_type: string,
    user1: User,
    user2: User,
  },
  history: any,
  public: boolean,
  markedUnread: boolean,
  estimatedTime: number,
  native: boolean,
};
type State = {
  currentlyIn: Playlist[],
  showComments: boolean,
  showFollowBtn: boolean,
  showDeleteConfirm: boolean,
  isUnread: boolean,
  isRated: boolean,
  isPlaylistSelectionReady: boolean,
};

class Post extends Component<Props, State> {
  api: any;

  constructor(props: Props) {
    super(props);
    this.api = API();
    const postPlaylists = this.props.playlists.filter((playlist) => {
      if (!playlist.posts) {
        return false;
      }
      for (const post of playlist.posts) {
        if (post.post_id === this.props.post_id) return true;
      }
      return false;
    });
    // this.playlistSelectionRef = React.createRef()
    this.state = {
      currentlyIn: postPlaylists,
      showComments: true,
      showFollowBtn: false,
      showDeleteConfirm: false,
      inUnread: this.props.markedUnread,
      isPlaylistSelectionReady: false,
    };
  }

  componentDidMount() {
    if (
      this.props.explore_page &&
      this.props.postUser &&
      this.props.user.id !== this.props.postUser.user_id
    ) {
      if (this.props.isFollowing === undefined) {
        this.api
          .getFollowStatus(this.props.postUser.user_id)
          .then((response) => {
            if (response.ok && response.data.following !== undefined) {
              this.props.dispatch(
                UserActions.setFollowStatus(
                  this.props.postUser.user_id,
                  response.data.following,
                ),
              );
            }
          })
          .catch((error) => console.warn(error));
      }
      this.setState({showFollowBtn: true});
    }

    // const initialPlaylists = this.toDropdownJSON(this.state.currentlyIn);
    // this.handleDropdownInitialization(this.playlistSelectionRef, initialPlaylists)
    this.setState({
      isPlaylistSelectionReady: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      isPlaylistSelectionReady: false,
    });
  }

  handleDropdownInitialization = (ref, initialPlaylists) => {
    ref.current.state.selectedItems = initialPlaylists;
    if (initialPlaylists.length === 0) {
      ref.current.state.title = 'Add to collections';
    } else if (initialPlaylists.length === 1) {
      ref.current.state.title = '1 collection';
    } else {
      ref.current.state.title = initialPlaylists.length + ' collections';
    }
  };

  // weird issues
  handleSelect = (selectedItem) => {
    // Segment Event
    window.analytics.track('Post Added To Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.api
      .addToPlaylist(selectedItem.playlist_id, this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.api
            .getMainUserPlaylists()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(UserActions.setPlaylists(response.data));
              }
            })
            .catch((error) => console.warn(error));
        }
      })
      .catch((error) => console.warn(error));
  };

  // weird issues
  handleRemove = (selectedItem) => {
    // Segment Event
    window.analytics.track('Post Removed From Collection', {
      ...this.props,
      playlist: {...selectedItem},
    });
    this.api
      .removeFromPlaylist(selectedItem.playlist_id, this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.api
            .getMainUserPlaylists()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(UserActions.setPlaylists(response.data));
              }
            })
            .catch((error) => console.warn(error));
        }
      })
      .catch((error) => console.warn(error));
  };

  handleCommentsToggle = () => {
    // Segment Event
    window.analytics.track('Comment Toggled', this.props);
    this.setState({showComments: !this.state.showComments});
  };

  handleRatingChange = (rating: number) => {
    window.analytics.track('Rating Set', {rating: rating, ...this.props});
    if (rating != null) {
      this.api
        .setRatingForPost(this.props.post_id, rating)
        .catch((error) => console.warn(error));
      this.props.dispatch(FeedActions.updateRating(this.props.post_id, rating));
      this.props.dispatch(
        PlaylistActions.updateRatingPlaylist(this.props.post_id, rating),
      );
      this.props.dispatch(UserActions.addRating(this.props.post_id));
    } else {
      this.api
        .removeRatingForPost(this.props.post_id)
        .catch((error) => console.warn(error));
      this.props.dispatch(FeedActions.removeRating(this.props.post_id));
      this.props.dispatch(UserActions.removeRating(this.props.post_id));
    }
  };

  confirmDelete = () => {
    this.setState({showDeleteConfirm: true});
  };

  cancelDelete = () => this.setState({showDeleteConfirm: false});

  handleDelete = (e: any) => {
    e.preventDefault();
    // Segment Event
    window.analytics.track('Post Deleted', this.props);
    this.api
      .deleteUserPost(this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.deleteSinglePost(this.props.post_id));
          this.props.dispatch(
            PlaylistActions.deletePlaylistPost(this.props.post_id),
          );
          this.props.dispatch(UnreadActions.deletePost(this.props.post_id));
          this.cancelDelete();
          toast('The post has been deleted.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        console.warn(error);
        this.cancelDelete();
        toast('The post could not be deleted.', {containerId: 'N'});
      });
  };

  updateSocialPosts = () => {
    this.api
      .getSocialPosts()
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(FeedActions.setSocialPosts(response.data));
        }
      })
      .catch((error) => console.warn(error));
  };

  handleFollowUnfollow = (e) => {
    e.preventDefault();
    if (this.props.isFollowing) {
      // Segment Event
      window.analytics.track('User Unfollowed', this.props.postUser);
      this.api.unfollowUser(this.props.postUser.user_id).then((response) => {
        if (response.ok) {
          this.props.dispatch(
            UserActions.setFollowStatus(this.props.postUser.user_id, false),
          );
          this.props.dispatch(
            UserActions.setFollowStatusForUser(
              this.props.postUser.user_id,
              false,
            ),
          );
          this.updateSocialPosts();
        }
      });
    } else {
      // Segment Event
      window.analytics.track('User Followed', this.props.postUser);
      this.api.followUser(this.props.postUser.user_id).then((response) => {
        if (response.ok) {
          this.props.dispatch(
            UserActions.setFollowStatus(this.props.postUser.user_id, true),
          );
          this.props.dispatch(
            UserActions.setFollowStatusForUser(
              this.props.postUser.user_id,
              true,
            ),
          );
          this.updateSocialPosts();
        }
      });
    }
  };

  onCommentSubmit = (comment: string) => {
    // Segment Event
    window.analytics.track('Comment Created', {
      post: this.props,
      comment: comment,
    });

    this.api
      .createComment(this.props.post_id, comment)
      .then((response) => {
        if (response.ok) {
          const newComment = {
            user: {
              full_name: this.props.user.name,
              profile_pic: response.data[0].user.profile_pic,
              user_id: this.props.user.id,
            },
            comment_text: comment,
            comment_time: response.data[0].comment_time,
            comment_id: response.data[0].comment_id,
          };
          this.props.dispatch(
            FeedActions.addComment(this.props.post_id, newComment),
          );
        }
      })
      .catch((error) => console.warn(error));
  };

  onCommentDelete = (comment_id: number) => {
    // Segment Event
    window.analytics.track('Comment Deleted', {
      post: this.props,
      comment_id: comment_id,
    });
    this.api
      .deleteComment(comment_id)
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(
            FeedActions.deleteComment(this.props.post_id, comment_id),
          );
          this.props.dispatch(
            PlaylistActions.deleteComment(this.props.post_id, comment_id),
          );
          toast('The comment has been deleted.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  renderConfirmDelete() {
    return (
      <Modal
        show={this.state.showDeleteConfirm}
        onHide={() => this.setState({showDeleteConfirm: false})}
        centered>
        <div className="modal-header">
          <h5>Confirm Delete</h5>
        </div>
        <div className="modal-body">
          <p>Are you sure you want to delete this post?</p>
        </div>
        <div className="modal-footer">
          <Button variant="primary" onClick={this.cancelDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleDelete}>
            Confirm
          </Button>
        </div>
      </Modal>
    );
  }

  renderFollow = () => {
    if (this.state.showFollowBtn && this.props.isFollowing) {
      return (
        <Button
          variant="outline-primary shadow-none"
          size="sm"
          className="follow-btn"
          onClick={this.handleFollowUnfollow}
          hidden={!this.state.showFollowBtn}>
          Following
        </Button>
      );
    } else if (this.state.showFollowBtn && !this.props.isFollowing) {
      return (
        <Button
          variant="primary shadow-none"
          size="sm"
          className="follow-btn"
          onClick={this.handleFollowUnfollow}
          hidden={!this.state.showFollowBtn}>
          Follow
        </Button>
      );
    }
  };

  renderTags = () => {
    if (this.props.tags && this.props.tags.length > 0) {
      const tags = [];

      for (const tag of this.props.tags) {
        tags.push(
          <TagComponent
            className="mr-1 ml-0 my-auto"
            id={tag.tag_id}
            key={tag.tag_id}
            color={tag.hex_bg_color}
            text={tag.text}
            textColor={tag.hex_text_color}
          />,
        );
      }
      return tags;
    }
    return null;
  };

  markUnread = () => {
    // Segment Event
    window.analytics.track('Post Marked Unread', this.props);
    this.api
      .markUnread(this.props.post_id)
      .then((response) => {
        if (response.ok) {
          this.api
            .getUnreadPosts()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(
                  UnreadActions.setUnreadPosts(response.data),
                );
              }
            })
            .catch((error) => console.warn(error));
          toast('Successfully added to your Unread.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        toast('An error occurred when adding to Unread.', {containerId: 'N'});
        console.warn(error);
      });
  };

  markRead = () => {
    // Later, users will have the option to share publicly or keep privately
    // in a playlist (or delete altogether)
    // basically check if public, if not ask user what they want to do
    // Segment Event
    window.analytics.track('Post Marked Read', this.props);
    this.api
      .markRead(this.props.post_id, true)
      .then((response) => {
        if (response.ok) {
          this.api
            .getUnreadPosts()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(
                  UnreadActions.setUnreadPosts(response.data),
                );
              }
            })
            .catch((error) => console.warn(error));

          this.api
            .getSocialPosts()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(FeedActions.setSocialPosts(response.data));
              }
            })
            .catch((error) => console.warn(error));

          this.api
            .getExplorePosts()
            .then((response) => {
              if (response.ok) {
                this.props.dispatch(FeedActions.setExplorePosts(response.data));
              }
            })
            .catch((error) => console.warn(error));

          toast('Successfully marked post as Read.', {containerId: 'N'});
        }
      })
      .catch((error) => {
        toast('An error occurred when adding to Unread.', {containerId: 'N'});
        console.warn(error);
      });
  };

  // Renders everything above the actual post content in a two-column format
  // will be rendered on medium to xl screens to make use of screen real estate
  renderTwoColumnPostHeader(postUserImg) {
    return (
      <Row className="my-1 justify-content-between">
        {/* Profile pic, name, tags */}
        <Col xs="auto" className="pl-0 my-auto post-header">
          <Row className="mx-0">
            <Col xs="auto" className="pr-0 my-auto">
              <Image
                src={postUserImg}
                roundedCircle
                className="profileIMG"
                width="45"
                height="45"
              />
            </Col>
            <Col xs="auto" className="mx-0 pl-2 overflow-auto">
              <Col xs={12} className="my-auto px-0 pb-1 overflow-auto">
                {this.renderHeader()}
              </Col>
              <div className="d-flex flex-row my-auto overflow-auto">
                {this.renderTags()}
              </div>
            </Col>
          </Row>
        </Col>
        {/* post funcionality md->xl screens */}
        <Col xs="auto" className="pl-0 my-auto post-header">
          <Col xs="auto" className="pr-0">
            <Row className="mx-0 pt-2">
              <Col xs="auto" className="my-auto pl-0 pr-3">
                {this.renderPlaylistSelectionBtn()}
              </Col>
              <Col
                xs="auto"
                className={
                  this.state.showFollowBtn ? 'my-auto px-0 pr-3' : 'd-none'
                }>
                {this.renderFollow()}
              </Col>
              <Col
                xs="auto"
                style={{cursor: 'pointer'}}
                className="my-auto px-0">
                {this.state.inUnread ? (
                  <BookmarkFilledIcon
                    className="unread-icon"
                    onClick={() =>
                      this.setState({inUnread: false}, () => this.markRead())
                    }
                  />
                ) : (
                  <BookmarkBorderIcon
                    className="unread-icon"
                    onClick={() =>
                      this.setState({inUnread: true}, () => this.markUnread())
                    }
                  />
                )}
              </Col>
              {this.renderDropdown()}
            </Row>
          </Col>
        </Col>
        {this.renderConfirmDelete()}
      </Row>
    );
  }

  renderDropdown = () => {
    if (this.props.user.id === this.props.postUser.user_id) {
      return (
        <Dropdown hidden={false}>
          <Dropdown.Toggle
            variant="outline-primary shadow-none"
            className="toggler pl-2 pr-0">
            <MoreVertIcon style={{color: Colors.grey, fontSize: '28px'}} />
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight>
            <Dropdown.Item onClick={this.confirmDelete}>
              <Row>
                <Col xs={12} className="post-option px-0 py-0">
                  <DeleteOutlineIcon className="mb-1" />{' '}
                  <p className="my-auto">&nbsp;Delete</p>
                </Col>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown hidden={false}>
          <Dropdown.Toggle
            variant="outline-primary shadow-none"
            className="toggler pl-1 pr-0">
            <MoreVertIcon style={{color: Colors.grey, fontSize: '28px'}} />
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight>
            <Dropdown.Item
              href={`mailto:justin@thinkwander.com?cc=yash@thinkwander.com
&subject=Reporting Post ${this.props.post_id}`}>
              <Row>
                <Col xs={12} className="post-option px-0 py-0">
                  <ReportIcon className="mb-1" />{' '}
                  <p className="my-auto">&nbsp;Report</p>
                </Col>
              </Row>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };

  renderShoutouts = () => {
    let count = 1;
    return this.props.shoutouts.map<any>((shoutout) => {
      let name = shoutout.user.firstname;
      if (this.props.shoutouts.length !== count) {
        name += ',';
      }
      count += 1;
      return (
        <Link
          style={{color: Colors.primary, marginRight: '5px', fontWeight: '500'}}
          to={`/profile/${shoutout.user.user_id}`}
          key={count}>
          {name}
        </Link>
      );
    });
  };

  renderHeader = () => {
    if (this.props.shoutouts && this.props.shoutouts.length > 0) {
      return (
        <div className="d-flex flex-row my-auto">
          <h6 className="my-0" style={{fontWeight: '300'}}>
            <Link
              style={{color: Colors.primary, fontWeight: '500'}}
              to={`/profile/${this.props.postUser.user_id}`}>
              {this.props.postUser.firstname}
            </Link>
            &nbsp;shouted out&nbsp;
            {this.renderShoutouts()}
          </h6>
        </div>
      );
    } else {
      return (
        <h6 className="my-0" style={{fontWeight: '300'}}>
          <Link
            style={{color: Colors.primary, fontWeight: '500'}}
            className="my-auto"
            to={`/profile/${this.props.postUser.user_id}`}>
            {this.props.postUser.full_name}
          </Link>
        </h6>
      );
    }
  };

  renderPostImage = () => {
    let postIMG = this.props.img;
    let img_class = 'postImage';
    if (this.props.link.includes('wsj.com')) {
      postIMG = wsj;
      img_class = 'postImageMod';
    }
    if (this.props.link.includes('bloomberg.com')) {
      postIMG = bloomberg;
      img_class = 'postImageMod';
    }
    if (postIMG == null) {
      postIMG = defaultWander;
    }

    return (
      <Card className="mt-3">
        <Link style={{color: 'black'}} to={`/post/${this.props.post_id}`}>
          <div className="imgWrapper">
            <Card.Img className={img_class} variant="top" src={postIMG} />
          </div>
        </Link>
      </Card>
    );
  };

  renderPostBody = () => {
    if (this.props.native === false && this.props.embed_link === '') {
      return (
        <Card.Body>
          <Link
            style={{color: 'black'}}
            to={`/post/${this.props.post_id}`}
            onClick={() => {
              window.analytics.track('Post Opened', this.props);
            }}>
            <Card.Title>{this.props.title}</Card.Title>
            <Card.Text>
              {this.props.description
                ? this.props.description.slice(0, 200)
                : ''}
            </Card.Text>
            <Card.Subtitle className="text-muted">
              {this.props.domain}
            </Card.Subtitle>
          </Link>
        </Card.Body>
      );
    } else {
      return (
        <Card.Body className="pl-0">
          <Link style={{color: 'black'}} to={`/post/${this.props.post_id}`}>
            <Card.Title>{this.props.title}</Card.Title>
            <Card.Text>
              {this.props.description
                ? this.props.description.slice(0, 200)
                : ''}
            </Card.Text>
            <Card.Subtitle className="text-muted">
              {this.props.domain}
            </Card.Subtitle>
          </Link>
        </Card.Body>
      );
    }
  };

  renderCommentIcon = () => {
    if (this.props.comments.length === 0) {
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="justify-content-end text-truncate">
          <p style={{color: Colors.grey}} className="my-auto">
            No comments&nbsp;
          </p>
          <CommentIcon />
        </div>
      );
    } else if (this.props.comments.length === 1) {
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="justify-content-end text-truncate">
          <p style={{color: Colors.grey}} className="my-auto">
            1 comment&nbsp;
          </p>
          <CommentIcon />
        </div>
      );
    } else {
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="justify-content-end text-truncate">
          <p style={{color: Colors.grey}} className="my-auto">
            {this.props.comments.length} comments&nbsp;
          </p>
          <CommentIcon />
        </div>
      );
    }
  };

  renderUpvoteIcon = () => {
    if (this.props.rating != null) {
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="pr-4 justify-content-end text-truncate upvoteIcon">
          <UpvoteFilledIcon onClick={() => this.handleRatingChange(null)} />
          &nbsp;
          <p style={{color: Colors.grey}} className="my-auto">
            {this.props.rating_count}
          </p>
        </div>
      );
    } else {
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="pr-4 justify-content-end text-truncate upvoteIcon">
          <UpvoteIcon onClick={() => this.handleRatingChange(5)} /> &nbsp;
          <p style={{color: Colors.grey}} className="my-auto">
            {this.props.rating_count}
          </p>
        </div>
      );
    }
  };

  renderTimeEstimate = () => {
    if (this.props.estimatedTime === 0) {
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="px-4 justify-content-end text-truncate">
          <AccessTimeIcon />
          &nbsp;
          <p style={{color: Colors.grey}} className="my-auto">
            {'<1'} min
          </p>
        </div>
      );
    } else if (this.props.estimatedTime !== null) {
      return (
        <div
          style={{color: Colors.primary, display: 'flex'}}
          className="justify-content-end text-truncate">
          <AccessTimeIcon />
          &nbsp;
          <p style={{color: Colors.grey}} className="my-0">
            {this.props.estimatedTime} min.
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  renderPlaylists = () => {
    const playlists = [];
    for (let i = 0; i < this.props.playlists.length; i++) {
      const playlist = this.props.playlists[i];
      playlists.push({
        label: playlist.title,
        value: playlist.playlist_id,
      });
    }

    return playlists;
  };

  toDropdownJSON = (arr: Playlist[]) => {
    const playlists = [];
    for (let i = 0; i < arr.length; i++) {
      const p = arr[i];
      playlists.push({
        value: p.playlist_id,
      });
    }

    return playlists;
  };

  onSelect = (items, name) => {
    if (this.state.isPlaylistSelectionReady) {
      const currentlyInSnapshot = this.state.currentlyIn
        .slice()
        .map((p) => p.playlist_id);
      const dropdownSnapshot = items.slice().map((i) => i.value);
      let updatedCurrentlyIn = [...this.state.currentlyIn];

      if (currentlyInSnapshot.length > dropdownSnapshot.length) {
        const _diff = _.difference(currentlyInSnapshot, dropdownSnapshot);
        const playlistToRemove = this.props.playlists.find(
          (p) => p.playlist_id === _diff[0],
        );
        this.handleRemove(playlistToRemove);
        updatedCurrentlyIn = updatedCurrentlyIn.filter(
          (p) => p.playlist_id !== _diff[0],
        );
      } else if (currentlyInSnapshot.length < dropdownSnapshot.length) {
        const _diff = _.difference(dropdownSnapshot, currentlyInSnapshot);
        const playlistToAdd = this.props.playlists.find(
          (p) => p.playlist_id === _diff[0],
        );
        this.handleSelect(playlistToAdd);
        updatedCurrentlyIn.push(playlistToAdd);
      }
      this.setState({
        currentlyIn: updatedCurrentlyIn,
      });
    }
  };

  renderPlaylistSelectionBtn() {
    return (
      <DropdownMultiple
        ref={this.playlistSelectionRef}
        name="playlists"
        title="Add to collections"
        titleSingular="collection"
        titlePlural="collections"
        closeOnSelection={true}
        list={this.renderPlaylists()}
        onChange={this.onSelect}
        styles={{
          wrapper: {
            padding: '0px',
            fontSize: '1rem',
          },
          header: {
            borderColor: Colors.primary,
            borderRadius: '5px',
            padding: '0px',
          },
          listItem: {
            fontSize: '0.75rem',
          },
        }}
      />
    );
  }

  renderDivider = () => {
    if (this.props.comments && this.props.comments.length > 0) {
      return <hr />;
    } // if
  };

  render() {
    let postUserImg = defaultWander;
    if (this.props.user.id === this.props.postUser.user_id) {
      if (this.props.mainUserIMG !== null) {
        postUserImg = this.props.mainUserIMG;
      }
    } else if (this.props.postUser.profile_pic !== null) {
      postUserImg = this.props.postUser.profile_pic;
    }

    return (
      <Card className="mb-4 shadow-sm" style={{borderRadius: '10px'}}>
        <Card.Body>
          {this.renderTwoColumnPostHeader(postUserImg)}
          {this.renderPostImage()}
          {this.renderPostBody()}
          <Row className="mx-auto my-auto align-items-center justify-content-between">
            <Col xs="auto">
              <Row>
                {this.renderUpvoteIcon()}
                {this.renderTimeEstimate()}
              </Row>
            </Col>
            <Col xs="auto">{this.renderCommentIcon()}</Col>
          </Row>
        </Card.Body>
        {this.renderDivider()}
        <Comments
          comments={this.props.comments}
          handleCommentSubmit={this.onCommentSubmit}
          handleCommentDelete={this.onCommentDelete}
          user={this.props.user}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: {
    name: state.user.name,
    id: state.user.id,
  },
  playlists: state.user.playlists,
  isFollowing: ownProps.postUser
    ? state.user.usersFollowStatus[ownProps.postUser.user_id]
    : false,
  mainUserIMG: state.user.user_img,
  ratedPosts: state.user.ratedPosts,
});
export default connect(mapStateToProps)(Post);
