import React from 'react';
import API from '../Services/API';
import {Row, Table} from 'react-bootstrap';
import './Styles/UnreadSideCard.css';
import UnreadActions from '../Redux/UnreadRedux';
import {connect} from 'react-redux';

type Props = {
  user_id: number,
  posts: [],
  dispatch: ({}) => void,
};

class UnreadSideCard extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.api = API();
  }

  static fetch(match, location, options) {
    options.dispatch(UnreadActions.setUnreadPosts([]));
    const api = API();
    const promises = [];
    promises.push(
      api
        .getUnreadPosts()
        .then((response) => {
          if (response.ok) {
            options.dispatch(UnreadActions.setUnreadPosts(response.data));
          }
        })
        .catch((error) => console.warn(error)),
    );
    return Promise.all(promises);
  }

  render() {
    const greater = this.props.posts.length < 3 ? this.props.posts.length : 3;
    return (
      <Table style={{marginTop: 0, marginBottom: 0}}>
        {this.props.posts
          .slice(0, greater)
          .filter((post) => post.clear_date == null)
          .map((item, index) => (
            <a href={`/post/${item.post_id}`}>
              <Row
                style={{
                  width: '100%',
                  marginRight: 0,
                  marginLeft: 0,
                  color: 'black',
                  fontSize: 13,
                  paddingLeft: 10,
                }}>
                {item.title}
              </Row>
              {index < greater - 1 && (
                <hr
                  style={{
                    height: 0.25,
                    color: 'grey',
                    marginTop: 4,
                    marginBottom: 4,
                    width: '100%',
                  }}
                />
              )}
            </a>
          ))}
      </Table>
    );
  }
}

const mapStateToProps = (state) => ({
  user_id: state.user.id,
  posts: state.unread.posts,
});
export default connect(mapStateToProps)(UnreadSideCard);
