import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setUnreadPosts: ['posts'],
  deletePost: ['postID'],
  changeFocus: ['active', 'time'],
  nextPost: ['next'],
  setNumRead: ['count'],
  resetFocus: [],
  reset: [],
});

export const UnreadTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  posts: [],
  focusPosts: [],
  focusActive: false,
  currentPost: 0,
  numRead: 0,
  timeLimit: 30,
  startTime: new Date(),
});

/* ------------- Reducers ------------- */

export const setUnreadPosts = (state, {posts}) =>
  state.merge({
    posts: posts.unread,
    focusPosts: posts.focus,
  });

export const deletePost = (state, {postID}) => {
  const newUnreadPosts = state.posts.filter((post) => post.post_id !== postID);
  const newFocusPosts = state.focusPosts.filter(
    (post_id) => post_id !== postID,
  );
  return state.merge({
    posts: newUnreadPosts,
    focusPosts: newFocusPosts,
  });
};

export const changeFocus = (state, {active, time}) =>
  state.merge({
    focusActive: active,
    startTime: time,
  });

export const nextPost = (state, {next}) =>
  state.merge({
    currentPost: next,
  });

export const setNumRead = (state, {count}) =>
  state.merge({
    numRead: count,
  });

export const resetFocus = (state) =>
  state.merge({
    currentPost: 0,
    focusActive: false,
    numRead: 0,
  });

export const reset = (state) => state.merge(INITIAL_STATE);

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_UNREAD_POSTS]: setUnreadPosts,
  [Types.DELETE_POST]: deletePost,
  [Types.CHANGE_FOCUS]: changeFocus,
  [Types.NEXT_POST]: nextPost,
  [Types.SET_NUM_READ]: setNumRead,
  [Types.RESET_FOCUS]: resetFocus,
  [Types.RESET]: reset,
});
