// @flow

import {Nav, Button} from 'react-bootstrap';
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import HomeIcon from '@material-ui/icons/Home';
import ExploreIcon from '@material-ui/icons/Explore';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import AssignmentIcon from '@material-ui/icons/Assignment';
import './Styles/NavigationBar.css';
import CreateLink from './CreateLink';

type Props = {
  show: boolean,
  handleClose: () => void,
  user: {
    id: number,
    name: string,
  },
  location: {
    pathname: string,
  },
};

type State = {
  showModal: boolean,
};

class MiniNavBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      modal: true,
    };
  }

  handleModalDismiss = () => {
    this.setState({showModal: false});
  };

  handleNewPostPress = () => {
    this.props.handleClose();
    this.setState({showModal: true});
    window.analytics.track('New Post Dialog Box Opened');
  };

  handleSelection = (message) => {
    window.analytics.track(message);
    this.props.handleClose();
  };

  handleFeedbackPress = () => {
    window.open(
      'https://thinkwander.feedbear.com/boards/feature-requests',
      '_blank',
    );
    // this.setState({ showFeedback: !this.state.showFeedback})
  };

  render() {
    return (
      <div
        className={
          this.props.show
            ? 'sidenav bg-light d-block d-xl-none'
            : 'sidenav close bg-light d-block d-xl-none'
        }>
        <Nav
          id="nav-bar"
          activeKey={this.props.location.pathname}
          className="flex-column px-2">
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/"
              href="/"
              onClick={() => {
                this.handleSelection('home tab clicked');
              }}
              className="menuLink"
              id={
                this.props.location.pathname === '/'
                  ? 'home'
                  : 'home-inactive-mobile'
              }>
              <HomeIcon className="homeIcon" fontSize="large" />
              &nbsp;&nbsp;&nbsp;Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/explore"
              href="/explore"
              onClick={() => {
                this.handleSelection('explore tab clicked');
              }}
              className="menuLink exploreBtn"
              id={
                this.props.location.pathname === '/explore'
                  ? 'explore'
                  : 'explore-inactive-mobile'
              }>
              <ExploreIcon className="exploreIcon" fontSize="large" />
              &nbsp;&nbsp;&nbsp;Explore
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/unread"
              href="/unread"
              onClick={() => {
                this.handleSelection('unread tab clicked');
              }}
              className="menuLink"
              id={
                this.props.location.pathname === '/unread'
                  ? 'unread'
                  : 'unread-inactive-mobile'
              }>
              <BookmarkBorderIcon className="bookmarkIcon" fontSize="large" />
              &nbsp;&nbsp;&nbsp;Unread
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to={`/profile/${this.props.user.id}`}
              href={`/profile/${this.props.user.id}`}
              onClick={() => {
                this.handleSelection('profile tab clicked');
              }}
              className="menuLink"
              id={
                this.props.location.pathname ===
                `/profile/${this.props.user.id}`
                  ? 'profile'
                  : 'profile-inactive-mobile'
              }>
              <PersonIcon className="personIcon" fontSize="large" />
              &nbsp;&nbsp;&nbsp;Profile
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="/follow"
              href="/follow"
              onClick={() => {
                this.handleSelection('follow tab clicked');
              }}
              className="menuLink"
              id={
                this.props.location.pathname === '/follow'
                  ? 'follow'
                  : 'follow-inactive-mobile'
              }>
              <PeopleIcon className="peopleIcon" fontSize="large" />
              &nbsp;&nbsp;&nbsp;Follow
            </Nav.Link>
          </Nav.Item>
          {this.props.user.role === 'admin' ? (
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/admin"
                href="/admin"
                onClick={() => {
                  this.handleSelection('admin tab clicked');
                }}
                className="menuLink"
                id={
                  this.props.location.pathname === '/admin'
                    ? 'admin'
                    : 'admin-inactive-mobile'
                }>
                <AssignmentIcon className="assignmentIcon" fontSize="large" />
                &nbsp;&nbsp;&nbsp;Admin
              </Nav.Link>
            </Nav.Item>
          ) : null}
          <Nav.Item id="mobile-post-button" className="px-3 pt-3 pb-2">
            <Button
              block
              variant="primary"
              style={{borderRadius: '15px'}}
              className="shadow-none"
              onClick={this.handleNewPostPress}>
              New Post
            </Button>
          </Nav.Item>
          <Nav.Item className="px-3 pt-3 pb-2">
            <Button
              block
              style={{borderRadius: '15px'}}
              variant="primary"
              className="shadow-none"
              onClick={this.handleFeedbackPress}>
              Leave Feedback
            </Button>
          </Nav.Item>
        </Nav>
        <CreateLink
          show={this.state.showModal}
          onHide={this.handleModalDismiss}
          onShow={this.handleNewPostPress}
          modal={this.state.modal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: {
    name: state.user.name,
    id: state.user.id,
    role: state.user.role,
  },
  playlists: state.user.playlists,
});
export default connect(mapStateToProps)(withRouter(MiniNavBar));
