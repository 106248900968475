import React from 'react';
import API from '../Services/API';
import './Styles/UnreadSideCard.css';
import ProfileCard from './ProfileCard2';

const UnreadSideCard = (props) => {
  const [toFollow, setToFollow] = React.useState([]);

  const api = API();

  React.useEffect(() => {
    api
      .getUsersToFollowSideCard()
      .then((response) => {
        if (response.ok) {
          // console.log(response.data);
          setToFollow(response.data);
        }
      })
      .catch((error) => console.warn(error));
    // eslint-disable-next-line
  }, []);

  return toFollow.map((item, index) => {
    item.bio = '';
    return (
      <>
        <ProfileCard className="shadow" user={item} />
        {index < 2 && (
          <hr style={{color: 'grey', marginTop: 6, marginBottom: 6}} />
        )}
      </>
    );
  });
};

export default UnreadSideCard;
