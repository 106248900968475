// @flow

import React, {Component} from 'react';
import type {Node} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {omit} from 'ramda';
import Cookies from 'js-cookie';

type Props = {
  children: Node,
};

export default class ProtectedRoute extends Component<Props> {
  isLoggedIn: boolean;

  constructor(props: Props) {
    super(props);
    const token = Cookies.get('accessToken');
    this.isLoggedIn = !!token;
  }

  renderRoute = (props: any) => {
    const children = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {...props}),
    );

    if (this.isLoggedIn) {
      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: '/landing',
            search: props.location.search,
            state: {from: props.location},
          }}
        />
      );
    }
  };

  render() {
    return (
      <Route
        {...omit(['children', 'isLoggedIn'], this.props)}
        render={this.renderRoute}
      />
    );
  }
}
